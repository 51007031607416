import { baseUrl } from "../env";
import axios from "axios";
import { CursorType, FormationEtudiant, StudentCourseEvent } from "./models";

const uri = `${baseUrl}/api/formationEtudiant`;

export const assignStudentsToCourse = (
  courseId: number,
  studentIds: number[]
) => {
  return axios.post<FormationEtudiant[]>(`${uri}/courses/${courseId}`, {
    studentIds,
  });
};

export function updateCursor(
  courseId: number,
  cursorId: number,
  cursorType: CursorType
) {
  return axios.put(`${uri}/courses/${courseId}/cursor`, {
    cursorId,
    cursorType,
  });
}

export function resetCursor(courseId: number, studentId: number) {
  return axios.put(`${uri}/courses/${courseId}/cursor/reset`, {
    studentId,
  });
}

export function emitEvent(
  courseId: number,
  eventName: StudentCourseEvent,
  eventId: number
) {
  return axios.post(`${uri}/courses/${courseId}/events`, {
    eventName,
    eventId,
  });
}
