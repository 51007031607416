import { FC } from "react";
import { IMenuItem } from "../dto";
import { Box } from "@mui/material";

export const CourseMenuIcon: FC<{
  itemType: IMenuItem["type"];
}> = (props) => {
  const type = props.itemType;

  return (
    <>
      {["subchapter", "chapter", "conclusion"].some((v) => type === v) && (
        <Box
          style={{
            width: type === "chapter" ? 16 : 10,
            height: type === "chapter" ? 16 : 10,
            background: "#023047",
            borderRadius: "50%",
          }}
        >
          &nbsp;
        </Box>
      )}
      {type === "workshop" && (
        <img alt={"workshop icon"} src={"/icons/workshop.svg"} />
      )}
      {type === "project" && (
        <img alt={"project icon"} src={"/icons/project.svg"} />
      )}
    </>
  );
};
