import * as React from "react";
import { SVGProps } from "react";

export const OneToOneIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" id="vuesax_bold_user-square" data-name="vuesax/bold/user-square" width={props.width} height={props.height} viewBox="0 0 30.745 30.745">
        <g id="vuesax_bold_user-square-2" data-name="vuesax/bold/user-square">
          <g id="user-square">
            <path id="Vector" d="M0,0H30.745V30.745H0Z" fill="none" opacity="0"/>
            <g id="Group" transform="translate(2.562 2.562)">
              <path id="Vector-2" data-name="Vector" d="M18.178,0H7.443C2.78,0,0,2.78,0,7.443V18.178c0,3.6,1.653,6.072,4.561,7.007a8.79,8.79,0,0,0,2.882.436H18.178a8.79,8.79,0,0,0,2.882-.436c2.908-.935,4.561-3.408,4.561-7.007V7.443C25.621,2.78,22.841,0,18.178,0ZM23.7,18.178q0,4.112-3.241,5.188a8.467,8.467,0,0,0-7.648-4.189,8.457,8.457,0,0,0-7.648,4.189H5.15c-2.139-.692-3.228-2.434-3.228-5.175V7.443c0-3.613,1.909-5.521,5.521-5.521H18.178c3.613,0,5.521,1.909,5.521,5.521Z" fill={props.color || "#fff"}/>
              <path id="Vector-3" data-name="Vector" d="M4.586,0A4.593,4.593,0,1,0,9.172,4.586,4.582,4.582,0,0,0,4.586,0Z" transform="translate(8.224 7.686)" fill={props.color || "#fff"}/>
            </g>
          </g>
        </g>
      </svg>
  );
};
