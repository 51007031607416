import * as React from "react";
import { SVGProps } from "react";

export const EyeVisibleIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Hide_Icon_On" data-name="Hide Icon On" width={props.width} viewBox="0 0 26.486 18.169">
        <path id="Path_3658" data-name="Path 3658" d="M27.8,13.361C27.567,13.019,22.1,5,15.039,5S2.511,13.019,2.281,13.361l-.485.724.485.724c.23.341,5.7,8.361,12.758,8.361S27.567,15.15,27.8,14.809l.485-.724ZM15.039,20.574c-4.583,0-8.649-4.659-10.068-6.489C6.39,12.255,10.456,7.6,15.039,7.6s8.649,4.659,10.068,6.489C23.688,15.915,19.621,20.574,15.039,20.574Z" transform="translate(-1.796 -5)" fill={props.color || "#000"}/>
        <path id="Path_3659" data-name="Path 3659" d="M13.191,8a5.191,5.191,0,1,0,5.191,5.191A5.2,5.2,0,0,0,13.191,8Zm0,7.787a2.6,2.6,0,1,1,2.6-2.6A2.6,2.6,0,0,1,13.191,15.787Z" transform="translate(0.052 -4.107)" fill={props.color || "#000"}/>
    </svg>
    );
};
