import * as React from "react";
import { SVGProps } from "react";

export const CalendarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" id="vuesax_bold_calendar" data-name="vuesax/bold/calendar" width={props.width} height={props.height} viewBox="0 0 30.745 30.745">
        <g id="vuesax_bold_calendar-2" data-name="vuesax/bold/calendar">
          <g id="calendar">
            <path id="Vector" d="M0,0H30.745V30.745H0Z" fill="none" opacity="0"/>
            <path id="Vector-2" data-name="Vector" d="M17.565,2.959v-2a.961.961,0,0,0-1.922,0V2.882H7.316V.961a.961.961,0,0,0-1.922,0v2A5.441,5.441,0,0,0,0,8.4a.644.644,0,0,0,.641.679H22.317a.635.635,0,0,0,.641-.679A5.441,5.441,0,0,0,17.565,2.959Z" transform="translate(3.893 1.601)" fill={props.color || "#fff"}/>
            <path id="Vector-3" data-name="Vector" d="M21.778,0H1.281A1.285,1.285,0,0,0,0,1.281V9.172c0,3.843,1.922,6.405,6.405,6.405H16.654c4.484,0,6.405-2.562,6.405-6.405V1.281A1.285,1.285,0,0,0,21.778,0ZM7.955,10.722a2.018,2.018,0,0,1-.192.154.97.97,0,0,1-.231.115.814.814,0,0,1-.231.077,1.736,1.736,0,0,1-.256.026,1.275,1.275,0,0,1-.487-.1,1.322,1.322,0,0,1-.423-.269,1.3,1.3,0,0,1,0-1.819,1.322,1.322,0,0,1,.423-.269A1.225,1.225,0,0,1,7.3,8.557a.814.814,0,0,1,.231.077.97.97,0,0,1,.231.115l.192.154a1.3,1.3,0,0,1,0,1.819Zm0-4.484a1.3,1.3,0,0,1-1.819,0,1.3,1.3,0,0,1,0-1.819,1.28,1.28,0,0,1,1.4-.269,1.2,1.2,0,0,1,.423.269,1.3,1.3,0,0,1,0,1.819Zm4.484,4.484A1.286,1.286,0,0,1,10.62,8.9a1.286,1.286,0,0,1,1.819,1.819Zm0-4.484-.192.154a.97.97,0,0,1-.231.115.814.814,0,0,1-.231.077,1.736,1.736,0,0,1-.256.026,1.269,1.269,0,0,1-1.281-1.281,1.347,1.347,0,0,1,.372-.91,1.2,1.2,0,0,1,.423-.269,1.28,1.28,0,0,1,1.4.269,1.3,1.3,0,0,1,0,1.819Zm4.484,4.484A1.286,1.286,0,0,1,15.1,8.9a1.286,1.286,0,0,1,1.819,1.819Zm0-4.484-.192.154a.97.97,0,0,1-.231.115.814.814,0,0,1-.231.077,1.925,1.925,0,0,1-.256.026,1.269,1.269,0,0,1-1.281-1.281,1.347,1.347,0,0,1,.372-.91,1.322,1.322,0,0,1,.423-.269,1.225,1.225,0,0,1,.743-.077.814.814,0,0,1,.231.077.97.97,0,0,1,.231.115l.192.154a1.3,1.3,0,0,1,0,1.819Z" transform="translate(3.843 12.605)" fill={props.color || "#fff"}/>
          </g>
        </g>
      </svg>
  );
};
