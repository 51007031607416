import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getContent } from "../../../../api/course.service";
import { Formation, Page } from "../../../../api/models";
import { updatePage } from "../../../../api/page.service";
import produce from "immer";
import { useDispatch } from "react-redux";
import { useSnackbar } from "../../../../hooks/snackbar";
import { WorkshopContent } from "./content/WorkshopContent";
import { PageContent } from "./content/PageContent";
import { QuizContent } from "./content/QuizContent";
import { SideMenu } from "./content/SideMenu";
import { ProjectContent } from "./content/ProjectContent";
import { PageSelection, Selection } from "./content/_types";
import { ConclusionContent } from "./content/ConclusionContent";
import { setSiderCollapse } from "../../../../reducers/layout.reducer";
import { useMutation } from "@tanstack/react-query";
import { Loader } from "../../../../component/Loader";
import {
  setCurrentCourse,
  unsetCurrentCourse,
} from "../../../../reducers/instructor/actions";

export const CourseContent = () => {
  const params = useParams();
  const { courseId } = params;
  const [course, setCourse] = useState<Formation | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selection, select] = useState<Selection | null>(null);
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const updatePageMutation = useMutation<
    any,
    any,
    { id: number; page: Partial<Page> }
  >({
    mutationKey: ["updatePage"],
    mutationFn: (args) =>
      updatePage(args.id, args.page)
        .then(() => {
          snackbar.show("Contenu de la page sauvegardé", "success");
        })
        .catch(() => {
          snackbar.show(
            "Erreur lors de la mise à jour du contenu de la page",
            "error"
          );
        }),
  });

  useEffect(() => {
    dispatch(
      setSiderCollapse({
        isCollapsed: true,
      })
    );
    return () => {
      dispatch(
        setSiderCollapse({
          isCollapsed: false,
        })
      );
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getContent(Number(courseId))
      .then((response) => {
        const course = response.data;
        if (!!course) {
          dispatch(setCurrentCourse(course));
          setCourse(course);
        }
      })
      .catch(() => {
        snackbar.show("Erreur lors du chargement du contenu", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {
      dispatch(unsetCurrentCourse());
      setCourse(null);
    };
  }, []);

  if (isLoading) {
    return <Loader text={"Chargement du cours"} sx={{ height: "80vh" }} />;
  }

  return (
    <div style={{ display: "flex" }}>
      <SideMenu
        courseId={courseId}
        selection={selection}
        course={course}
        setCourse={setCourse}
        select={select}
      />
      <div style={{ padding: "0.5rem 0.5rem", width: "100%" }}>
        {selection?.type === "page" && (
          <PageContent
            isLoading={updatePageMutation.isLoading}
            pageId={selection.page.id}
            data={selection.page.contenu_Html}
            onChange={(html) => {
              const theSelection = selection as PageSelection;
              setCourse(
                produce((course) => {
                  if (!!course) {
                    course.chapitres[theSelection.chapterIndex].souschapitres[
                      theSelection.subChapterIndex
                    ].pages[theSelection.pageIndex].contenu_Html = html;
                  }
                })
              );
            }}
            onSave={(data) => {
              updatePageMutation.mutate({
                id: selection.page.id,
                page: {
                  contenu_Html: data,
                },
              });
            }}
          />
        )}
        {selection?.type === "quiz" &&
          !!course?.chapitres[selection.chapterIndex]?.souschapitres[
            selection.subChapterIndex
          ]?.qcm && (
            <QuizContent
              selection={{
                ...selection,
                quiz: course!!.chapitres[selection.chapterIndex].souschapitres[
                  selection.subChapterIndex
                ].qcm,
              }}
              setCourse={setCourse}
            />
          )}
        {selection?.type === "project" &&
          !!course &&
          course.chapitres[selection.chapterIndex]?.project && (
            <ProjectContent
              project={course.chapitres[selection.chapterIndex].project}
              onUpdate={(project) => {
                setCourse(
                  produce((course) => {
                    if (!!course) {
                      course.chapitres[selection?.chapterIndex].project = {
                        ...course.chapitres[selection?.chapterIndex].project,
                        ...project,
                      };
                    }
                  })
                );
              }}
            />
          )}
        {selection?.type === "workshop" &&
          course?.chapitres[selection.chapterIndex]?.Workshop && (
            <WorkshopContent
              selection={selection}
              course={course}
              setCourse={setCourse}
            />
          )}
        {selection?.type === "conclusion" &&
          course?.chapitres[selection.chapterIndex] && (
            <ConclusionContent
              selection={selection}
              course={course}
              onChange={(newChapter) => {
                setCourse(
                  produce((course) => {
                    if (!!course) {
                      course.chapitres[selection?.chapterIndex].recap_url =
                        newChapter.recap_url;
                    }
                  })
                );
              }}
            />
          )}
        {selection == null && (
          <div>Please select an option from the sidebar</div>
        )}
      </div>
    </div>
  );
};
