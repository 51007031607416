import { useQuery } from "@tanstack/react-query";
import { getAllCourses } from "../api/student.service";
import { getUserTools } from "../api/user.service";
import { findById } from "../api/course.service";

export const useAllCoursesForStudent = () =>
  useQuery({
    queryKey: ["getAllCoursesForStudent"],
    queryFn: () => getAllCourses().then((response) => response.data),
  });

export const useUserToolsQuery = () =>
  useQuery({
    queryKey: ["getUserTools"],
    queryFn: () => getUserTools(),
  });

export const useCourseQuery = (id: number) =>
  useQuery({
    queryKey: ["findCourseById", id],
    queryFn: () => findById(id).then((response) => response.data),
  });
