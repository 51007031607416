export interface SousChapitre {
  id: number;
  chapitreId: number;
  createdAt: string;
  order: number;
  pages: Page[];
  title: string;
  updatedAt: string;
  qcm: Qcm;
}

export interface Page {
  id: number;
  SousChapitreId: number;
  contenu_Html: string;
  createdAt: string;
  order: number;
  updatedAt: string;
}

export interface Chapitre {
  id: number;
  formationId: number;
  title: string;
  recap_url: string;
  order: number;
  updatedAt: string;
  createdAt: string;
  souschapitres: SousChapitre[];
  Workshop?: Workshop;
  formation: Formation;
  project: Project;
}

export interface Formation {
  id: number;
  title: string;
  description: string;
  nombre_places_disp: number;
  prix: number;
  duree: number;
  createdAt: string;
  updatedAt: string;
  solde_one_to_one: number;
  formateurId: number;
  formateur?: Formateur;
  chapitres: Chapitre[];
  etudiants: Etudiant[];
  formation_etudiant: FormationEtudiant;
  tag: string;
  photo: string;
}

export interface FormationEtudiant {
  bloquer: boolean;
  avancement: number;
  createdAt?: any;
  updatedAt?: any;
  etudiantId: number;
  formationId: number;
  soldeOneToOne: number;
  cursorId: number;
  cursorType: string;
}

export interface User {
  nomComplet: string;
  id: number;
  cin: string;
  tel?: any;
  nom: string;
  prenom: string;
  role: string;
  email: string;
  adresse: null | string;
  userImage: string;
  createdAt: Date;
  updatedAt: Date;
  connectedAt: string;
}

export interface Etudiant {
  id: number;
  createdAt?: any;
  updatedAt?: any;
  userId: number;
  formation_etudiant: FormationEtudiant;
  user: User;
  formations?: Formation[];
}

export interface Qcm {
  id: number;
  createdAt: string;
  updatedAt: string;
  SousChapitreId: number;
  questions: Question[];
  score: number;
  version: number;
}

export interface Reponse {
  id: number;
  estElleValide: number;
  reponse: string;
  createdAt: string;
  updatedAt: string;
  QuestionId: number;
}

export interface Question {
  Reponses: Reponse[];
  id: number;
  QcmId: number;
  question: string;
  order: number;
  updatedAt: string;
  createdAt: string;
  version: number;
}

export interface OneToOne {
  id: number;
  dateDebut: string;
  createdAt: string;
  updatedAt: string;
  formateurId: number;
  etudiantId: number;
  etudiant: Etudiant;
  formateur: Formateur;
}

export interface Disponibilite {
  id: number;
  jour: string;
  heure_debut: string;
  heure_fin: string;
  createdAt: string;
  updatedAt: string;
  formateurId: number;
}

export interface Workshop {
  id: number;
  lieu?: any;
  date?: any;
  duree?: any;
  description?: any;
  title?: any;
  lien_google_map?: any;
  createdAt: Date;
  updatedAt: Date;
  ChapitreId: number;
}

export interface PresenceWorkshop {
  WorkshopId: number;
  estPresent?: boolean;
  etudiantId: number;
  etudiant: {
    user: User;
  };
}

export interface Project {
  id: number;
  enonce: string;
  deadline: string;
  createdAt: string;
  updatedAt: string;
  ChapitreId: number;
  chapitre: Chapitre;
}

export enum EtudiantProjectStatut {
  DEADLINE_MISSED = "deadline_missed",
  ACCEPTED = "accepted",
  REFUSED = "refused",
  SUBMITTED = "submitted",
}

export interface EtudiantProject {
  id: number;
  fichierUrl: string;
  note?: any;
  createdAt: string;
  updatedAt: string;
  etudiantId: number;
  projectId: number;
  project: Project;
  etudiant: Etudiant;
  estValide?: boolean;

  statut: EtudiantProjectStatut;
}

export type DisponibiliteOneToOne = Disponibilite & {
  date_debut: string;
  date_fin: string;
  estReserve: boolean;
};

export interface DemandeInscription {
  id: number;
  cin: string;
  tel: string;
  nom: string;
  prenom: string;
  email: string;
  adresse: string;
  accepter?: boolean;
  createdAt: string;
  updatedAt: Date;
  formationId: number;
}

export interface Formateur {
  id: number;
  userId: number;
  formations: Formation[];
  user: User;
  soldeOneToOne: number;
  lienGoogleMeet?: string;
}

export interface Recruteur {
  id: number;
  nom: string;
  adresse: string;
  domaine: string;
  nombreEmploye: number;
  createdAt: string;
  updatedAt: string;
  image: string;
}

export interface Club {
  id: number;
  nom: string;
  domaine: string;
  image: string;
  createdAt: string;
  updatedAt: string;
}

export enum UserRole {
  ADMIN = "admin",
  STUDENT = "etudiant",
  INSTRUCTOR = "formateur",
}

export interface Tool {
  id: number;
  title: string;
  code: ToolCode;
  image: string;
  tag: string;
  price: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface StudentQuiz {
  status?: StudentQuizStatus;
  id: number;
  score: number;
  etudiantId: number;
  qcmId: number;
  createdAt: string;
  updatedAt: string;
  qcm: Qcm;
  student_answers: StudentAnswer[];
}

export interface StudentAnswer {
  id: number;
  ReponseId: number;
  createdAt: string;
  updatedAt: string;
  studentQuizId: number;
}

export enum ToolCode {
  SIMPLY_SUPPORTED_BEAM_AT_BOTH_ENDS = "simply-supported-beam-at-both-ends",
  HYPERSTATIC_BEAM_ON_3_SUPPORTS = "hyperstatic-beam-on-3-supports",
  EMBANKMENT = "embankment",
  CIRCULAR_FOOTINGS = "circular-footings",
  STRUCTURAL_FRAMES = "structural-frames",
  SINGLE_FOOTING = "single-footing",
  STRIP_FOOTING = "strip-footing",
  PILE = "pile",
  TRIANGULAR_EMBANKMENT = "triangular-embankment",
  HYPERSTATIC_BEAM_ON_4_SUPPORTS = "hyperstatic-beam-on-4-supports",
}

export type CursorType =
  | "project"
  | "workshop"
  | "conclusion"
  | "page"
  | "quiz";

export type Cursor = Pick<FormationEtudiant, "cursorId" | "cursorType">;
export const CONSULT_PAGE = "consult_page";
export type StudentCourseEvent = typeof CONSULT_PAGE;
export const STUDENT_QUIZ_SUCCESS = "success";
export const STUDENT_QUIZ_FAIL = "fail";
export type StudentQuizStatus =
  | typeof STUDENT_QUIZ_SUCCESS
  | typeof STUDENT_QUIZ_FAIL;
