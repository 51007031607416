import { cloneElement, FC, ReactElement, ReactNode } from "react";
import { PresenceWorkshop, Workshop } from "../../../../api/models";
import {
  Avatar,
  Box,
  Container,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  DateRangeOutlined,
  EmojiPeopleOutlined,
  PlaceOutlined,
  TimelapseOutlined,
} from "@mui/icons-material";
import { humanize } from "../../../../utils/utils";

const Info: FC<{
  label: ReactNode;
  value: ReactNode;
  icon: {
    color: string;
    bgcolor: string;
    element: ReactElement;
  };
}> = (props) => {
  return (
    <ListItem component={"div"} sx={{ p: 0 }}>
      <ListItemAvatar>
        <Avatar
          variant={"rounded"}
          style={{ backgroundColor: props.icon.bgcolor }}
        >
          {cloneElement(props.icon.element, {
            style: { color: props.icon.color },
          })}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<>{props.label}</>}
        secondary={<>{props.value}</>}
      />
    </ListItem>
  );
};

function getAttendanceStatus(attendance?: PresenceWorkshop) {
  switch (attendance?.estPresent) {
    case true: {
      return "Marqué présent";
    }
    case false: {
      return "Marqué absent";
    }
    default: {
      return "Non determiné";
    }
  }
}

export const ContentOfWorkshop: FC<{
  numberOfWorkshop: number;
  workshop: Workshop;
  attendance?: PresenceWorkshop;
}> = (props) => {
  const undetermined = "Non déterminé";
  return (
    <Container maxWidth={"md"}>
      <Stack spacing={4}>
        <Box>
          <Stack spacing={2}>
            <Box>
              <Typography
                variant={"h4"}
                color={"primary"}
                sx={{
                  fontWeight: "bold",
                }}
              >
                {props.workshop.title}
              </Typography>
              <Typography variant={"h6"} sx={{ color: "#023047" }}>
                Workshop #{props.numberOfWorkshop}
              </Typography>
            </Box>

            <Typography>
              {props.workshop.description ||
                "Pas de description disponible pour le moment."}
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Info
                label={"Date"}
                value={
                  !props.workshop.date
                    ? undetermined
                    : moment(props.workshop.date).format(
                        "dddd, DD MMMM YYYY HH:mm"
                      )
                }
                icon={{
                  color: "#023047",
                  bgcolor: "#CFCFCF",
                  element: <DateRangeOutlined />,
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Info
                label={"Lieu"}
                value={
                  !props.workshop.lieu ? undetermined : props.workshop.lieu
                }
                icon={{
                  color: "#023047",
                  bgcolor: "#CFCFCF",
                  element: <PlaceOutlined />,
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Info
                label={"Durée"}
                value={
                  !props.workshop.duree
                    ? undetermined
                    : humanize(props.workshop.duree)
                }
                icon={{
                  color: "#023047",
                  bgcolor: "#CFCFCF",
                  element: <TimelapseOutlined />,
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Info
                label={"Présence"}
                value={getAttendanceStatus(props.attendance)}
                icon={{
                  color: "#023047",
                  bgcolor: "#CFCFCF",
                  element: <EmojiPeopleOutlined />,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Container>
  );
};
