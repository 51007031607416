import React, { FC, useEffect, useState } from "react";
import { useSnackbar } from "../../../../hooks/snackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAll as getAllInstructors } from "../../../../api/instructor.service";
import { editCourse } from "../../../../api/course.service";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { bigButton, blackAndWhite } from "../../../../styles";
import { useNavigate, useParams } from "react-router-dom";
import { getError, getUrl } from "../../../../utils/utils";
import { useCourseQuery } from "../../../../hooks/queries";
import { AddImage } from "../../../../component/AddImage";

type EditCourse = {
  title: string;
  description: string;
  tag: string;
  places: number;
  price: number;
  duration: string;
  formateurId: number;
};

export const CourseEditPage: FC = () => {
  const snackbar = useSnackbar();
  const instructorsQuery = useQuery({
    queryKey: ["all-instructors"],
    queryFn: () => getAllInstructors().then((res) => res.data),
  });
  const params = useParams();
  const courseId = params.id ? Number(params.id) : 0;
  const courseQuery = useCourseQuery(courseId);
  const course = courseQuery.data;
  const [image, setImage] = useState<File | null>(null);
  const instructors = instructorsQuery.data || [];
  const editCourseMutation = useMutation({
    mutationKey: ["editCourse", courseId],
    mutationFn: (values: EditCourse) => {
      return editCourse(
        courseId,
        values.formateurId,
        values.title,
        values.description,
        values.places,
        values.price,
        values.duration,
        values.tag,
        image
      ).then((response) => {});
    },
  });
  const form = useForm<EditCourse>({});
  const navigate = useNavigate();
  const errorMessage =
    (editCourseMutation.error as any)?.response?.data?.message ||
    "Erreur lors de la mise à jour";

  useEffect(() => {
    if (editCourseMutation.isSuccess) {
      snackbar.show("Formation edité avec succès", "success");
    }
  }, [editCourseMutation.isSuccess]);

  useEffect(() => {
    if (editCourseMutation.isError) {
      snackbar.show(
        getError(
          (editCourseMutation.error as any)?.response,
          "Erreur lors de la mise à jour"
        ) || "Erreur lors de la mise à jour",
        "error"
      );
    }
  }, [editCourseMutation.isError]);

  useEffect(() => {
    if (!!course) {
      form.setValue("title", course.title);
      form.setValue("formateurId", course.formateurId);
      course.description && form.setValue("description", course.description);
      course.nombre_places_disp &&
        form.setValue("places", course.nombre_places_disp);
      course.duree && form.setValue("duration", course.duree.toString());
      course.prix && form.setValue("price", course.prix);
      course.tag && form.setValue("tag", course.tag);
    }
  }, [course]);

  if (!course) {
    return <></>;
  }

  return (
    <Box p={3}>
      <form
        onSubmit={form.handleSubmit((values) => {
          editCourseMutation.mutate(values);
        })}
      >
        <Typography
          style={{
            color: "#023047",
            fontSize: 26,
            fontWeight: "bold",
          }}
        >
          Formation: {course.title}
        </Typography>
        <Box
          sx={{
            "& *": {
              marginTop: "1rem",
            },
          }}
        >
          <TextField
            placeholder={"Titre"}
            fullWidth={true}
            {...form.register("title")}
          />
          <div>
            <Controller
              control={form.control}
              name={"formateurId"}
              render={({ field }) => (
                <TextField
                  select
                  label={"Formateur"}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  value={field.value || ""}
                >
                  <MenuItem disabled={true}>Sélectionner le formateur</MenuItem>
                  {instructors.map((instructor) => (
                    <MenuItem key={instructor.id} value={instructor.id}>
                      <ListItemText>{instructor.user?.nomComplet}</ListItemText>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
          <TextField
            placeholder={"Description"}
            multiline={true}
            rows={3}
            fullWidth={true}
            {...form.register("description")}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <TextField
                {...form.register("places")}
                type={"number"}
                placeholder={"Nombre de places"}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <TextField
                {...form.register("price")}
                type={"number"}
                placeholder={"Prix"}
                fullWidth={true}
              />{" "}
            </Grid>

            <Grid item xs={12} lg={3}>
              <TextField
                {...form.register("duration")}
                placeholder={"Durée"}
                fullWidth={true}
              />{" "}
            </Grid>

            <Grid item xs={12} lg={3}>
              <TextField
                {...form.register("tag")}
                type={"text"}
                placeholder={"Tag"}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </Box>
        <div style={{ marginTop: "0.5rem" }}>
          <AddImage
            defaultUrl={course.photo ? getUrl(course.photo) : undefined}
            parentFile={image || undefined}
            inputId={"courseImage"}
            onChange={setImage}
          />
        </div>
        {editCourseMutation.isError && (
          <Alert style={{ marginTop: "1rem" }} severity={"error"}>
            {errorMessage || "Erreur lors de la mise à jour"}
          </Alert>
        )}
        <Grid container justifyContent={"flex-end"}>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  type={"button"}
                  style={{
                    color: "#121212",
                  }}
                  {...bigButton}
                  fullWidth={true}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Retour
                </Button>
              </Grid>
              <Grid item>
                <Button type={"submit"} {...blackAndWhite} fullWidth={true}>
                  Confirmer
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </form>
    </Box>
  );
};
