import * as React from "react";
import { SVGProps } from "react";

export const ProjectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" id="vuesax_bold_tick-square" data-name="vuesax/bold/tick-square" width={props.width} height={props.height} viewBox="0 0 30.745 30.745">
      <g id="vuesax_bold_tick-square-2" data-name="vuesax/bold/tick-square">
        <g id="tick-square">
          <path id="Vector" d="M18.178,0H7.443C2.78,0,0,2.78,0,7.443V18.165c0,4.676,2.78,7.456,7.443,7.456H18.165c4.663,0,7.443-2.78,7.443-7.443V7.443C25.621,2.78,22.841,0,18.178,0Zm.756,9.864L11.67,17.128a.959.959,0,0,1-1.358,0L6.687,13.5a.96.96,0,1,1,1.358-1.358l2.946,2.946,6.585-6.585a.96.96,0,0,1,1.358,1.358Z" transform="translate(2.562 2.562)" fill={props.color || "#fff"}/>
          <path id="Vector-2" data-name="Vector" d="M0,0H30.745V30.745H0Z" transform="translate(30.745 30.745) rotate(180)" fill="none" opacity="0"/>
        </g>
      </g>
    </svg>
);
