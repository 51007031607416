import axios from "axios";
import { Recruteur } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/recruteur`;

export const getAll = () => {
  return axios.get<Recruteur[]>(`${uri}/getAll`);
};

export const save = (recruiter: Omit<Recruteur, "image">, image?: File) => {
  const formData = new FormData();
  formData.append("nom", recruiter.nom);
  formData.append("adresse", recruiter.adresse);
  formData.append("domaine", recruiter.domaine);
  formData.append("nombreEmploye", recruiter.nombreEmploye.toString());
  image && formData.append("image", image);

  return axios.post(`${uri}/`, formData);
};

export const deleteById = (id: number) => {
  return axios.delete(`${uri}/${id}`);
};
