import { CircularProgress, Grid, GridProps, Typography } from "@mui/material";
import * as React from "react";
import { FC } from "react";

export const Loader: FC<
  GridProps & {
    text?: string;
  }
> = ({ text, ...props }) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
      {...props}
      sx={{
        minHeight: "30vh",
        gap: 2,
        ...props.sx,
      }}
    >
      <Grid item>
        <Typography>{text || "Chargement en cours..."}</Typography>
      </Grid>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
