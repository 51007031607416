import axios from "axios";
import authHeader from "./auth-header";
import { Tool, User } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/user`;

const getPublicContent = () => {
  return axios.get(uri + "all");
};
const getEtudiantBoard = () => {
  return axios.get(uri + "dashboard", { headers: authHeader() });
};
const getFormateurBoard = () => {
  return axios.get(uri + "course", { headers: authHeader() });
};
const getAdminBoard = () => {
  return axios.get(uri + "admin", { headers: authHeader() });
};
export const update = (userId: number, user: FormData) => {
  return axios.patch<User>(`${uri}/${userId}`, user);
};

export function changePassword(oldPassword: string, newPassword: string) {
  return axios.put(`${uri}/change-password`, { oldPassword, newPassword });
}

export function sendForgotPasswordRequest(email: string) {
  return axios.post(`${uri}/forgetPassword`, { email });
}

export function resetPassword(
  email: string,
  code: string,
  newPassword: string
) {
  return axios.post(`${uri}/resetPassword`, { email, code, newPassword });
}

export function getUserTools() {
  return axios.get<Tool[]>(`${uri}/tools`).then((response) => response.data);
}

export function assignToolToUser(toolId: number) {
  return axios.post(`${uri}/tools/${toolId}`);
}

export default {
  getPublicContent,
  getEtudiantBoard,
  getFormateurBoard,
  getAdminBoard,
  assignToolToUser,
};
