import axios from "axios";
import { Formation, Page, Qcm, SousChapitre } from "./models";
import { baseUrl } from "../env";
import { hasQuestions } from "./quiz.service";

const uri = `${baseUrl}/api/SousChapitre`;

export function addSubchapter(chapterId: number, title: string) {
  return axios.post<SousChapitre>(`${uri}/create`, {
    title,
    chapitreId: chapterId,
  });
}

export function updateSubchapter(
  id: number,
  subchapter: Partial<SousChapitre>
) {
  return axios.put<Partial<SousChapitre>>(`${uri}/update/${id}`, subchapter);
}

export function deleteSubchapter(id: number) {
  return axios.delete(`${uri}/delete/${id}`);
}

export function findSubchapterInCourse(id: number, course: Formation) {
  return course.chapitres
    .map((chapter) => chapter.souschapitres)
    .flat()
    .find((subchapter) => subchapter.id === id);
}

export function hasQuiz(subchapter: SousChapitre) {
  return !!subchapter?.qcm && hasQuestions(subchapter.qcm);
}

export function getFirstChild(subchapter: SousChapitre):
  | undefined
  | {
      type: "quiz" | "page";
      value: Qcm | Page;
    } {
  const firstPage = subchapter.pages[0];
  const quiz = subchapter.qcm;

  if (!!firstPage) {
    return {
      type: "page",
      value: firstPage,
    };
  } else if (quiz) {
    return {
      type: "quiz",
      value: quiz,
    };
  }

  return undefined;
}
