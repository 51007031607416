import * as api from "../api/index";
import { FETCH_FORMATION } from "./types";
import { findById } from "../api/course.service";

export const fetchFormation = (formationId) => async (dispatch) => {
  return findById(formationId).then((response) => {
    dispatch({ type: FETCH_FORMATION, payload: response?.data });
    return response.data;
  });
};

export const getQcmSheet = async (idQcm) => await api.getQcmSheet({ idQcm });
export const validQcm = async (answerSheet, formationId) =>
  await api.validQcmSheet({ answerSheet, formationId });

export const pushProjectAction = (filepath, id) => {
  return api.pushProject(filepath, id);
};
