import axios from "axios";
import { PresenceWorkshop } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/api/presence_workshops`;

export function getAttendanceInWorkshop(workshopId: number) {
  return axios.get<PresenceWorkshop[]>(`${uri}/${workshopId}`);
}

export function setAttendanceInWorkshop(
  workshopId: number,
  records: PresenceWorkshop[]
) {
  return axios.post<PresenceWorkshop[]>(`${uri}/${workshopId}`, {
    presence_workshops: records,
  });
}
