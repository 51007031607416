import React, { FC, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { getWorkshopByEtudiantFormation } from "../../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const StyledTableCell: FC<TableCellProps> = (props) => {
  return (
    <TableCell
      {...props}
      style={{
        borderBottom: "2px solid #E8E8E8",
        color: "#023047",
        fontSize: "16px",
        ...props.style,
      }}
    />
  );
};

export function Workshop() {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const convertDate = (inputFormat: any) => {
    const pad = (s: any) => {
      return s < 10 ? "0" + s : s;
    };

    var d = new Date(inputFormat);
    return (
      pad(d.getDate()) +
      " " +
      monthNames[d.getMonth()] +
      " " +
      d.getFullYear() +
      " - " +
      pad(d.getHours()) +
      ":" +
      pad(d.getMinutes())
    );
  };

  const auth = useSelector((state) => (state as any).auth);
  const [workshops, setWorkshops] = useState([]);
  const currentFormation = useSelector(
    (state: RootState) => state.student.currentCourseId
  );

  useEffect(() => {
    getWorkshopByEtudiantFormation({
      etudiantId: auth.user.id,
      formationId: currentFormation,
    }).then((data: any) => {
      setWorkshops(data.data);
    });
  }, [currentFormation]);

  return (
    <div className={"m-5"}>
      <TableContainer
        style={{
          borderRadius: 6,
        }}
      >
        <Table
          sx={{
            border: "2px solid #E8E8E8",
          }}
        >
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "#FAFAFA",
              }}
            >
              <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
                #
              </TableCell>
              <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
                Workshop
              </TableCell>
              <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
                Date Et Heure
              </TableCell>
              <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
                Lieu
              </TableCell>
              <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workshops.map((element: any, index) => (
              <TableRow key={index}>
                <StyledTableCell>{index + 1}.</StyledTableCell>
                <StyledTableCell>{element.title}</StyledTableCell>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  {convertDate(element.date)}
                </StyledTableCell>
                <StyledTableCell>{element.lieu}</StyledTableCell>
                <StyledTableCell>A venir</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
