import { useState } from "react";
import { ExploreLayout } from "../../../../component/ExploreLayout";
import { primary } from "../../../../theme/colors";
import { getAllTools } from "../../../../api/tools.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { assignToolToUser } from "../../../../api/user.service";
import { Tool } from "../../../../api/models";
import { useUserToolsQuery } from "../../../../hooks/queries";
import { toolRoute } from "../../../../routes/student.routes";
import { useNavigate } from "react-router-dom";

export enum ToolView {
  EXPLORE_TOOLS,
  MY_TOOLS,
}

export const ExploreTools = () => {
  const [view, setView] = useState<ToolView>(ToolView.EXPLORE_TOOLS);
  const toolsQuery = useQuery({
    queryKey: ["getAllTools"],
    queryFn: () => getAllTools(),
  });
  const navigate = useNavigate();
  const userToolsQuery = useUserToolsQuery();
  const assignToolToUserMutation = useMutation<any, any, { toolId: number }>({
    mutationKey: ["assignToolToUser"],
    mutationFn: (args) => {
      return assignToolToUser(args.toolId).then(() => {
        userToolsQuery.refetch().then();
      });
    },
  });

  const tools = toolsQuery.data;
  const userTools = userToolsQuery.data;
  if (!tools) return <></>;
  if (!userTools) return <></>;

  const isInMyList = (toolId: Tool["id"]) =>
    userTools.map((userTool) => userTool.id).includes(toolId);
  const myTools = tools.filter((tool) => isInMyList(tool.id));
  const otherTools = tools.filter((tool) => !isInMyList(tool.id));

  return (
    <ExploreLayout
      banner={{
        url: "/tools-bg.svg",
        textColor: primary,
      }}
      title={"Outils de calcul"}
      discoverButton={{
        isActive: view === ToolView.EXPLORE_TOOLS,
        onClick: () => setView(ToolView.EXPLORE_TOOLS),
      }}
      listButton={{
        isActive: view === ToolView.MY_TOOLS,
        number: myTools.length,
        title: "Mes Outils",
        onClick: () => setView(ToolView.MY_TOOLS),
      }}
      items={(view === ToolView.MY_TOOLS ? myTools : otherTools).map(
        (tool) => ({
          title: tool.title,
          price: tool.price,
          tag: tool.tag,
          showActionBar: isInMyList(tool.id),
          image: tool.image,
          onAdd: () => {
            assignToolToUserMutation.mutate({ toolId: tool.id });
          },
          onClick: !isInMyList(tool.id)
            ? undefined
            : () => {
                if (view === ToolView.MY_TOOLS) {
                  navigate(toolRoute(tool.id));
                }
              },
        })
      )}
    />
  );
};
