import { Alert, AlertProps, Snackbar, Typography } from "@mui/material";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

export const SnackbarContext = createContext<any>(null);

export const SnackbarProvider: FC<PropsWithChildren> = (props) => {
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertProps["severity"]>();

  return (
    <SnackbarContext.Provider
      value={{
        message: [message, setMessage],
        severity: [severity, setSeverity],
        isOpen: [isOpen, setIsOpen],
      }}
    >
      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        children={
          <Alert
            elevation={3}
            variant={"filled"}
            sx={{ width: "100%" }}
            severity={severity}
            children={
              <Typography style={{ fontFamily: "DM Sans" }}>
                {message}
              </Typography>
            }
          />
        }
      />
      <>{props.children}</>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  const [_, setMessage] = context.message;
  const [__, setSeverity] = context.severity;
  const [___, setIsOpen] = context.isOpen;

  return {
    show: (message: string, severity: AlertProps["severity"] = "info") => {
      setIsOpen(false);
      setSeverity(severity);
      setMessage(message);
      setIsOpen(true);
    },
  };
};
