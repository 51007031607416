import { createReducer } from "@reduxjs/toolkit";
import { StudentReducer } from "../redux-dto";
import {
  setCourses,
  setCurrentCourseId,
  unsetCurrentCourse,
  updateCourseAction,
} from "./actions";

export const studentReducer = createReducer<StudentReducer>(
  {
    currentCourseId: undefined,
    courses: [],
  },
  (builder) => {
    builder
      .addCase(setCurrentCourseId, (state, action) => ({
        ...state,
        currentCourseId: action.payload,
      }))
      .addCase(unsetCurrentCourse, (state) => ({
        ...state,
        currentCourseId: undefined,
      }))
      .addCase(setCourses, (state, action) => ({
        ...state,
        courses: action.payload,
      }))
      .addCase(updateCourseAction, (state, action) => {
        return {
          ...state,
          courses: state.courses.map((item) => {
            if (item.id !== action.payload.id) {
              return item;
            }

            return {
              ...item,
              ...action.payload,
            };
          }),
        };
      });
  }
);
