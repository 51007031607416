import axios from "axios";
import { Etudiant, Formation, Project, Workshop } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/api/courses`;

function toFormData(
  formateurId: number,
  title: string,
  description: string,
  availablePlaces: number,
  price: number,
  duration: string,
  tag: string,
  image: File | null
) {
  const formData = new FormData();
  formData.append("formateurId", formateurId.toString());
  formData.append("title", title);
  formData.append("description", description);
  formData.append("nombre_places_disp", availablePlaces.toString());
  formData.append("prix", price.toString());
  formData.append("duree", duration);
  formData.append("tag", tag);
  if (!!image) {
    formData.append("image", image);
  }

  return formData;
}

export function addCourse(
  formateurId: number,
  title: string,
  description: string,
  availablePlaces: number,
  price: number,
  duration: string,
  tag: string,
  image: File | null
) {
  const formData = toFormData(
    formateurId,
    title,
    description,
    availablePlaces,
    price,
    duration,
    tag,
    image
  );

  return axios.post<Formation>(`${uri}/create`, formData);
}

export function editCourse(
  id: number,
  formateurId: number,
  title: string,
  description: string,
  availablePlaces: number,
  price: number,
  duration: string,
  tag: string,
  image: File | null
) {
  const data = toFormData(
    formateurId,
    title,
    description,
    availablePlaces,
    price,
    duration,
    tag,
    image
  );
  return axios.put<Formation>(`${uri}/update/${id}`, data);
}

export const deleteCourse = (courseId: number) => {
  return axios.delete(`${uri}/delete/${courseId}`);
};

export const getStudents = (courseId: number) => {
  return axios.get<{
    course: Formation;
    students: Etudiant[];
  }>(`${uri}/${courseId}/etudiants`);
};

export const getContent = (courseId: number) => {
  return axios.get<Formation | null>(`${uri}/${courseId}/content`);
};

export const getWorkshops = (courseId: number) => {
  return axios.get<{
    course: Formation;
    workshops: Workshop[];
  }>(`${uri}/${courseId}/workshops`);
};

export const getList = (search?: string, skip?: number, take?: number) => {
  return axios
    .get<{
      count: number;
      rows: Formation[];
    }>(uri, {
      params: {
        search,
        skip,
        take,
      },
    })
    .then((response) => response.data);
};

export const findById = (id: number) => axios.get<Formation>(`${uri}/${id}`);

export const getCourseForStudent = (courseId: number) => {
  return axios.get(`${uri}/${courseId}/student/content`);
};

export const getUnassignedStudents = (
  courseId: number,
  params: {
    search?: string;
    skip?: number;
    take?: number;
  }
) => {
  return axios.get<Etudiant[]>(`${uri}/${courseId}/students/unassigned`, {
    params,
  });
};

export function getTotalProjects(course: Formation) {
  return course.chapitres.map((chapter) => chapter.project).filter(Boolean)
    .length;
}

export function getIndexOfProject(course: Formation, project: Project): number {
  return course.chapitres
    .map((chapter) => chapter.project?.id)
    .findIndex((id) => project.id === id);
}
