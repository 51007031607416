import React, { FC } from "react";
import { EtudiantProject, Project } from "../../../api/models";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemProps,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ProjectStatus } from "./ProjectStatus";
import moment from "moment";
import { capitalize } from "../../../utils/utils";

const Info: FC<{
  label: string;
  value: string;
  containerProps?: ListItemProps;
  labelProps?: TypographyProps;
  valueProps?: TypographyProps;
}> = (props) => {
  return (
    <ListItem {...props.containerProps}>
      <Typography
        {...props.labelProps}
        sx={{
          color: "#023047",
          fontWeight: "bold",
          minWidth: "150px",
          ...props.labelProps?.sx,
        }}
      >
        {props.label}:
      </Typography>
      <Typography {...props.valueProps}>{props.value}</Typography>
    </ListItem>
  );
};

export const ProjectDetails: FC<{
  chapterTitle: string;
  project: Project;
  index: number;
  total: number;
  studentProject?: EtudiantProject;
}> = (props) => {
  const dateToString = (date: any) =>
    capitalize(moment(date).format("dddd, DD MMMM YYYY, HH:mm"));

  return (
    <Stack spacing={2}>
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              color: "black",
            }}
          >
            Projet {props.index + 1}
          </Typography>
          <Typography
            color={"primary"}
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
            }}
          >
            {props.chapterTitle}
          </Typography>
        </Grid>
        <Grid item>
          <ProjectStatus
            studentProject={props.studentProject}
            project={props.project}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Box
          sx={{
            height: 1,
            borderBottom: "2px solid #e8e8e8",
            width: "90%",
          }}
        />
      </Grid>
      <Box
        sx={{
          pl: 4,
        }}
      >
        <List
          sx={{
            "& > *": {
              borderBottom: "2px solid #E8E8E8",
            },
          }}
        >
          <Info
            label={"Deadline"}
            value={
              !props.project.deadline
                ? "Non déterminé"
                : dateToString(props.project.deadline)
            }
            valueProps={{
              sx: {
                fontWeight: !props.project.deadline ? "normal" : "bold",
              },
            }}
          />
          <Info
            label={"Date de dépôt"}
            value={
              Boolean(props.studentProject)
                ? dateToString(props.studentProject?.updatedAt)
                : "Non déposé"
            }
          />
          <Info
            label={"Traité le"}
            value={
              Boolean(props.studentProject)
                ? dateToString(props.studentProject?.createdAt)
                : "Non traité"
            }
          />
        </List>
      </Box>
    </Stack>
  );
};
