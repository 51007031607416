import React, { FC, useState } from "react";
import { useSnackbar } from "../../../hooks/snackbar";
import { useMutation } from "@tanstack/react-query";
import { deleteProject } from "../../../api/student-project.service";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { bigButton, blackAndWhite } from "../../../styles";

export const buttonStyle: ButtonProps["sx"] = {
  textTransform: "none",
  fontWeight: "bold",
  color: "white",
  backgroundColor: "black",
  border: "1px solid black",
  borderRadius: "6px",
  width: "100%",
  minWidth: 150,
  px: 5,
  "&:hover": {
    color: "white",
    backgroundColor: "black",
  },
};

export const ProjectDelete: FC<{
  studentCourseId: number;
  onDelete: () => void;
}> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const snackbar = useSnackbar();
  const mutation = useMutation({
    mutationKey: ["deleteStudentProject", props.studentCourseId],
    mutationFn: () =>
      deleteProject(props.studentCourseId)
        .then(() => {
          props.onDelete();
          setIsOpen(false);
        })
        .catch((e) => {
          let message =
            e.response.data?.message || "Erreur lors de la suppression";
          snackbar.show(message, "error");
        }),
  });
  return (
    <>
      <Button
        sx={{
          ...buttonStyle,
        }}
        children={"Supprimer"}
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <Dialog
        maxWidth={"xs"}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DialogTitle>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: "bold",
            }}
            color={"primary"}
          >
            Suppression du projet
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Voulez-vous vraiment supprimer le project, cette action est
            définitive.
            <br />
            Vous devez déposer votre projet de nouveau.
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={mutation.isLoading}
            fullWidth
            disableElevation
            {...blackAndWhite}
            onClick={() => {
              mutation.mutate();
            }}
          >
            Oui
          </LoadingButton>
          <Button
            sx={{
              ...bigButton.sx,
              color: "black",
              borderColor: "black",
            }}
            fullWidth
            variant={"outlined"}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
