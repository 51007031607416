import Grid from "@mui/material/Grid";
import React, { FC, ReactElement } from "react";
import { Typography } from "@mui/material";

export const MessagePage: FC<{
  icon: ReactElement;
  message: string;
}> = (props) => {
  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{
        height: "100vh",
      }}
    >
      {props.icon}
      <Typography variant={"h3"}>{props.message}</Typography>
    </Grid>
  );
};
