import { Outlet } from "react-router-dom";
import { CourseIcon } from "../../icons/CourseIcon";
import { CalendarIcon } from "../../icons/CalendarIcon";
import { DashboardIcon } from "../../icons/DashboardIcon";
import { UserLayout } from "../../component/UserLayout";
import { OneToOneIcon } from "../../icons/OneToOneIcon";
import { CommunityIcon } from "../../icons/CommunityIcon";
import {
  clubList,
  courseList,
  instructorList,
  listStudents,
  recruiterList,
} from "../../routes/admin.routes";

const menuItems = [
  {
    text: "Formateurs",
    icon: <DashboardIcon />,
    link: instructorList,
  },
  {
    text: "Étudiants",
    icon: <CourseIcon />,
    link: listStudents,
  },
  {
    text: "Formation",
    icon: <CalendarIcon />,
    link: courseList,
  },
  {
    text: "Recruteurs",
    icon: <OneToOneIcon />,
    link: recruiterList,
  },
  {
    text: "Clubs",
    icon: <CommunityIcon />,
    link: clubList,
  },
];
export function AdminLayout() {
  return (
    <UserLayout
      logoLink={instructorList}
      menuItems={menuItems}
      content={<Outlet />}
      toolbar={<div></div>}
    />
  );
}
