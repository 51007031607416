const instructor = `/instructor`;
export const account = `${instructor}/account`;
export const courseList = `${instructor}/courses`;
export const courseStudents = (courseId: number) =>
  `${courseList}/${courseId}/students`;
export const courseContent = (courseId: number) =>
  `${courseList}/${courseId}/content`;
export const students = `${instructor}/students`;
export const oneToOne = `${instructor}/one-to-one`;
export const oneToOnePlanning = `${oneToOne}/planning`;

export const workshops = `${instructor}/workshops`;
export const workshopOfCourse = (courseId: number) =>
  `${workshops}/${courseId}`;
export const projects = `${instructor}/projects`;
export const calendar = `${instructor}/calendar`;
