import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCourse } from "../../../api/student.service";
import { Loader } from "../../../component/Loader";
import { useSnackbar } from "../../../hooks/snackbar";
import { CourseMenu } from "./components/CourseMenu";
import { useSider } from "../../../hooks/hooks";
import { useEffect, useState } from "react";
import {
  CursorType,
  Formation,
  Page,
  Qcm,
  StudentQuiz,
} from "../../../api/models";
import { CourseSelection, IMenuItem } from "./dto";
import { CourseContent } from "./components/CourseContent";
import { useFolding, useUpdateCurrentCourseCursor } from "./hooks";
import {
  getConcreteSelections,
  getIndexes,
  getNextSelection,
  isConcrete,
  nonConcreteItems,
} from "../../../api/course-content.service";
import { useCurrentCourse } from "../../../hooks/student/hooks";
import { getFirstChild } from "../../../api/subchapter.service";
import { createFromPage, createFromQuiz } from "../../../api/course-selection";
import { emitEvent } from "../../../api/student-course.service";

export const CoursePage = () => {
  useSider();

  const [items, setItems] = useState<IMenuItem[]>([]);
  const courseId = useSelector(
    (root: RootState) => root.student.currentCourseId
  );
  const snackbar = useSnackbar();
  const [selection, setSelection] = useState<CourseSelection | undefined>();
  const [course, setCourse] = useState<Formation | undefined>();
  const currentCourse = useCurrentCourse();
  const updateCursor = useUpdateCurrentCourseCursor();
  const emitEventMutation = useMutation<unknown, unknown, { pageId: number }>({
    mutationKey: ["emitStudentCourseEvent", course?.id],
    mutationFn: (args) => emitEvent(course!!.id, "consult_page", args.pageId),
  });

  useFolding({
    content: course,
    onChangeItems: setItems,
    onChangeSelection: (selection) => {
      if (selection.type === "subchapter") {
        const child = getFirstChild(selection.value);
        if (child?.type === "page" && !!selection) {
          setSelection(createFromPage(child.value as Page, 0));
        } else if (child?.type === "quiz") {
          setSelection(createFromQuiz(child.value as Qcm));
        }
      } else {
        setSelection(selection);
      }
    },
    onClick: () => {},
  });

  useEffect(() => {
    if (!!currentCourse && !!course) {
      const concreteSelections = getConcreteSelections(course);
      const selectionIndex = concreteSelections.findIndex(
        (courseSelection) =>
          courseSelection.type ===
            currentCourse.formation_etudiant.cursorType &&
          courseSelection.id === currentCourse.formation_etudiant.cursorId
      );
      const nextSelection = concreteSelections[Math.max(0, selectionIndex)];
      if (selection?.type !== "workshop" && selection?.type !== "conclusion") {
        setSelection(nextSelection);
      }
    }
  }, [currentCourse, course]);

  const query = useQuery({
    queryKey: ["getStudentCourseContent", courseId],
    queryFn: () =>
      getCourse(courseId as number)
        .then((response) => {
          setCourse({
            ...currentCourse,
            ...response.data.content,
          });
          return response.data;
        })
        .catch((response) => {
          const message =
            response.data?.message || "Erreur lors du chargement du contenu";
          snackbar.show(message, "error");
        }),
    enabled: typeof courseId !== "undefined" && !!currentCourse,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!!currentCourse && !!course) {
      setCourse((course) => ({
        ...course,
        ...currentCourse,
      }));
    }
  }, [currentCourse]);

  if (query.isLoading) {
    return <Loader sx={{ minHeight: "70vh" }} />;
  }
  if (!query.data || !course) {
    return <></>;
  }
  const {
    progression,
    studentProjects,
    studentQuizzes,
    studentWorkshopAttendance,
  } = query.data;

  const goNext = () => {
    const nextSelection = getNextSelection(course, selection);
    if (!nextSelection) return;

    const { indexOfSelection, indexOfCursor } = getIndexes({
      course: course,
      currentSelection: nextSelection,
    });

    const hasNextSelection = !!nextSelection;
    if (hasNextSelection && indexOfSelection >= indexOfCursor) {
      updateCursor.mutation.mutate({
        courseId: course.id,
        cursorType: nextSelection.type as CursorType,
        cursorId: nextSelection.id,
      });
      setSelection(nextSelection);
    }
  };

  const setNextSelection = () => {
    const nextSelection = getNextSelection(course, selection);
    if (!nextSelection) return;
    setSelection(nextSelection);
  };

  const onQuizSuccess = (studentQuiz: StudentQuiz) => {
    if (selection?.type !== "quiz" || !course || !selection) {
      return;
    }
    goNext();
  };

  const onQuizFail = (studentQuiz: StudentQuiz) => {
    query.refetch().then();
  };

  const onSubchapterChangeIndex = (nextSelection: CourseSelection) => {
    if (!selection) return;
    const { indexOfCursor, indexOfSelection } = getIndexes({
      course,
      currentSelection: nextSelection,
    });

    if (
      indexOfSelection > indexOfCursor &&
      !nonConcreteItems.includes(nextSelection.type) &&
      nextSelection.type === "page"
    ) {
      emitEventMutation.mutate({ pageId: nextSelection.id });
    }
    if (indexOfSelection >= indexOfCursor && isConcrete(nextSelection)) {
      updateCursor.mutation.mutate({
        courseId: course.id,
        cursorId: nextSelection.id,
        cursorType: nextSelection.type as CursorType,
      });
    }
    setSelection(nextSelection);
  };

  return (
    <Stack
      direction={"row"}
      sx={{
        height: "100%",
        maxHeight: "calc(100vh - 80px)",
      }}
    >
      <Box
        sx={{
          width: 300,
          height: "100%",
        }}
      >
        <CourseMenu
          selection={selection}
          items={items}
          course={course}
          progression={progression}
        />
      </Box>
      <Box sx={{ flex: 1, px: 2, py: 4 }}>
        <CourseContent
          course={course}
          studentQuizzes={query.data?.studentQuizzes}
          selection={selection}
          studentProjects={studentProjects}
          studentWorkshopAttendance={studentWorkshopAttendance}
          onUpdateProject={() => {
            query.refetch().then();
          }}
          onQuizSuccess={onQuizSuccess}
          onQuizFail={onQuizFail}
          onSubchapterChangeIndex={onSubchapterChangeIndex}
          onQuizNext={setNextSelection}
          onProjectNext={goNext}
        />
      </Box>
    </Stack>
  );
};
