import { FC } from "react";
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Typography,
} from "@mui/material";

export const CourseProgression: FC<{
  progression: number;
}> = (props) => {
  return (
    <Box
      px={2}
      py={2}
      sx={{
        borderBottom: "1px solid #e6e6e6",
      }}
    >
      <Stack spacing={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
            Progression
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
            {props.progression}%
          </Typography>
        </Stack>
        <LinearProgress
          sx={{
            borderRadius: "6px",
            height: 12,
            [`&.${linearProgressClasses.colorPrimary}`]: (theme) => ({
              backgroundColor: "#E8E8E8",
            }),
            [`& .${linearProgressClasses.bar}`]: (theme) => ({
              backgroundColor: theme.palette.primary.main,
            }),
          }}
          variant={"determinate"}
          value={props.progression}
        />
      </Stack>
    </Box>
  );
};
