import {
  FETCH_CHAPITRES,
  FETCH_FORMATION,
  LAST_FORMATION,
} from "../actions/types";

export const formationEtudiant = (
  state = { formation: null, lastFormation: {} },
  action
) => {
  switch (action.type) {
    case FETCH_FORMATION:
      return {
        ...state,
        formation: action?.payload,
      };
    case FETCH_CHAPITRES:
      return {
        ...state,
        ...state.formation,
        formation: { chapitres: { souschapitres: [action?.payload] } },
      };
    case LAST_FORMATION:
      return {
        ...state,
        lastFormation: action?.payload?.data,
      };
    default:
      return state;
  }
};
