import axios from "axios";
import { Tool } from "./models";
import { baseUrl } from "../env";

export const tools = [
  {
    title: "Simply Supported Beam at Both Ends",
    price: 200,
    tag: "Structural Computation",
    inMyList: true,
    id: 1,
    image: "https://www.geotashfeen.tech/images/poutre_iso.jpg",
  },
  {
    title: "Hyperstatic Beam on 3 supports",
    price: 0,
    tag: "Structural Computation",
    inMyList: false,
    id: 2,
    image: "https://www.geotashfeen.tech/images/poutre_hyper.jpg",
  },
  {
    title: "Embankment",
    price: 0,
    tag: "Geotechnical Computation",
    inMyList: false,
    id: 3,
    image: "https://www.geotashfeen.tech/images/remblai.jpg",
  },
  {
    title: "Circular Footings",
    price: 0,
    tag: "Geotechnical Computation",
    inMyList: false,
    id: 4,
    image: "https://www.geotashfeen.tech/images/cylindre.jpg",
  },
];

const uri = `${baseUrl}/api/tools`;
export const getAllTools = () =>
  axios.get<Tool[]>(uri).then((response) => response.data);

export const findToolById = (id: number) =>
  axios.get<Tool>(`${uri}/${id}`).then((response) => response.data);
