import { QUIZ } from "../actions/types";

export const quizEtudiant = (state = [], action) => {
  switch (action.type) {
    case QUIZ:
      return action?.payload;
    default:
      return state;
  }
};
