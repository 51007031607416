import { FC } from "react";
import { TableCell, TableCellProps } from "@mui/material";

export const StyledTableCell: FC<TableCellProps> = (props) => {
  return (
    <TableCell
      {...props}
      style={{
        borderBottom: "2px solid #E8E8E8",
        color: "#023047",
        fontSize: "16px",
        ...props.style,
      }}
    />
  );
};

export const HeaderCell: FC<TableCellProps> = (props) => (
  <TableCell
    style={{ color: "#048B9A", fontWeight: "bold", ...props.style }}
    {...props}
  />
);
