import { useEffect, useState } from "react";
import { CursorType, Formation } from "../../../api/models";
import { CourseSelection, IMenuItem } from "./dto";
import { getFirstPage } from "../../../api/chapter.service";
import { useCurrentCourse } from "../../../hooks/student/hooks";
import {
  canAccess,
  getSelections,
  nonConcreteItems,
} from "../../../api/course-content.service";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { updateCursor as _updateCursor } from "../../../api/student-course.service";
import { updateCourseAction } from "../../../reducers/student/actions";

function getMenuItems(
  course: Formation,
  onClick: (item: CourseSelection) => void
): IMenuItem[] {
  return getSelections(course).map((item) => ({
    ...item,
    onClick: () => onClick(item),
  }));
}

export const useFolding = (props: {
  content?: Formation;
  onChangeItems: (items: IMenuItem[]) => void;
  onChangeSelection: (selection: CourseSelection) => void;
  onClick: (target: { chapterId: number; isFold: boolean }) => void;
}) => {
  const { content, onChangeSelection, onChangeItems } = props;
  const course = useCurrentCourse();
  const [foldedChapters, setFoldedChapters] = useState<number[]>([]);
  const isChapterFold = (chapterId: number) =>
    foldedChapters.includes(chapterId);
  const reverseFolding = (chapterId: number) => {
    let isFold = true;
    if (isChapterFold(chapterId)) {
      isFold = false;
      setFoldedChapters(foldedChapters.filter((id) => chapterId !== id));
    } else {
      setFoldedChapters((foldedChapters) => [...foldedChapters, chapterId]);
    }
    props.onClick({
      chapterId,
      isFold,
    });
  };

  const openFirstPageInChapter = (chapterId: number) => {
    const chapter = content!!.chapitres.find((c) => c.id === chapterId);
    const firstPage = getFirstPage(chapter!!);
    const hasFirstPage = !!firstPage;

    if (hasFirstPage) {
      props.onChangeSelection({
        type: "page",
        value: firstPage,
        id: firstPage.id,
        title: "Page",
      });
    }
  };

  const onItemClick = (item: CourseSelection) => {
    let changeSelection = true;

    if (item.type === "chapter") {
      if (isChapterFold(item.id)) {
        changeSelection = false;
        openFirstPageInChapter(item.id);
      }
      reverseFolding(item.id);
    } else if (
      !course ||
      !canAccess({
        currentSelection: item,
        course: { ...course, ...content },
      })
    ) {
      changeSelection = false;
    }
    if (changeSelection) {
      onChangeSelection(item);
    }
  };

  function handleSetItems() {
    if (!!content) {
      const menuItems = getMenuItems(content, onItemClick).filter((item) => {
        const conditionsToFold = [
          item.type === "subchapter" &&
            foldedChapters.includes(item.value.chapitreId),
          item.type === "workshop" &&
            foldedChapters.includes(item.value.ChapitreId),
          item.type === "conclusion" && foldedChapters.includes(item.value.id),
          item.type === "project" &&
            foldedChapters.includes(item.value.ChapitreId),
        ];

        return conditionsToFold.every((condition) => !condition);
      });
      onChangeItems(menuItems);
    }
  }

  useEffect(() => {
    handleSetItems();
  }, [content, foldedChapters]);
};

export function useUpdateCurrentCourseCursor() {
  const currentCourse = useCurrentCourse();
  const dispatch = useDispatch();
  const mutation = useMutation<
    unknown,
    unknown,
    {
      courseId: number;
      cursorId: number;
      cursorType: CursorType;
    }
  >({
    mutationKey: ["updateCursor"],
    mutationFn: (args) => {
      return _updateCursor(args.courseId, args.cursorId, args.cursorType)
        .catch(() => {})
        .then(() => {
          dispatch(
            updateCourseAction({
              ...currentCourse,
              formation_etudiant: {
                ...currentCourse.formation_etudiant,
                cursorId: args.cursorId,
                cursorType: args.cursorType,
              },
            })
          );
        });
    },
  });

  const updateCursor = (course: Formation, selection: CourseSelection) => {
    const isConcrete = !nonConcreteItems.includes(selection.type);
    if (!isConcrete) return;

    mutation.mutate({
      courseId: course!!.id,
      cursorId: selection.id,
      cursorType: selection.type as CursorType,
    });
  };

  return {
    mutation,
    updateCursor,
  };
}
