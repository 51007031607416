export const admin = `/admin`;
export const listStudents = `${admin}/list-students`;
export const addStudent = `${listStudents}/add`;
export const instructorList = `${admin}/list-formers`;
export const addInstructor = `${instructorList}/ajouter`;
export const studentList = `${admin}/list-students`;
export const courseList = `${admin}/list-formations`;
export const editCourse = (id: number) => `${courseList}/${id}/edit`;
export const addCourse = `${courseList}/ajout`;
export const recruiterList = `${admin}/recruiters`;
export const clubList = `${admin}/clubs`;
