import React, { FC, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "../../student/workshop/Workshop";
import PersonAddAltSharpIcon from "@mui/icons-material/PersonAddAltSharp";
import { Add, ArrowBackIos } from "@mui/icons-material";
import RestoreIcon from "@mui/icons-material/Restore";
import moment from "moment";
import {
  getAll as getAllSubscriptionRequests,
  validationDemande,
} from "../../../api/DemandeInscrit.service";
import { DemandeInscription, Etudiant } from "../../../api/models";
import { useMutation, useQuery } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { getAll as getAllStudents } from "../../../api/student.service";
import { useSnackbar } from "../../../hooks/snackbar";
import Avatar from "@mui/material/Avatar";
import { getUrl } from "../../../utils/utils";
import { SearchInput } from "../../../component/SearchInput";
import { Link } from "react-router-dom";
import { addStudent } from "../../../routes/admin.routes";
import { DEFAULT_PAGE, ROWS_PER_PAGE } from "../../../utils/constants";

const formatDate = (date: string) => moment(date).format("DD MMMM YYYY");

export const StudentsTable: FC<{
  students: Etudiant[];
}> = (props) => {
  return (
    <TableContainer
      style={{
        borderRadius: 6,
      }}
    >
      <Table
        sx={{
          border: "2px solid #E8E8E8",
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#FAFAFA",
              "& th": {
                color: "#0c8e9d",
                fontWeight: "bold",
                fontSize:"1rem",
                padding:"4px 15px"
              },
            }}
          >
            <TableCell>Nom complet</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Adresse</TableCell>
            <TableCell>Cin</TableCell>
            <TableCell>Téléphone</TableCell>
            <TableCell>Formations</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.students.map((student) => {
            return (
              <TableRow key={student.id}>
                <StyledTableCell>
                  <div>
                    <div
                      style={{ display: "flex", gap: 8, alignItems: "center" }}
                    >
                      <Avatar src={getUrl(student.user.userImage)} />
                      <div>{student.user.nomComplet}</div>
                    </div>
                  </div>
                </StyledTableCell>
                <StyledTableCell>{student.user.email}</StyledTableCell>
                <StyledTableCell>{student?.user.adresse}</StyledTableCell>
                <StyledTableCell>{student.user.cin}</StyledTableCell>
                <StyledTableCell>{student.user.tel}</StyledTableCell>
                <StyledTableCell>
                  <ul>
                    {student.formations?.map((course) => (
                      <li key={[student.id, course.id].join(",")}>
                        {course.title}
                      </li>
                    ))}
                  </ul>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SubscriptionsRequestTable: FC<{
  requests: DemandeInscription[];
  treated: boolean | null;
  onTreat?: () => void;
}> = (props) => {
  const mutation = useMutation({
    mutationKey: ["answerSubscriptionRequest"],
    mutationFn: (values: { id: number; accepterStatus: boolean }) => {
      return validationDemande({
        id: values.id,
        accepterStatus: values.accepterStatus,
      }).then(() => {
        props.onTreat?.();
      });
    },
  });

  return (
    <TableContainer
      style={{
        borderRadius: 6,
      }}
    >
      <Table
        sx={{
          border: "2px solid #E8E8E8",
          "& th": {
            color: "#048B9A !important",
            fontWeight: "bold !important",
          },
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#FAFAFA",
            }}
          >
            <TableCell>Date</TableCell>
            <TableCell>Nom</TableCell>
            <TableCell>Prénom</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Adresse</TableCell>
            <TableCell>Cin</TableCell>
            <TableCell>Téléphone</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.requests
            .filter(
              (element) =>
                element.accepter === props.treated || props.treated === true
            )
            .map((element, index: number) => (
              <TableRow key={index}>
                <StyledTableCell>
                  {formatDate(element.createdAt) || "NAN"}
                </StyledTableCell>
                <StyledTableCell>{element.nom || "NAN"}</StyledTableCell>
                <StyledTableCell>{element.prenom || "NAN"}</StyledTableCell>
                <StyledTableCell>{element.email || "NAN"}</StyledTableCell>
                <StyledTableCell>{element.adresse || "NAN"}</StyledTableCell>
                <StyledTableCell>{element.cin || "NAN"}</StyledTableCell>
                <StyledTableCell>{element.tel || "NAN"}</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant={"contained"}
                    style={{
                      backgroundColor:
                        props.treated === null
                          ? "#121212"
                          : element.accepter
                          ? "#121212"
                          : "#E8E8E8",
                      color: props.treated === true ? "white" : "white",
                      cursor: props.treated !== null ? "default" : "pointer",
                    }}
                    onClick={() => {
                      if (props.treated === null) {
                        mutation.mutate({
                          id: element.id,
                          accepterStatus: true,
                        });
                      }
                    }}
                  >
                    <span
                      style={{
                        fontSize: ".95rem",
                      }}
                      className={"mb-0"}
                    >
                      {props.treated === true ? "Validé" : "Valider"}
                    </span>
                  </Button>
                  <LoadingButton
                    loading={mutation.isLoading}
                    variant={"contained"}
                    style={{
                      backgroundColor:
                        props.treated === null
                          ? "#707070"
                          : element.accepter
                          ? "transparent"
                          : "#707070",
                      border:
                        props.treated === null
                          ? "0px"
                          : element.accepter
                          ? "1px solid #121212"
                          : "0px",
                      color:
                        props.treated === null
                          ? "white"
                          : element.accepter
                          ? "#121212"
                          : "white",
                      cursor: props.treated !== null ? "default" : "pointer",
                    }}
                    onClick={() => {
                      if (props.treated === null) {
                        mutation.mutate({
                          id: element.id,
                          accepterStatus: false,
                        });
                      }
                    }}
                  >
                    <span
                      style={{
                        fontSize: ".95rem",
                      }}
                      className={"mb-0"}
                    >
                      {props.treated === true ? "Rejeté" : "Rejeter"}
                    </span>
                  </LoadingButton>
                </StyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export function ListStudents() {
  const snackbar = useSnackbar();
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [search, setSearch] = useState("");
  const studentsQuery = useQuery({
    queryKey: ["list-students", { search, rowsPerPage, page }],
    queryFn: () =>
      getAllStudents({
        skip: page * rowsPerPage,
        take: rowsPerPage,
        search,
      })
        .then((response) => response.data)
        .catch(() => {
          snackbar.show("Erreur lors du chargement des étudiants", "error");
        }),
  });

  const requestsQuery = useQuery({
    queryKey: ["list-subscription-requests"],
    queryFn: () =>
      getAllSubscriptionRequests()
        .then((response) => response.data)
        .catch(() => {
          snackbar.show(
            "Erreur lors du chargement des demandes d'inscription",
            "error"
          );
        }),
  });
  const [requestSection, setRequestSection] = useState(false);
  const treatedRequests =
    requestsQuery.data?.filter((a) => a.accepter === null) || [];
  const requestNumber = treatedRequests.length;
  const students = studentsQuery.data?.rows || [];
  const count = studentsQuery.data?.count || 0;

  useEffect(() => {
    setPage(0);
  }, [search]);

  return (
    <div className={"m-5"}>
      <Grid
        container
        justifyContent={"space-between"}
        spacing={4}
        alignItems={"flex-end"}
      >
        {!requestSection && (
          <Grid item>
            <Badge
              overlap="rectangular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              color="error"
              badgeContent={requestNumber}
            >
              <Button
                variant={"contained"}
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#121212",
                  color: "white",
                }}
                onClick={() => {
                  setRequestSection(true);
                }}
              >
                <span
                  style={{
                    fontSize: ".95rem",
                  }}
                  className={"mb-0"}
                >
                  <PersonAddAltSharpIcon /> Demande d'inscription
                </span>
              </Button>
            </Badge>
          </Grid>
        )}
        {!requestSection && (
          <Stack direction={"row"} spacing={2}>
            <Link to={addStudent}>
              <IconButton
                sx={{
                  background: (theme) => theme.palette.primary.main,
                  color: "white",
                  "&:hover": {
                    background: (theme) => theme.palette.primary.main,
                    color: "white",
                  },
                }}
              >
                <Add />
              </IconButton>
            </Link>
            <SearchInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Stack>
        )}
        {requestSection && (
          <Grid item>
            <Button
              variant={"contained"}
              style={{
                textTransform: "capitalize",
                backgroundColor: "#121212",
                color: "white",
              }}
              className={"btn"}
              onClick={() => {
                setRequestSection(false);
              }}
            >
              <span
                style={{
                  fontSize: ".95rem",
                }}
                className={"mb-0"}
              >
                <ArrowBackIos /> Retour
              </span>
            </Button>
          </Grid>
        )}
        {!requestSection && (
          <Grid item xs={12}>
            <StudentsTable students={students} />
            <TablePagination
              component={"div"}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={(event, page) => {
                setPage(page);
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
              }}
            />
          </Grid>
        )}
        {requestSection && (
          <>
            <Grid item xs={12}>
              <SubscriptionsRequestTable
                requests={(requestsQuery.data || []).filter(
                  (request) => request.accepter === null
                )}
                treated={null}
                onTreat={() => {
                  requestsQuery.refetch().then();
                }}
              />
            </Grid>

            <Grid item xs={12} className={"mt-3"}>
              <div className={"d-flex fw-bold"} style={{ color: "#048B9A" }}>
                <RestoreIcon />
                <p className={"ml-1"}>Demandes Traitées</p>
              </div>
            </Grid>

            <Grid item xs={12}>
              <SubscriptionsRequestTable
                requests={(requestsQuery.data || []).filter(
                  (request) => request.accepter !== null
                )}
                treated={true}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
