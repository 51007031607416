import { AxiosRequestHeaders, default as axios } from "axios";
import { DisponibiliteOneToOne, Formation, User } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/api/courses`;

export function getAuthHeaders(): AxiosRequestHeaders {
  const minTokenLength = 1;

  try {
    const accessToken = localStorage.getItem("accessToken") || "{}";

    if (
      !(typeof accessToken === "string" && accessToken.length >= minTokenLength)
    ) {
      throw new Error("Invalid user access token");
    }

    return { "x-access-token": accessToken };
  } catch (e) {
    return {};
  }
}

export function getFormationFormateur(idFormation: number) {
  return axios
    .get<Pick<User, "nom" | "prenom" | "id">>(
      `${uri}/getFormateurFormation/${idFormation}`
    )
    .then((data) => {
      return data.data;
    });
}

export function getLastFourSouschapiterDone(formationId: number) {
  return axios
    .get(`${uri}/get_last_four_souschapiter_done/${formationId}`, {
      headers: getAuthHeaders(),
    })
    .then((data) => {
      return data.data;
    });
}
export function getOneToOnes(courseId: number) {
  return axios.get<DisponibiliteOneToOne[]>(`${uri}/${courseId}/oneToOnes`);
}

export function getGeneralProgressionOnlyByStudent(formationId: number) {
  return axios
    .get(
      `${uri}/get_general_progression_only_formation_by_student/${formationId}`,
      {
        headers: getAuthHeaders(),
      }
    )
    .then((data) => {
      return data.data;
    });
}

export function getCountSousChapitreDoneFormationByStudent(
  formationId: number
) {
  return axios
    .get(
      `${uri}/count_sous_chapitres_done_formation_by_student/${formationId}`,
      {
        headers: getAuthHeaders(),
      }
    )
    .then((data) => {
      return data.data;
    });
}

export function getAll() {
  return axios.get<Formation[]>(`${uri}/all`).then((response) => response.data);
}
