import { forwardRef } from "react";
import { TextField, TextFieldProps, Typography } from "@mui/material";

export const CInput = forwardRef<unknown, TextFieldProps>(
  ({ label, ...props }, ref) => {
    return (
      <div>
        <label style={{ width: "100%" }}>
          <Typography>{label}</Typography>
          <TextField
            size={"small"}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #E8E8E8",
                  borderRadius: 2,
                },
                "&:hover fieldset": {},
                "&.Mui-focused fieldset": {},
              },
            }}
            ref={ref as any}
            {...props}
          />
        </label>
      </div>
    );
  }
);
