import axios from "axios";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { User } from "../api/models";

let user: any;
try {
  user = JSON.parse(localStorage.getItem("profile") || "");
} catch (e) {}

const accessToken = localStorage.getItem("accessToken");

export const logoutAction = createAction("logout");
export const loginSuccessAction = createAction<{
  data: User;
  accessToken: string;
}>("login_success");
export const loginFailAction = createAction("login_fail");
export const updateUserAction = createAction<{
  user: User;
}>("update_user");

export const authReducer = createReducer<{
  user: User | null;
  isLoggedIn: boolean;
  accessToken: string | null;
}>(
  {
    user: !!user ? user : null,
    isLoggedIn: !!user,
    accessToken: accessToken || null,
  },
  (builder) => {
    builder
      .addCase(logoutAction, (state, action) => {
        localStorage.clear();
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          accessToken: null,
        };
      })
      .addCase(loginSuccessAction, (state, action) => {
        localStorage.setItem("profile", JSON.stringify(action.payload.data));
        const { accessToken } = action.payload;
        axios.defaults.headers.common["x-access-token"] = accessToken;
        localStorage.setItem("accessToken", accessToken);
        return {
          ...state,
          isLoggedIn: true,
          user: action?.payload.data,
          accessToken: accessToken,
        };
      })
      .addCase(loginFailAction, (state) => {
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          accessToken: null,
        };
      })
      .addCase(updateUserAction, (state, action) => {
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload.user,
          },
        };
      });
  }
);
