import * as React from "react";
import { SVGProps } from "react";

export const BagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Group_386"
    {...props}
    data-name="Group 386"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="21.953"
    height="18.288"
    viewBox="0 0 21.953 18.288"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_218"
          data-name="Rectangle 218"
          width="21.953"
          height="18.288"
          fill="#023047"
        />
      </clipPath>
    </defs>
    <g id="Group_385" data-name="Group 385" clipPath="url(#clip-path)">
      <path
        id="Path_3759"
        data-name="Path 3759"
        d="M0,160.966v-7.288a.565.565,0,0,1,.063.014q5.084,1.693,10.167,3.39a2.232,2.232,0,0,0,1.467.009q5.011-1.674,10.025-3.338l.217-.07c.006.048.011.069.011.09,0,2.322.008,4.643-.011,6.965a2.33,2.33,0,0,1-.223.948,2.469,2.469,0,0,1-2.421,1.48q-8.3-.007-16.608,0c-.1,0-.2,0-.3,0a2.518,2.518,0,0,1-2.291-1.822c-.034-.123-.063-.247-.095-.371"
        transform="translate(0 -144.877)"
        fill="#023047"
      />
      <path
        id="Path_3760"
        data-name="Path 3760"
        d="M0,4.943c.038-.144.071-.29.116-.432A2.5,2.5,0,0,1,2.536,2.748c1.5-.006,3,0,4.5,0h.284c0-.334-.007-.646,0-.957A1.8,1.8,0,0,1,8.78.04,1.875,1.875,0,0,1,9.163,0C10.371,0,11.578,0,12.786,0a1.8,1.8,0,0,1,1.807,1.464,2.59,2.59,0,0,1,.035.469c.005.256,0,.513,0,.808h.26q2.218,0,4.437,0a2.507,2.507,0,0,1,2.623,2.63c0,.593,0,1.186,0,1.779a.223.223,0,0,1-.178.259q-5.25,1.742-10.5,3.486a.987.987,0,0,1-.579.007Q5.374,9.151.058,7.372A.465.465,0,0,1,0,7.344v-2.4M9.153,2.73h3.64V1.844H9.153Z"
        transform="translate(0 0)"
        fill="#023047"
      />
      <rect
        id="Rectangle_217"
        data-name="Rectangle 217"
        width="3.64"
        height="0.885"
        transform="translate(9.153 1.844)"
        fill="#023047"
      />
    </g>
  </svg>
);
