import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { login } from "../unauth.routes";
import { LoginPage } from "../../pages/login/LoginPage";
import { admin } from "../admin.routes";
import { ProtectedRoute } from "../../component/ProtectedRoute";
import { UserRole } from "../../api/models";
import { AdminLayout } from "../../pages/admin/AdminLayout";
import { AccountSettings } from "../../pages/account/AccountSettings";
import { InstructorList } from "../../pages/admin/instructors/InstructorListPage";
import { AddInstructorPage } from "../../pages/admin/instructors/add/AddInstructorPage";
import { ListStudents } from "../../pages/admin/students/StudentListPage";
import { StudentAddPage } from "../../pages/admin/students/add/StudentAddPage";
import { CourseListPage } from "../../pages/admin/courses/list/CourseListPage";
import { CourseEditPage } from "../../pages/admin/courses/edit/CourseEditPage";
import { CourseAddPage } from "../../pages/admin/courses/new/CourseAddPage";
import { RecruitersPage } from "../../pages/admin/recruiters/RecruitersPage";
import { ClubsPage } from "../../pages/admin/clubs/ClubListPage";
import { StudentHome } from "../../pages/student/home/StudentHome";
import { ExploreCourses } from "../../pages/student/explore/courses/ExploreCourses";
import { ExploreTools } from "../../pages/student/explore/tools/ExploreTools";
import { ToolsPage } from "../../pages/student/tools/ToolsPage";
import { StudentLayout } from "../../pages/student/StudentLayout";
import { Dashboard } from "../../pages/student/dashboard/Dashboard";
import { Calendar } from "../../pages/student/calendar/Calendar";
import { Projects } from "../../pages/student/projects/Projects";
import { OneToOnePage } from "../../pages/student/one-to-one/OneToOne";
import { Workshop } from "../../pages/student/workshop/Workshop";
import { Community } from "../../pages/student/community/Community";
import { Store } from "../../pages/student/store/Store";
import { InstructorLayout } from "../../pages/instructor/InstructorLayout";
import { CoursesPage } from "../../pages/instructor/courses/CoursesPage";
import { StudentsPage as StudentsOfCourse } from "../../pages/instructor/courses/course/StudentsPage";
import { CourseContent } from "../../pages/instructor/courses/course/ContentPage";
import { StudentsPage } from "../../pages/instructor/students/StudentsPage";
import { OneToOnePage as OneToOneOfInstructor } from "../../pages/instructor/one-to-one/OneToOnePage";
import { OneToOnePlanningPage } from "../../pages/instructor/one-to-one/planification/OneToOnePlanningPage";
import { WorkshopsPage } from "../../pages/instructor/workshops/WorkshopsPage";
import { CourseWorkshopsPage } from "../../pages/instructor/workshops/workshop/CourseWorkshopsPage";
import { ProjectsPage } from "../../pages/instructor/projects/ProjectsPage";
import { CalendarPage } from "../../pages/instructor/calendar/CalendarPage";
import { Subscription } from "../../pages/subscription/SubscriptionPage";
import { NotFoundPage } from "../../pages/not-found/NotFoundPage";
import React from "react";
import { Redirect } from "./Redirect";
import { CoursePage } from "../../pages/student/course/CoursePage";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={login} element={<LoginPage />} />
        <Route
          path={admin}
          element={
            <ProtectedRoute role={UserRole.ADMIN}>
              <AdminLayout />
            </ProtectedRoute>
          }
        >
          <Route path={"account"} element={<AccountSettings />} />
          <Route path="list-formers">
            <Route path={""} element={<InstructorList />} />
            <Route path={"ajouter"} element={<AddInstructorPage />} />
          </Route>
          <Route path="list-students">
            <Route path={""} element={<ListStudents />} />
            <Route path={"add"} element={<StudentAddPage />} />
          </Route>
          <Route path={"list-formations"}>
            <Route path={""} element={<CourseListPage />} />
            <Route path={":id/edit"} element={<CourseEditPage />} />
            <Route path={"ajout"} element={<CourseAddPage />} />
          </Route>
          <Route path={"recruiters"} element={<RecruitersPage />} />
          <Route path={"clubs"} element={<ClubsPage />} />
        </Route>
        <Route
          path={"/etudiant"}
          element={
            <ProtectedRoute role={UserRole.STUDENT}>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route path={"home"} element={<StudentHome />} />
          <Route path={"explore/courses"} element={<ExploreCourses />} />
          <Route path={"explore/tools"} element={<ExploreTools />} />
          <Route path={"tools/:id"} element={<ToolsPage />} />
          <Route path={""} element={<StudentLayout />}>
            <Route path="account" element={<AccountSettings />} />
            <Route path="" element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="course" element={<CoursePage />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="projects" element={<Projects />} />
            <Route path="one-to-one" element={<OneToOnePage />} />
            <Route path="workshop" element={<Workshop />} />
            <Route path="community" element={<Community />} />
            <Route path="store" element={<Store />} />
          </Route>
        </Route>
        <Route
          path={"/instructor"}
          element={
            <ProtectedRoute role={UserRole.INSTRUCTOR}>
              <InstructorLayout />
            </ProtectedRoute>
          }
        >
          <Route path="account" element={<AccountSettings />} />
          <Route path={"courses"}>
            <Route path={""} element={<CoursesPage />} />
            <Route path={":courseId"}>
              <Route path={"students"} element={<StudentsOfCourse />} />
              <Route path={"content"} element={<CourseContent />} />
            </Route>
          </Route>
          <Route path={"students"} element={<StudentsPage />} />
          <Route path={"one-to-one"}>
            <Route path={""} element={<OneToOneOfInstructor />} />
            <Route path={"planning"} element={<OneToOnePlanningPage />} />
          </Route>
          <Route path={"workshops"}>
            <Route path={""} element={<WorkshopsPage />} />
            <Route path={":courseId"} element={<CourseWorkshopsPage />} />
          </Route>
          <Route path={"projects"} element={<ProjectsPage />} />
          <Route path={"calendar"} element={<CalendarPage />} />
        </Route>
        <Route path={"/subscription-request"} element={<Subscription />} />
        <Route path="" element={<Redirect />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
