import { Search } from "@mui/icons-material";
import { TextField, TextFieldProps } from "@mui/material";
import React, { FC } from "react";

export const SearchInput: FC<TextFieldProps> = (props) => {
  return (
    <TextField
      {...props}
      size={"small"}
      InputProps={{
        startAdornment: <Search style={{ color: "#707070" }} />,
        ...props.InputProps,
      }}
      placeholder={"Rechercher"}
      style={{
        background: "#FAFAFA",
        ...props.style,
      }}
    />
  );
};
