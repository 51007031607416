import {
  Grid,
  MenuItem,
  TextField as Input,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { forwardRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { getAll } from "../../api/formation.service";
import { DemandeInscription } from "../../api/models";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../hooks/snackbar";
import { baseUrl } from "../../env";

const TextField = forwardRef<unknown, TextFieldProps>(
  ({ label, ...props }, ref) => (
    <div>
      <Typography style={{ color: "darkgrey", fontWeight: "bold" }}>
        {label}
      </Typography>
      <Input variant="standard" ref={ref as any} {...props} />
    </div>
  )
);

type FormData = Pick<
  DemandeInscription,
  "cin" | "tel" | "nom" | "prenom" | "email" | "adresse" | "formationId"
>;

const defaultValues: FormData = {
  cin: "",
  tel: "",
  nom: "",
  prenom: "",
  email: "",
  adresse: "",
  formationId: -1,
};

const addSubscriptionRequest = (data: any) => {
  return axios.post(`${baseUrl}/demande/register`, data);
};

export const Subscription = () => {
  const snackbar = useSnackbar();
  const form = useForm<FormData>({
    defaultValues,
  });
  const coursesQuery = useQuery({
    queryKey: ["all-courses"],
    queryFn: () => getAll(),
  });
  const mutation = useMutation({
    mutationKey: ["submit-subscription-request"],
    mutationFn: (values: any) =>
      addSubscriptionRequest(values)
        .then(() => {
          // form.reset(defaultValues);
          snackbar.show("Subscription added successfully", "success");
        })
        .catch((e) => {
          snackbar.show(e.message, "error");
        }),
  });

  return (
    <Container maxWidth={"sm"}>
      <form
        onSubmit={form.handleSubmit((values) => {
          mutation.mutate(values);
        })}
      >
        <Typography style={{ marginBottom: "2rem" }} variant={"h2"}>
          Subscription
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={"Nom"}
              {...form.register("nom", { required: true })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={"Prénom"}
              {...form.register("prenom")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={"Email"}
              type={"email"}
              {...form.register("email")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label={"N°CIN"} {...form.register("cin")} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={"N° de téléphone"}
              type="phone"
              {...form.register("tel", { required: true })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={"Adresse"}
              fullWidth
              multiline
              type={"adresse"}
              rows={2}
              {...form.register("adresse")}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <Controller
                control={form.control}
                name="formationId"
                render={({ field }) => (
                  <TextField
                    label="Formation"
                    variant="standard"
                    select
                    fullWidth
                    {...field}
                  >
                    <MenuItem disabled value={-1}>
                      Sélectionner la formation
                    </MenuItem>
                    {coursesQuery.data?.map((course) => (
                      <MenuItem key={course.id} value={course.id}>
                        {course.title}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <LoadingButton
                  loading={mutation.isLoading}
                  type="submit"
                  variant={"contained"}
                  color={"primary"}
                >
                  Sauvegarder
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
