import axios from "axios";
import { Club } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/clubs`;

export const getAll = () => {
  return axios.get<Club[]>(`${uri}/`);
};

export const create = (club: Omit<Club, "image">, image?: File) => {
  const formData = new FormData();
  formData.append("nom", club.nom);
  formData.append("domaine", club.domaine);
  image && formData.append("image", image);

  return axios.post(`${uri}/`, formData);
};

export const deleteById = (id: number) => {
  return axios.delete(`${uri}/${id}`);
};
