import { createReducer } from "@reduxjs/toolkit";
import { setCurrentCourse, unsetCurrentCourse } from "./actions";
import { InstructorReducer } from "../redux-dto";

export const instructorReducer = createReducer<InstructorReducer>(
  {
    currentCourse: null,
  },
  (builder) => {
    builder
      .addCase(setCurrentCourse, (state, action) => ({
        currentCourse: action.payload,
      }))
      .addCase(unsetCurrentCourse, () => ({
        currentCourse: null,
      }));
  }
);
