import * as React from "react";
import { SVGProps } from "react";

export const GroupOfUsersIcon = (props: SVGProps<SVGSVGElement>) => {
  const color = props.color || "#023047";
  return (
    <svg
      id="Group_382"
      data-name="Group 382"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22.668"
      height="19.22"
      viewBox="0 0 22.668 19.22"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_215"
            data-name="Rectangle 215"
            width="22.668"
            height="19.22"
            fill={color}
          />
        </clipPath>
      </defs>
      <g id="Group_381" data-name="Group 381" clipPath="url(#clip-path)">
        <path
          id="Path_3751"
          data-name="Path 3751"
          d="M0,190.045a6.743,6.743,0,0,1,.294-.965,3.131,3.131,0,0,1,2.753-1.92c.643-.046,1.293-.008,1.958-.008-.015.062-.032.116-.04.172a10.906,10.906,0,0,0-.211,1.481c-.024,2.13-.013,4.261-.007,6.391,0,.255.05.509.079.783H4.061c-.649,0-1.3-.017-1.946,0a1.989,1.989,0,0,1-2.091-1.7c0-.013-.016-.024-.024-.036v-4.2"
          transform="translate(0 -176.764)"
          fill={color}
        />
        <path
          id="Path_3752"
          data-name="Path 3752"
          d="M114.55,166.929H110.1c-.593,0-.82-.223-.82-.807,0-2.138,0-4.276,0-6.415a3.245,3.245,0,0,1,2.571-3.243,3.088,3.088,0,0,1,.679-.074c1.342-.007,2.684-.014,4.026,0a3.234,3.234,0,0,1,3.182,2.561,3.464,3.464,0,0,1,.078.766q.009,3.229,0,6.459a.663.663,0,0,1-.763.753H114.55"
          transform="translate(-103.225 -147.715)"
          fill={color}
        />
        <path
          id="Path_3753"
          data-name="Path 3753"
          d="M137.362,0a3.932,3.932,0,0,1,0,7.864,3.932,3.932,0,1,1,0-7.864"
          transform="translate(-126.026 0)"
          fill={color}
        />
        <path
          id="Path_3754"
          data-name="Path 3754"
          d="M318.835,196.042c.016-.513.043-1.012.044-1.51.005-1.807,0-3.614,0-5.42a5.355,5.355,0,0,0-.246-1.787.373.373,0,0,1-.01-.08c.019-.014.031-.029.041-.029a18.219,18.219,0,0,1,2.315.078,3.181,3.181,0,0,1,2.635,3.089c.023,1.231.018,2.463,0,3.694a1.905,1.905,0,0,1-1.787,1.954c-1.007.049-2.018.011-3,.011"
          transform="translate(-300.965 -176.839)"
          fill={color}
        />
        <path
          id="Path_3755"
          data-name="Path 3755"
          d="M32.591,69.12a2.939,2.939,0,1,1-2.905-2.945,2.957,2.957,0,0,1,2.905,2.945"
          transform="translate(-25.232 -62.507)"
          fill={color}
        />
        <path
          id="Path_3756"
          data-name="Path 3756"
          d="M281.91,69.1a2.939,2.939,0,1,1-2.947-2.93,2.952,2.952,0,0,1,2.947,2.93"
          transform="translate(-260.73 -62.506)"
          fill={color}
        />
      </g>
    </svg>
  );
};
