import axios from "axios";
import { EtudiantProject } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/student-projects`;

export function updateStudentProject(
  studentProjectId: number,
  isValidated: boolean
) {
  return axios.put<EtudiantProject>(`${uri}/${studentProjectId}`, {
    estValide: isValidated,
  });
}

export function submitProject(project: File, projectId: number) {
  const formData = new FormData();
  formData.append("projectId", projectId.toString());
  formData.append("file", project);
  return axios.post<EtudiantProject>(`${uri}/`, formData);
}

export function deleteProject(id: number) {
  return axios.delete(`${uri}/${id}`);
}
