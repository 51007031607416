import { Typography } from "@mui/material";
import { FC } from "react";

export const Empty: FC<{
  message: string;
}> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img style={{ width: "10%" }} src={"/empty_sets.svg"} alt={"no data"} />
      <Typography variant="h6">{props.message}</Typography>
    </div>
  );
};
