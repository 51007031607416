import React, { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { getImageStyles } from "../utils/utils";

export const AddImage: FC<{
  defaultUrl?: string;
  parentFile?: File;
  inputId: string;
  onChange: (file: File) => void;
}> = (props) => {
  const [file, setFile] = useState<File | null>(props.parentFile || null);
  const [url, setUrl] = useState<string | undefined>(props.defaultUrl);

  useEffect(() => {
    if (file) {
      props.onChange(file);
    }
  }, [file]);

  useEffect(() => {
    if (!props.parentFile) {
      setUrl(props.defaultUrl);
    }
  }, [props.parentFile]);

  return (
    <>
      <input
        type={"file"}
        id={props.inputId}
        multiple={false}
        accept="image/*"
        style={{ visibility: "hidden" }}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            const newFile = e.target.files[0];
            setFile(newFile);
            setUrl(URL.createObjectURL(newFile));
          }
        }}
      />
      <Box
        sx={{
          width: 300,
          height: 150,
          display: "flex",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          color: "#808A96",
          fontWeight: "bold",
          border: `1px dashed #808A96`,
          borderRadius: (theme) => `${theme.shape.borderRadius}px`,
          ...(!!url ? getImageStyles(url) : {}),
        }}
        onClick={() => {
          document.getElementById(props.inputId)?.click();
        }}
      >
        {!url && "+ Ajouter image"}
      </Box>
    </>
  );
};
