import * as React from "react";
import { SVGProps } from "react";

export const CourseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" id="vuesax_bold_video-square" data-name="vuesax/bold/video-square" width={props.width} height={props.height} viewBox="0 0 30.745 30.745">
        <g id="vuesax_bold_video-square-2" data-name="vuesax/bold/video-square">
          <g id="video-square">
            <path id="Vector" d="M18.178,0H7.443C2.78,0,0,2.78,0,7.443V18.165c0,4.676,2.78,7.456,7.443,7.456H18.165c4.663,0,7.443-2.78,7.443-7.443V7.443C25.621,2.78,22.841,0,18.178,0Zm-1.96,15.027-1.64.948-1.64.948C10.825,18.14,9.1,17.14,9.1,14.706V10.914c0-2.447,1.729-3.433,3.843-2.216l1.64.948,1.64.948C18.332,11.811,18.332,13.81,16.218,15.027Z" transform="translate(2.562 2.562)" fill={props.color || "#fff"}/>
            <path id="Vector-2" data-name="Vector" d="M0,0H30.745V30.745H0Z" fill="none" opacity="0"/>
          </g>
        </g>
      </svg>
  );
};
