import { useSnackbar } from "../../hooks/snackbar";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { changePassword } from "../../api/user.service";
import React, { forwardRef, InputHTMLAttributes } from "react";
import * as Yup from "yup";

const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  width: "25vw",
  height: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  borderRadius: "15px",
  border: "1px solid #E8E8E8",
  boxShadow: 24,
  p: 4,
  paddingTop: 2,
};

interface IChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const schema = Yup.object().shape({
  oldPassword: Yup.string().min(5, "Minimum de 5 caractères"),
  newPassword: Yup.string().min(5, "Minimum de 5 caractères"),
  confirmPassword: Yup.string().test(
    "passwords-match",
    "Les mots de passe doit être identiques",
    function (value) {
      return this.parent.newPassword === value;
    }
  ),
});
const Input = forwardRef<
  unknown,
  InputHTMLAttributes<HTMLInputElement> & { label: string; error?: string }
>(({ label, error, ...props }, ref) => {
  return (
    <div className={"form-group"} style={{ marginBottom: ".75rem" }}>
      <label
        style={{
          fontSize: "1rem",
          letterSpacing: 0,
          color: "#121212",
        }}
      >
        {label}
      </label>
      <input
        ref={ref as any}
        type={"text"}
        {...props}
        className={`form-control ${error ? "is-invalid" : ""}`}
      />
      <div className="invalid-feedback">{error}</div>
    </div>
  );
});

export function ChangePasswordDialog(props: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const snackbar = useSnackbar();
  const form = useForm<IChangePassword>({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Modal open={props.isOpen} onClose={props.onClose}>
        <form
          autoComplete={"off"}
          onSubmit={form.handleSubmit((values) => {
            changePassword(values.oldPassword, values.newPassword)
              .then(() => {
                snackbar.show("Mot de passe changé avec succès", "success");
                form.reset();
              })
              .catch((response) => {
                if (response.response.data === "Invalid old password") {
                  form.setError("oldPassword", {
                    message: "Mot de passe invalide",
                  });
                }
                snackbar.show(
                  "Erreur lors du changement du mot de passe",
                  "error"
                );
              });
          })}
        >
          <Box sx={{ ...styleModal }} className={"p-5"}>
            <Grid container className={"d-block justify-content-center"}>
              <Grid item xs={12} className={"text-center mb-5"}>
                <Typography
                  style={{ color: "#023047", fontSize: "1.25rem" }}
                  className={"fw-bold"}
                >
                  Changer Mot De Passe
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={"form-group"}>
                  <Input
                    label={"Mot de passe actuelle"}
                    error={form.formState.errors?.oldPassword?.message}
                    {...form.register("oldPassword")}
                    type={"password"}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={"form-group"}>
                  <Input
                    label={"Nouveau mot de passe"}
                    error={form.formState.errors?.newPassword?.message}
                    {...form.register("newPassword")}
                    autoComplete={"new-password"}
                    type={"password"}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={"form-group"}>
                  <Input
                    label={"Confirmer le nouveau mot de passe"}
                    error={form.formState.errors?.confirmPassword?.message}
                    {...form.register("confirmPassword")}
                    autoComplete={"chrome-off"}
                    type={"password"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} className={"mt-4"}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    className={
                      "d-flex justify-content-center align-items-center"
                    }
                  >
                    <Button
                      style={{
                        backgroundColor: "transparent",
                      }}
                      className={"px-4"}
                      onClick={props.onClose}
                    >
                      <p
                        className={"fw-bold capitalize"}
                        style={{ color: "#121212" }}
                      >
                        Annuler
                      </p>
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={"justify-content-center"}>
                    <Button
                      style={{
                        backgroundColor: "black",
                      }}
                      className={"px-4"}
                      type={"submit"}
                    >
                      <p className={"fw-bold text-white capitalize"}>
                        Confirmer
                      </p>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Modal>
    </>
  );
}
