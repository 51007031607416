import { StudentAppBar } from "../home/StudentAppBar";
import { Content } from "antd/lib/layout/layout";
import { Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { findToolById } from "../../../api/tools.service";
import { primary } from "../../../theme/colors";
import { useQuery } from "@tanstack/react-query";
import { MessagePage } from "../../../component/MessagePage";
import { Info } from "@mui/icons-material";
import { ToolCode } from "../../../api/models";
import { SimplySupportedBeamAtBothEnds } from "./simply-supported-beam-at-both-ends/SimplySupportedBeamAtBothEnds";

export const ToolsPage = () => {
  const { id } = useParams();
  const toolQuery = useQuery({
    queryKey: ["findTool"],
    queryFn: () => findToolById(Number(id)),
    enabled: typeof id !== "undefined" && !isNaN(Number(id)),
  });
  let component;

  const tool = toolQuery.data;
  if (!tool) {
    return (
      <MessagePage
        icon={<Info style={{ fontSize: "10rem" }} />}
        message={"Tool not found"}
      />
    );
  }

  if (tool.code === ToolCode.SIMPLY_SUPPORTED_BEAM_AT_BOTH_ENDS) {
    component = <SimplySupportedBeamAtBothEnds />;
  }

  if (!component) {
    return (
      <MessagePage
        icon={<Info style={{ fontSize: "10rem" }} />}
        message={"Tool not implemented yet"}
      />
    );
  }

  return (
    <div>
      <StudentAppBar />
      <Content>
        <Container maxWidth={"lg"}>
          {!tool && <Typography variant={"h5"}>Outil inconnu</Typography>}
          {tool?.id && (
            <div>
              <Typography
                style={{
                  fontSize: 32,
                  fontFamily: "Biennale",
                  color: primary,
                  margin: "2rem 0",
                }}
              >
                {tool.title}
              </Typography>
              {component}
            </div>
          )}
        </Container>
      </Content>
    </div>
  );
};
