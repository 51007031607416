import * as React from "react";
import { FC, useState } from "react";
import { ConclusionSelection } from "./_types";
import { Chapitre, Formation } from "../../../../../api/models";
import Button from "@mui/material/Button";
import { updateChapter } from "../../../../../api/chapter.service";
import { useSnackbar } from "../../../../../hooks/snackbar";
import { blackAndWhite } from "../../../../../styles";
import { getUrl } from "../../../../../utils/utils";

export const ConclusionContent: FC<{
  selection: ConclusionSelection;
  course: Formation | null;
  onChange: (chapter: Chapitre) => void;
}> = (props) => {
  const [file, setFile] = useState<File | null>(null);
  const snackbar = useSnackbar();

  if (!props.course) {
    return <></>;
  }
  const chapter = props.course.chapitres[props.selection.chapterIndex];

  return (
    <form
      style={{ display: "flex", flexDirection: "column", gap: 16 }}
      onSubmit={(e) => {
        e.preventDefault();
        if (!!file) {
          updateChapter(chapter.id, { title: chapter.title }, file)
            .then((response) => response.data)
            .then((chapter) => {
              props.onChange(chapter);
              snackbar.show("Conclusion chargé avec succès", "success");
            })
            .catch(() => {
              snackbar.show("Erreur du chargement de la conclusion", "error");
            });
        }
      }}
    >
      <div>
        <label>
          Conclusion
          <input
            className={"form-control"}
            type={"file"}
            accept="application/pdf,application/vnd.ms-excel"
            onChange={(e) => {
              setFile(e.target.files?.[0] || null);
            }}
          />
        </label>
      </div>
      <div>
        <Button {...blackAndWhite} type={"submit"}>
          Charger
        </Button>
      </div>
      {chapter.recap_url && (
        <embed
          style={{
            height: "80vh",
            width: "100%",
          }}
          src={getUrl(chapter.recap_url)}
        />
      )}
    </form>
  );
};
