import axios from "axios";
import { Chapitre, Page } from "./models";
import { baseUrl } from "../env";
import { compareByField } from "../utils/utils";

const uri = `${baseUrl}/api/chapitre`;

export function addChapter(title: string, courseId: number) {
  return axios.post<Chapitre>(`${uri}/`, {
    title,
    formationId: courseId,
  });
}

export function updateChapter(
  id: number,
  chapter?: Pick<Chapitre, "title">,
  file?: File
) {
  const formData = new FormData();
  if (chapter?.title) {
    formData.append("title", chapter.title);
  }
  if (!!file) {
    formData.append("conclusionFile", file);
  }
  return axios.put<Chapitre>(`${uri}/update/${id}`, formData);
}

export function deleteChapter(id: number) {
  return axios.delete(`${uri}/delete/${id}`);
}

export function getFirstPage(chapter: Chapitre): Page | null {
  const subchapters = chapter.souschapitres.sort(
    compareByField("order", "asc")
  );
  if (subchapters.length === 0 || subchapters[0].pages.length === 0) {
    return null;
  }
  return subchapters[0].pages[0];
}

export function getFirstConcreteItem(chapter: Chapitre) {}
