import { useQuery } from "@tanstack/react-query";
import { ping } from "../api/server.service";
import { useEffect, useState } from "react";
import { useSnackbar } from "./snackbar";
import { isDevelopmentEnvironment } from "../utils/utils";

const PING_INTERVAL_TIME_IN_SECONDS = 5;
const enablePing = !isDevelopmentEnvironment();

export const usePing = () => {
  const pingQuery = useQuery({
    queryKey: ["ping"],
    queryFn: () => ping(),
    enabled: enablePing,
  });
  const [lastStatus, setLastStatus] = useState<boolean | undefined>(undefined);
  const snackbar = useSnackbar();
  const isAlive = pingQuery.data;

  useEffect(() => {
    if (!isDevelopmentEnvironment()) {
      if (isAlive === false) {
        snackbar.show("Could not connect to the server", "error");
      } else if (lastStatus === false && isAlive === true) {
        snackbar.show("Connected to the server", "info");
      }
      setLastStatus(isAlive);
    }
  }, [isAlive]);

  useEffect(() => {
    let interval: any = undefined;
    if (enablePing) {
      interval = setInterval(() => {
        pingQuery.refetch().then();
      }, PING_INTERVAL_TIME_IN_SECONDS * 1000);
    }
    return () => {
      if (interval !== undefined) {
        clearInterval(interval);
      }
    };
  }, []);
};
