import { FC } from "react";
import { Formation } from "../../../../api/models";
import { CourseProgression } from "./CourseProgression";
import {
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  Typography,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { CourseSelection, IMenuItem } from "../dto";
import Sider from "antd/lib/layout/Sider";
import { canAccess } from "../../../../api/course-content.service";
import { useCurrentCourse } from "../../../../hooks/student/hooks";
import { black } from "../../../../theme/colors";
import { CourseMenuIcon } from "./CourseMenuIcon";

export const CourseMenu: FC<{
  selection?: CourseSelection;
  items: IMenuItem[];
  progression: number;
  course: Formation;
}> = (props) => {
  const course = useCurrentCourse();
  return (
    <Sider
      width={300}
      style={{
        overflow: "auto",
        position: "fixed",
        top: 64,
        bottom: 0,
        background: "white",
        height: "100%",
        borderRight: "1px solid #e6e6e6",
      }}
    >
      <CourseProgression progression={props.progression} />
      <Box p={2}>
        <Stepper
          orientation={"vertical"}
          connector={
            <StepConnector
              sx={{
                [`& .${stepConnectorClasses.line}`]: {
                  transform: "translate(-1px)",
                  borderLeft: "2px solid #023047",
                },
              }}
            />
          }
        >
          {props.items.map((item) => (
            <Step
              key={[item.type, item.id].join("-")}
              onClick={() => {
                item.onClick();
              }}
            >
              <StepLabel
                sx={{
                  py: 0,
                  pr: 1,
                }}
                StepIconComponent={() => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: 24,
                    }}
                  >
                    <CourseMenuIcon itemType={item.type} />
                  </Box>
                )}
              >
                <Typography
                  sx={{
                    fontSize: item.type === "chapter" ? 16 : 14,
                    fontWeight: "bold",
                    color: !canAccess({
                      currentSelection: item,
                      course: { ...course, ...props.course },
                    })
                      ? "#707070"
                      : black,
                    pl: item.type !== "chapter" ? 2 : 0,
                    pr: 2,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  {item.title}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Sider>
  );
};
