import React, { FC } from "react";
import {
  EtudiantProject,
  EtudiantProjectStatut,
  Project,
} from "../../../api/models";
import { Box } from "@mui/material";

export const ProjectStatus: FC<{
  studentProject?: EtudiantProject;
  project: Project;
}> = (props) => {
  let color: string | undefined;
  let backgroundColor: string | undefined;
  let status: string | undefined;

  if (!props.studentProject) {
    status = "Non déposé";
    backgroundColor = "#FBD5D5";
    color = "#771D1D";
  } else if (
    props.studentProject.statut === EtudiantProjectStatut.DEADLINE_MISSED
  ) {
    status = "Manqué";
    backgroundColor = "#FBD5D5";
    color = "#771D1D";
  } else if (props.studentProject.statut === EtudiantProjectStatut.REFUSED) {
    status = "Refusé";
    backgroundColor = "#FBD5D5";
    color = "#771D1D";
  } else if (props.studentProject.statut === EtudiantProjectStatut.ACCEPTED) {
    status = "Accepté";
    backgroundColor = "#BCF0DA";
    color = "#014737";
  } else if (props.studentProject.statut === EtudiantProjectStatut.SUBMITTED) {
    status = "Soumis";
    backgroundColor = "#FCE96A";
    color = "#AA873B";
  }
  return (
    <Box
      sx={{
        backgroundColor,
        color,
        fontSize: 18,
        fontWeight: "bold",
        borderRadius: "12px",
      }}
      px={2}
      py={1}
      children={status}
    />
  );
};
