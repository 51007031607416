import { Outlet } from "react-router-dom";
import { CourseIcon2 } from "../../icons/CourseIcon2";
import { GroupOfUsersIcon } from "../../icons/GroupOfUsersIcon";
import { OneToOneIcon } from "../../icons/OneToOneIcon";
import { SettingsIcon } from "../../icons/SettingsIcon";
import { ProjectsIcon } from "../../icons/ProjectsIcon";
import { CalendarIcon } from "../../icons/CalendarIcon";
import { InstructorCurrentCourse } from "../../component/InstructorCurrentCourse";
import { UserLayout } from "../../component/UserLayout";
import {
  calendar,
  courseList,
  oneToOne,
  projects,
  students,
  workshops,
} from "../../routes/instructor.routes";

const menuItems = [
  {
    text: "Formations",
    icon: <CourseIcon2 />,
    link: courseList,
  },
  {
    text: "Étudiants",
    icon: <GroupOfUsersIcon />,
    link: students,
  },
  {
    text: "Calendrier",
    icon: <CalendarIcon />,
    link: calendar,
  },
  {
    text: "Series",
    icon: <ProjectsIcon />,
    link: projects,
  },
  {
    text: "One To One",
    icon: <OneToOneIcon width={27} height={27} />,
    link: oneToOne,
  },
  {
    text: "Devoirs",
    icon: <SettingsIcon />,
    link: workshops,
  },
];

export function InstructorLayout() {
  return (
    <UserLayout
      logoLink={"/instructor/courses"}
      toolbar={<InstructorCurrentCourse />}
      menuItems={menuItems}
      content={<Outlet />}
    />
  );
}
