import React, { FC, useEffect, useRef, useState } from "react";

// import  "@zach.codes/react-calendar/dist/calendar-tailwind.css";
import "./calendar-tailwind.css";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  SvgIconProps,
  Typography,
} from "@mui/material";
import "antd/dist/antd.css";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";

import PlaningIconSVG from "../../../images/planing.svg";

import "@fullcalendar/daygrid/main.css";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOneToOneByEtudiant,
  getWorkshopByEtudiantFormation,
} from "../../../api";
import { useLocation } from "react-router-dom";
import { getOneToOnes } from "../../../api/formation.service";
import { DisponibiliteOneToOne } from "../../../api/models";
import moment from "moment";
import { book } from "../../../api/one-to-one.service";
import { OneToOneBalance } from "../../../component/StudentOneToOneBalance";
import { capitalize, getError } from "../../../utils/utils";
import { RootState } from "../../../store";
import { useQuery } from "@tanstack/react-query";
import { updateCourseAction } from "../../../reducers/student/actions";
import { useSnackbar } from "../../../hooks/snackbar";

const { Content, Sider } = Layout;

const SvgCancelIcon = styled(CancelIcon, {
  name: "MopeimIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(() => ({
  stroke: "none",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  color: "#707070",
}));

function SuccessBooking(props: {
  oneToOne?: DisponibiliteOneToOne;
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      style={{
        borderRadius: 52,
      }}
    >
      <DialogTitle>
        <Grid container justifyContent={"flex-end"}>
          <IconButton onClick={props.onClose}>
            <SvgCancelIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Box p={2}>
        <Grid container flexDirection={"column"} alignItems={"center"}>
          <img
            src={PlaningIconSVG}
            style={{
              width: "65%",
            }}
            className={"mb-3"}
            alt={"planning icon"}
          />
          <div>
            <p
              style={{
                fontSize: "1rem",
                color: "#707070",
              }}
            >
              Votre prochain One To One est planifié pour
            </p>
          </div>
          {!!props.oneToOne && (
            <Grid container spacing={5}>
              <Grid item xs={12} className={"text-center"}>
                <p className={"mr-2"}>
                  <b>{moment(props.oneToOne.date_debut).format("dddd")}</b>
                  &nbsp;
                  {moment(props.oneToOne.date_debut).format("DD MMMM YYYY")}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    style={{
                      fontSize: "bold",
                      color: "#048B9A",
                    }}
                  >
                    <b>
                      {[
                        moment(props.oneToOne.heure_debut, "HH:mm").format(
                          "HH:mm"
                        ),
                        moment(props.oneToOne.heure_fin, "HH:mm").format(
                          "HH:mm"
                        ),
                      ].join(" - ")}
                    </b>
                  </span>
                </p>
              </Grid>
            </Grid>
          )}
          <Box mt={3}>
            <OneToOneBalance />
          </Box>
        </Grid>
      </Box>
    </Dialog>
  );
}

const AskForOneToOne: FC<{
  onCancel: () => void;
  onBook: () => void;
}> = (props) => {
  const currentCourseId = useSelector((state: RootState) => {
    return state.student.currentCourseId;
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const oneToOnesQuery = useQuery({
    queryKey: ["getAvailableOneToOnes", currentCourseId],
    queryFn: () =>
      getOneToOnes(currentCourseId as number).then((response) => response.data),
    enabled: !!currentCourseId,
  });
  const [selectedOneToOneId, selectOneToOneId] = useState<number | null>();
  const [selectedOneToOneTime, selectOneToOneTime] = useState<string>("");
  const [lastOneToOne, setLastOneToOne] = useState<
    DisponibiliteOneToOne | undefined
  >(undefined);
  const snackbar = useSnackbar();

  const oneToOnes = oneToOnesQuery.data || [];
  const selectedOneToOne = oneToOnes.find(
    (oneToOne) => oneToOne.id === selectedOneToOneId
  );

  if (!oneToOnesQuery.data) {
    return <></>;
  }

  const days = Array.from(
    new Set(
      oneToOnes.map((oneToOne) =>
        moment(oneToOne.date_debut).format("DD MM YYYY")
      )
    )
  );

  const handleBook = () => {
    if (!selectedOneToOne || !currentCourseId) {
      return;
    }

    book(
      moment(selectedOneToOneTime, "DD MMMM YYYY HH:mm").toISOString(),
      currentCourseId
    )
      .then(() => {
        setLastOneToOne(selectedOneToOne);
        oneToOnesQuery.refetch();
        setIsModalOpen(true);
        selectOneToOneId(null);
        selectOneToOneTime("");
        props.onBook();
      })
      .catch((e) => {
        snackbar.show(
          getError(e, "Erreur lors de la réservation du créneau"),
          "error"
        );
      });
  };

  function handleSelectOneToOne(oneToOne: DisponibiliteOneToOne, day: string) {
    const isAlreadySelected = oneToOne.id === selectedOneToOneId;
    const date_debut = moment(
      day + " " + oneToOne.heure_debut,
      "DD MM YYYY HH:mm"
    ).format("DD MMMM YYYY HH:mm");

    selectOneToOneTime(date_debut);
    if (isAlreadySelected) {
      selectOneToOneId(null);
      selectOneToOneTime("");
    } else {
      selectOneToOneId(oneToOne.id);
      selectOneToOneTime(date_debut);
    }
  }

  return (
    <Grid
      className={"container m-0"}
      style={{
        width: "100%",
        backgroundColor: "#FFF6DF",
        height: "100%",
      }}
    >
      <Grid container className={"d-flex justify-content-end pt-2"}>
        <IconButton
          onClick={() => {
            props.onCancel();
          }}
        >
          <SvgCancelIcon />
        </IconButton>
      </Grid>
      <div className={"p-3 pt-2"}>
        <OneToOneBalance />
      </div>
      <div className={"p-2 text-center"}>
        <p
          style={{
            fontSize: ".75rem",
            color: "#121212",
            fontWeight: "bold",
            marginBottom: 5,
          }}
        >
          Selectionner un créneau horaire
        </p>
        <p
          style={{
            fontSize: ".65rem",
            color: "#707070",
          }}
        >
          <InfoIcon
            style={{
              fontSize: "small",
            }}
            className={"mr-2"}
          />{" "}
          Un créneau au maximum
        </p>
      </div>
      {days.map((day) => (
        <Grid className={"my-3"} key={day}>
          <Grid item xs={12}>
            <Typography>
              <b>{capitalize(moment(day, "DD MM YYYY").format("dddd"))}</b>
              &nbsp;{moment(day, "DD MM YYYY").format("DD MMMM YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              className={"mt-2"}
              style={{
                fontSize: "0.75rem",
              }}
            >
              {oneToOnes
                .filter((oneToOne) => {
                  return moment(oneToOne.date_debut).isSame(
                    moment(day, "DD MM YYYY"),
                    "days"
                  );
                })
                .map((oneToOne) => (
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    key={oneToOne.id}
                    style={{
                      fontSize: "0.75rem",
                    }}
                  >
                    <Button
                      variant={"contained"}
                      fullWidth
                      className={`btn p-2 ${
                        selectedOneToOneId === oneToOne.id && "selected"
                      }`}
                      disableElevation={true}
                      style={{
                        backgroundColor: "#121212",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.75rem",
                      }}
                      onClick={() => {
                        handleSelectOneToOne(oneToOne, day);
                      }}
                      sx={{
                        "&.Mui-disabled": {
                          backgroundColor: "#E8E8E8 !important",
                          color: "white!important",
                        },
                        "&.selected": {
                          background: "#048B9A!important",
                        },
                      }}
                      disabled={oneToOne.estReserve}
                    >
                      {moment(oneToOne.heure_debut, "HH:mm").format("HH:mm")} -{" "}
                      {moment(oneToOne.heure_fin, "HH:mm").format("HH:mm")}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid
        container
        className={"d-flex align-items-center justify-content-center mt-5"}
      >
        <Button
          variant={"contained"}
          style={{
            textTransform: "capitalize",
            backgroundColor: "#121212",
            color: "white",
            width: "70%",
          }}
          disabled={!selectedOneToOne}
          onClick={() => {
            handleBook();
          }}
        >
          Confirmer
        </Button>
      </Grid>
      <SuccessBooking
        oneToOne={lastOneToOne}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </Grid>
  );
};

export function Calendar() {
  const currentCourseId = useSelector((state: RootState) => {
    return state.student.currentCourseId;
  });
  const courses = useSelector((root: RootState) => root.student.courses);
  const currentCourse = courses.find((course) => course.id === currentCourseId);
  const dispatch = useDispatch();
  const [events, setEvents] = useState<any>([]);
  const auth = useSelector((state) => (state as any).auth);
  const [oneToOneData, setOneToOneData] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const location = useLocation();
  const loadOnce = useRef(true);

  function load() {
    let calendarApi = calendarRef.current.getApi();

    calendarApi.removeAllEvents();

    getAllOneToOneByEtudiant({
      userId: auth.user.id,
      idFormation: currentCourseId,
    }).then((data: any) => {
      setOneToOneData(data.data);
      data.data.forEach((element: any) => {
        calendarApi.addEvent({
          title: "One To One",
          start: element.dateDebut,
          end: element.dateDebut,
          allDay: true,
          backgroundColor: "#048B9A",
          borderColor: "#048B9A",
        });
      });
    });

    getWorkshopByEtudiantFormation({
      etudiantId: auth?.user.id,
      formationId: currentCourseId,
    }).then((data: any) => {
      setWorkshops(data.data);
      data.data.forEach((element: any) => {
        calendarApi.addEvent({
          title: element.title,
          start: element.date,
          end: element.date,
          allDay: true,
          backgroundColor: "#FFB703",
          borderColor: "#FFB703",
        });
      });
    });
  }

  useEffect(() => {
    if (loadOnce.current) {
      load();
      loadOnce.current = !loadOnce.current;
    }
    loadOnce.current = !loadOnce.current;
  }, [location.pathname, currentCourseId]);

  const [collapsed, setCollapsed] = useState(true);

  const calendarRef = useRef<any>(React.createRef());
  return (
    <Layout>
      <Content
        style={{
          backgroundColor: "white",
          overflowY: "hidden",
          marginBottom: 0,
          marginLeft: 0,
          padding: 0,
        }}
      >
        <Grid
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "96vh",
            overflowY: "scroll",
            marginBottom: 0,
            marginLeft: 0,
            padding: 0,
            paddingTop: "1rem",
          }}
        >
          <Grid
            item
            xs={12}
            className={"d-flex container justify-content-center m-auto my-5"}
          >
            <Grid container spacing={2}>
              <Grid item xs={9} className={"d-flex align-items-end"}>
                <div className={"d-flex align-items-center mr-3"}>
                  <div
                    className={"mr-1"}
                    style={{
                      width: "9px",
                      height: "9px",
                      backgroundColor: "#023047",
                      borderRadius: 50,
                    }}
                  ></div>
                  <p
                    className={"mb-0"}
                    style={{
                      fontSize: ".75rem",
                      color: "#121212",
                    }}
                  >
                    Projet
                  </p>
                </div>

                <div className={"d-flex align-items-center mr-3"}>
                  <div
                    className={"mr-1"}
                    style={{
                      width: "9px",
                      height: "9px",
                      backgroundColor: "#048B9A",
                      borderRadius: 50,
                    }}
                  ></div>
                  <p
                    className={"mb-0"}
                    style={{
                      fontSize: ".75rem",
                      color: "#121212",
                    }}
                  >
                    One To One
                  </p>
                </div>

                <div className={"d-flex align-items-center"}>
                  <div
                    className={"mr-1"}
                    style={{
                      width: "9px",
                      height: "9px",
                      backgroundColor: "#FFB703",
                      borderRadius: 50,
                    }}
                  ></div>
                  <p
                    className={"mb-0"}
                    style={{
                      fontSize: ".75rem",
                      color: "#121212",
                    }}
                  >
                    Workshop
                  </p>
                </div>
              </Grid>
              <Grid item xs={3} className={"d-flex justify-content-end"}>
                <Button
                  onClick={() => setCollapsed(!collapsed)}
                  variant={"contained"}
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#023047",
                    color: "white",
                  }}
                >
                  Demander un one to one
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={"pt-0 mt-1"}>
            <div className={"container"}>
              <FullCalendar
                schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                ref={calendarRef}
                initialView="dayGridMonth"
                displayEventTime={true}
                weekends={true}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                selectable={true}
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  timeGridPlugin,
                  resourceTimeGridPlugin,
                ]}
                events={events}
              />
            </div>
          </Grid>
        </Grid>
      </Content>
      <Sider
        collapsedWidth="0"
        width={"20vw"}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ height: "96vh" }}
      >
        <AskForOneToOne
          onBook={() => {
            load();
            if (currentCourse && !!currentCourse.formation_etudiant) {
              dispatch(
                updateCourseAction({
                  ...currentCourse,
                  formation_etudiant: {
                    ...currentCourse.formation_etudiant,
                    soldeOneToOne:
                      currentCourse.formation_etudiant.soldeOneToOne - 15,
                  },
                })
              );
            }
          }}
          onCancel={() => {
            setCollapsed(!collapsed);
          }}
        />
      </Sider>
    </Layout>
  );
}
