import { Chapitre, Formation, Project, SousChapitre, Workshop } from "./models";
import { CourseSelection } from "../pages/student/course/dto";
import { compareByField } from "../utils/utils";
import { hasQuiz } from "./subchapter.service";
import {
  createFromChapter,
  createFromConclusion,
  createFromPage,
  createFromQuiz,
  createFromSubchapter,
} from "./course-selection";

export const nonConcreteItems: Array<CourseSelection["type"]> = [
  "chapter",
  "subchapter",
];

export function isConcrete(selection: CourseSelection) {
  return !nonConcreteItems.includes(selection.type);
}

export function createFromWorkshop(workshop: Workshop): CourseSelection {
  return {
    type: "workshop",
    id: workshop.id,
    title: workshop.title,
    value: workshop,
  };
}

export function createFromProject(project: Project): CourseSelection {
  return {
    id: project.id,
    value: project,
    type: "project",
    title: "Project",
  };
}

export function getConcreteSelectionsFromSubchapter(subchapter: SousChapitre) {
  const items: CourseSelection[] = [];
  subchapter.pages.forEach((page, i) => {
    items.push(createFromPage(page, i));
  });
  if (hasQuiz(subchapter)) {
    items.push(createFromQuiz(subchapter.qcm));
  }

  return items;
}

export function getConcreteSelectionsFromChapter(chapter: Chapitre) {
  const items: CourseSelection[] = [];

  chapter.souschapitres
    .sort(compareByField("order", "asc"))
    .forEach((subchapter) => {
      subchapter.pages.forEach((page, i) => {
        items.push(createFromPage(page, i));
      });
      if (hasQuiz(subchapter)) {
        items.push(createFromQuiz(subchapter.qcm));
      }
    });
  if (chapter.recap_url) {
    items.push(createFromConclusion(chapter));
  }
  if (chapter.project) {
    items.push(createFromProject(chapter.project));
  }
  if (chapter.Workshop) {
    items.push(createFromWorkshop(chapter.Workshop));
  }

  return items;
}

export function getSelectionsFromChapter(chapter: Chapitre) {
  const items: CourseSelection[] = [];
  items.push(createFromChapter(chapter));

  chapter.souschapitres
    .sort(compareByField("order", "asc"))
    .forEach((subchapter) => {
      items.push(createFromSubchapter(subchapter));
    });
  if (chapter.recap_url) {
    items.push(createFromConclusion(chapter));
  }
  if (chapter.project) {
    items.push(createFromProject(chapter.project));
  }
  if (chapter.Workshop) {
    items.push(createFromWorkshop(chapter.Workshop));
  }

  return items;
}

export function getSelections(content: Formation): CourseSelection[] {
  return content.chapitres
    .sort(compareByField("order", "asc"))
    .map(getSelectionsFromChapter)
    .flat();
}

export function getConcreteSelections(content: Formation): CourseSelection[] {
  const items: CourseSelection[] = [];

  content.chapitres.sort(compareByField("order", "asc")).forEach((chapter) => {
    chapter.souschapitres.forEach((subchapter) => {
      subchapter.pages.forEach((page, i) => {
        items.push(createFromPage(page, i));
      });
      if (hasQuiz(subchapter)) {
        items.push(createFromQuiz(subchapter.qcm));
      }
    });
    if (chapter.recap_url) {
      items.push(createFromConclusion(chapter));
    }
    if (chapter.project) {
      items.push(createFromProject(chapter.project));
    }
    if (chapter.Workshop) {
      items.push(createFromWorkshop(chapter.Workshop));
    }
  });

  return items;
}

export function getNextSelection(
  content: Formation,
  selection?: CourseSelection
): CourseSelection | undefined {
  const concreteSelections = getConcreteSelections(content);
  let _selection =
    selection === undefined
      ? {
          cursorType: content.formation_etudiant.cursorType,
          cursorId: content.formation_etudiant.cursorId,
        }
      : {
          cursorId: selection.id,
          cursorType: selection.type,
        };
  const selectionIndex = concreteSelections.findIndex(
    (courseSelection) =>
      courseSelection.type === _selection.cursorType &&
      courseSelection.id === _selection.cursorId
  );
  if (selectionIndex === -1) return undefined;
  return concreteSelections[selectionIndex + 1] || undefined;
}

function getFirstConcreteChildFromSelection(
  selection: CourseSelection
): CourseSelection | undefined {
  if (!nonConcreteItems.includes(selection.type)) {
    return selection;
  } else if (selection.type === "chapter") {
    return getConcreteSelectionsFromChapter(selection.value)[0];
  } else if (selection.type === "subchapter") {
    return getConcreteSelectionsFromSubchapter(selection.value)[0];
  }
}

export function getIndexes(args: {
  currentSelection: CourseSelection;
  course: Formation;
}) {
  const items = getConcreteSelections(args.course).filter(
    (item) => item.type !== "chapter"
  );
  const selection = getFirstConcreteChildFromSelection(args.currentSelection);
  const { cursorId, cursorType } = args.course.formation_etudiant;
  const indexOfCursor = items.findIndex(
    (item) => item.type === cursorType && item.id === cursorId
  );
  const indexOfSelection = items.findIndex(
    (item) => item.type === selection?.type && item.id === selection?.id
  );

  return {
    indexOfCursor,
    indexOfSelection,
  };
}

export function canAccess(args: {
  currentSelection?: CourseSelection;
  course: Formation;
}) {
  if (args.currentSelection === undefined) {
    return false;
  }
  const { currentSelection, course } = args;
  const { indexOfCursor, indexOfSelection } = getIndexes({
    currentSelection: currentSelection!!,
    course,
  });
  return indexOfCursor >= indexOfSelection;
}
