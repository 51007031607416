import axios from "axios";
import { Formation, Question } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/api/question`;

export function addQuestion(qcmId: number, question: string, version: number) {
  return axios.post<Question>(`${uri}/create`, {
    QcmId: qcmId,
    question,
    version,
  });
}

export function deleteQuestion(id: number) {
  return axios.delete(`${uri}/delete/${id}`);
}

export function updateQuestion(id: number, question: Partial<Question>) {
  return axios.put<Partial<Question>>(`${uri}/update/${id}`, question);
}

export function deleteByVersion(qcmId: number, version: number) {
  return axios.delete<Question[]>(`${uri}/deleteByVersion`, {
    data: {
      qcmId,
      version,
    },
  });
}

export function getCourseIndexes(
  course: Formation,
  questionId: number
):
  | {
      chapterIndex: number;
      subchapterIndex: number;
    }
  | undefined {
  let value: ReturnType<typeof getCourseIndexes> = undefined;

  course.chapitres.forEach((chapter, chapterIndex) => {
    chapter.souschapitres.forEach((subchapter, subchapterIndex) => {
      const questions = subchapter.qcm?.questions || [];
      const index = questions.findIndex(
        (question) => question.id === questionId
      );
      if (index !== -1) {
        value = {
          chapterIndex,
          subchapterIndex,
        };
      }
    });
  });

  return value;
}
