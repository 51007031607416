import { useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export function ProtectedRoute(props: {
  role: string;
  children: ReactNode;
}): JSX.Element | null {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const authUser = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();

  if (isLoggedIn && authUser?.role === props.role) {
    return <>{props.children}</>;
  } else {
    navigate("/login");
    return <></>;
  }
}
