import { Error } from "@mui/icons-material";
import { MessagePage } from "../../component/MessagePage";

export const NotFoundPage = () => {
  return (
    <MessagePage
      icon={<Error style={{ fontSize: "10rem" }} />}
      message={"Page not found"}
    />
  );
};
