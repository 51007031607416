import axios from "axios";
import { getAuthHeaders } from "./formation.service";
import { DemandeInscription } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/demande`;

export function validationDemande(data: any) {
  return axios
    .post(`${uri}/validation_demande/`, data, {
      headers: getAuthHeaders(),
    })
    .then((data) => {
      return data.data;
    });
}

export function getAll() {
  return axios.get<DemandeInscription[]>(`${uri}/all`);
}
