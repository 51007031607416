import { createAction, createReducer } from "@reduxjs/toolkit";

export interface Layout {
  isCollapsed: boolean;
}

export const setSiderCollapse =
  createAction<Pick<Layout, "isCollapsed">>("SET_SIDER_COLLAPSE");

export const layoutReducer = createReducer<Layout>(
  {
    isCollapsed: false,
  },
  (builder) => {
    builder.addCase(setSiderCollapse, (state, action) => {
      return {
        isCollapsed: action.payload.isCollapsed,
      };
    });
  }
);
