import React, { FC, MutableRefObject, useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { useSnackbar } from "../../../hooks/snackbar";
import { EtudiantProject } from "../../../api/models";
import { useMutation } from "@tanstack/react-query";
import { submitProject } from "../../../api/student-project.service";
import { LoadingButton } from "@mui/lab";
import { getUrl } from "../../../utils/utils";
import { buttonStyle, ProjectDelete } from "./ProjectDelete";

export const ProjectFile: FC<{
  projectId: number;
  studentProject?: EtudiantProject;
  onUpdate: () => void;
}> = (props) => {
  const ref = useRef<HTMLInputElement>();
  const snackbar = useSnackbar();
  const [file, setFile] = useState<File | undefined>();
  const hasProject = !!props.studentProject;
  const mutation = useMutation({
    mutationKey: [
      "submitProject",
      props.projectId,
      props.studentProject?.id || "",
    ],
    mutationFn: () =>
      submitProject(file as File, props.projectId)
        .then(() => {
          snackbar.show("Projet soumis avec succès", "success");
          props.onUpdate();
        })
        .catch((error) => {
          const message =
            error.response?.data?.message || "Erreur lors de la soumission";
          snackbar.show(message, "error");
        }),
  });
  const hasFile = !!file;

  return (
    <Grid>
      <Grid item xs={12} className={"mt-3"}>
        <Typography
          sx={{
            color: "#023047",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Pièces Jointes
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <input
              ref={ref as MutableRefObject<HTMLInputElement>}
              type="file"
              hidden={true}
              onChange={(e) => {
                const files = e.target.files;
                if (!!files && files[0]) {
                  setFile(files[0]);
                }
              }}
            />
            <Button
              disabled={mutation.isLoading}
              style={{
                textTransform: "none",
                fontWeight: "bold",
                border: "1px solid #707070",
                borderRadius: 6,
                marginRight: 9,
                color: "black",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: hasProject ? "#CDE8F4" : undefined,
              }}
              fullWidth
              onClick={() => {
                if (!hasProject) {
                  ref?.current?.click();
                } else {
                  window.open(getUrl(props.studentProject?.fichierUrl));
                }
              }}
            >
              {hasProject ? (
                <CheckCircle
                  sx={{
                    color: "white",
                  }}
                />
              ) : (
                <span></span>
              )}
              {hasFile
                ? file.name
                : hasProject
                ? props.studentProject?.fichierUrl?.split("/")[1]
                : mutation.isLoading
                ? "Chargement du projet"
                : "Sélectionner le fichier"}

              <span>&nbsp;</span>
            </Button>
          </Grid>
          <Grid item xs={2}>
            {!!props.studentProject && (
              <ProjectDelete
                studentCourseId={props.studentProject.id}
                onDelete={() => {
                  props.onUpdate();
                }}
              />
            )}
            {!hasProject && (
              <LoadingButton
                loading={mutation.isLoading}
                sx={{
                  ...buttonStyle,
                }}
                onClick={() => {
                  if (!file) {
                    snackbar.show("Aucun fichier sélectionné", "error");
                  } else {
                    mutation.mutate();
                  }
                }}
              >
                Confirmer
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={"mt-4"}>
        <hr
          style={{
            color: "gray",
          }}
        />
        <Box
          sx={{
            ml: 4,
            fontWeight: "bold",
            mt: 3,
          }}
        >
          <ul
            style={{
              listStyle: "initial",
              fontWeight: "bold",
            }}
          >
            <li>Un seul fichier pour le dépôt</li>
            <li>
              Sélectionnez le fichier de type: PDF, Images, Zip, RAR, DWG.
            </li>
            <li>Fichier de taille maximale: 15MB</li>
          </ul>
        </Box>
      </Grid>
    </Grid>
  );
};
