import * as React from "react";
import { SVGProps } from "react";

export const BadgeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="vuesax/outline/award"
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    {...props}
  >
    <g fill={props.color || "#157afe"} data-name="vuesax/outline/award">
      <path d="M20 37.917a5.737 5.737 0 0 1-2.783-.683l-7.884-4.555c-3.266-2.2-3.483-2.6-3.483-6.046v-8.266c0-3.45.217-3.85 3.417-6l7.967-4.6a5.944 5.944 0 0 1 5.55 0l7.883 4.55c3.267 2.2 3.483 2.6 3.483 6.05v8.267c0 3.45-.217 3.85-3.417 6l-7.967 4.6a5.5 5.5 0 0 1-2.766.683Zm0-28.333a3.158 3.158 0 0 0-1.533.35l-7.884 4.545C8.35 16 8.35 16 8.35 18.367v8.267c0 2.367 0 2.367 2.317 3.933l7.817 4.5a3.5 3.5 0 0 0 3.05 0l7.883-4.55C31.65 29 31.65 29 31.65 26.633v-8.266c0-2.367 0-2.367-2.317-3.933l-7.817-4.5A3.046 3.046 0 0 0 20 9.579Z" />
      <path
        data-name="Vector"
        d="M29.166 13.966a1.259 1.259 0 0 1-1.25-1.25V8.333c0-2.633-1.117-3.75-3.75-3.75h-8.333c-2.633 0-3.75 1.117-3.75 3.75V12.6a1.25 1.25 0 1 1-2.5 0V8.333c0-4.033 2.217-6.25 6.25-6.25h8.333c4.033 0 6.25 2.217 6.25 6.25v4.383a1.259 1.259 0 0 1-1.25 1.25ZM22.717 29.383a2.853 2.853 0 0 1-1.083-.217l-1.633-.633-1.633.65a2.53 2.53 0 0 1-2.45-.217 2.557 2.557 0 0 1-.967-2.267l.1-1.75-1.118-1.349a2.539 2.539 0 0 1-.533-2.417 2.515 2.515 0 0 1 1.85-1.617l1.7-.433.95-1.483a2.452 2.452 0 0 1 4.217 0l.95 1.483 1.7.433a2.515 2.515 0 0 1 1.85 1.617 2.56 2.56 0 0 1-.55 2.4l-1.117 1.35.1 1.75a2.575 2.575 0 0 1-.966 2.267 2.338 2.338 0 0 1-1.367.433ZM15.867 22l1.117 1.35a2.66 2.66 0 0 1 .567 1.75l-.1 1.75 1.633-.65a2.667 2.667 0 0 1 1.833 0l1.633.65-.1-1.75a2.69 2.69 0 0 1 .567-1.75L24.134 22l-1.7-.433A2.749 2.749 0 0 1 20.95 20.5l-.933-1.467-.95 1.467a2.7 2.7 0 0 1-1.483 1.083Z"
      />
    </g>
  </svg>
);
