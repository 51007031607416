import { Chapitre, Page, Qcm, SousChapitre } from "./models";
import { CourseSelection } from "../pages/student/course/dto";

export function createFromChapter(chapter: Chapitre): CourseSelection {
  return {
    id: chapter.id,
    value: chapter,
    type: "chapter",
    title: chapter.title,
  };
}

export function createFromSubchapter(
  subchapter: SousChapitre
): CourseSelection {
  return {
    type: "subchapter",
    id: subchapter.id,
    title: subchapter.title,
    value: subchapter,
  };
}

export function createFromPage(page: Page, index: number): CourseSelection {
  return {
    id: page.id,
    value: page,
    type: "page",
    title: `Page ${index + 1}`,
  };
}

export function createFromQuiz(quiz: Qcm): CourseSelection {
  return {
    id: quiz.id,
    value: quiz,
    type: "quiz",
    title: `Quiz`,
  };
}

export function createFromConclusion(chapter: Chapitre): CourseSelection {
  return {
    id: chapter.id,
    value: chapter,
    type: "conclusion",
    title: "Conclusion",
  };
}
