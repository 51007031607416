import React, { FC } from "react";
import { useSnackbar } from "../../../../hooks/snackbar";
import { useMutation } from "@tanstack/react-query";
import { deleteCourse } from "../../../../api/course.service";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { bigButton, blackAndWhite } from "../../../../styles";
import { LoadingButton } from "@mui/lab";

export const CourseDelete: FC<{
  isOpen: boolean;
  courseId: number;
  courseTitle: string;
  onOpen: () => void;
  onClose: () => void;
  onDelete: () => void;
}> = (props) => {
  const snackbar = useSnackbar();
  const mutation = useMutation({
    mutationKey: ["deleteCourse", props.courseId],
    mutationFn: () =>
      deleteCourse(props.courseId)
        .then(() => {
          props.onClose();
          props.onDelete();
        })
        .catch((response) => {
          const defaultError = "Erreur lors de la suppression";
          const error = response.data.message || defaultError;
          snackbar.show(error, "error");
        }),
  });

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      maxWidth={"xs"}
      fullWidth={true}
    >
      <DialogTitle>Suppression de la formation</DialogTitle>
      <DialogContent>
        <Typography>
          Voulez-vous vraiment supprimer la formation <b>{props.courseTitle}</b>
          ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          {...bigButton}
          onClick={() => {
            props.onClose();
          }}
        >
          Annuler
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          {...blackAndWhite}
          children={"Confirmer"}
          onClick={() => {
            mutation.mutate();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
