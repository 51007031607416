import axios from "axios";
import { Reponse } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/api/Reponse`;

export function addAnswer(
  questionId: number,
  answer: string,
  isCorrect: boolean
) {
  return axios.post<Reponse>(`${uri}/create`, {
    QuestionId: questionId,
    reponse: answer,
    estElleValide: isCorrect,
  });
}

export function deleteAnswer(answerId: number) {
  return axios.delete(`${uri}/delete/${answerId}`);
}

export function updateAnswer(answerId: number, answer: Partial<Reponse>) {
  return axios.put<Partial<Reponse>>(`${uri}/update/${answerId}`, answer);
}
