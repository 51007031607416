import axios from "axios";
import { Project } from "./models";
import { getAuthHeaders } from "./formation.service";
import { baseUrl } from "../env";

const uri = `${baseUrl}/api/project`;
const uriInfo = `${baseUrl}/student-projects`;

export const updateProject = (projectId: number, project: Partial<Project>) => {
  return axios.put(`${uri}/update/${projectId}`, project);
};

export function getProjectStudentCount(idForm: number) {
  return axios
    .get(`${uriInfo}/project_student_count/${idForm}`, {
      headers: getAuthHeaders(),
    })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return { total: 0 };
      //data.data;
    });
}

export function getInfoProjectStudent(idProject: number) {
  return axios
    .get(`${uriInfo}/getInfoProjectStudent/${idProject}`, {
      headers: getAuthHeaders(),
    })
    .then((data) => {
      return data.data;
    });
}
