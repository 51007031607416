import { GET_FORMATION_ETUDIANT } from "../actions/types";

export const formationEtudiantReducer = (
  state = { formationEtudiant: {} },
  action
) => {
  switch (action.type) {
    case GET_FORMATION_ETUDIANT:
      return {
        ...state,
        formationEtudiant: action?.payload?.data,
      };
    default:
      return state;
  }
};
