import { createAction } from "@reduxjs/toolkit";
import { Formation } from "../../api/models";

export const setCurrentCourseId =
  createAction<Formation["id"]>("SET_CURRENT_COURSE");
export const unsetCurrentCourse = createAction("UNSET_CURRENT_COURSE");
export const setCourses = createAction<Formation[]>("SET_COURSES");
export const updateCourseAction = createAction<Formation>(
  "UPDATE_STUDENT_COURSE"
);
