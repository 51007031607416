import React, { FC } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Etudiant } from "../../../../api/models";
import { getStudents } from "../../../../api/course.service";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { Loader } from "../../../../component/Loader";
import { getError, getUrl } from "../../../../utils/utils";
import { MoreVert } from "@mui/icons-material";
import { useMenu, useSwitch } from "../../../../hooks/hooks";
import { resetCursor } from "../../../../api/student-course.service";
import { useSnackbar } from "../../../../hooks/snackbar";
import { LoadingButton } from "@mui/lab";

const ResetCursorDialog: FC<{
  courseId: number;
  studentId: number;
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const snackbar = useSnackbar();
  const mutation = useMutation({
    mutationKey: [],
    mutationFn: () => resetCursor(props.courseId, props.studentId),
    onSuccess: () => {
      snackbar.show("Curseur réinitialisé avec succès", "success");
      props.onClose();
    },
    onError: (error) => {
      snackbar.show(getError(error, "Server error"), "error");
    },
  });
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>Réinitialiser le curseur</DialogTitle>
      <DialogContent>
        Voulez-vous vraiment réinitialiser le curseur de la formation pour
        l'étudiant?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
        >
          Annuler
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          onClick={() => {
            mutation.mutate();
          }}
        >
          Confirmer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const StudentItem: FC<{
  student: Etudiant;
  courseId: number;
}> = (props) => {
  const { student } = props;
  const menu = useMenu();
  const confirmDialog = useSwitch();

  return (
    <>
      <ResetCursorDialog
        studentId={props.student.id}
        courseId={props.courseId}
        isOpen={confirmDialog.isOpen}
        onClose={confirmDialog.close}
      />
      <ListItem
        secondaryAction={
          <>
            <IconButton onClick={menu.open}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={menu.anchor}
              open={menu.isOpen}
              onClose={menu.close}
            >
              <MenuItem
                onClick={() => {
                  menu.close();
                  confirmDialog.open();
                }}
              >
                Réinitialiser le curseur
              </MenuItem>
            </Menu>
          </>
        }
      >
        <ListItemAvatar>
          <Avatar src={getUrl(student.user.userImage)} />
        </ListItemAvatar>
        <ListItemText children={student.user.nomComplet} />
      </ListItem>
    </>
  );
};

export const CourseStudentsDialog: FC<{
  isOpen: boolean;
  onClose: () => void;
  courseId: number;
}> = (props) => {
  const courseStudentsQuery = useQuery<Etudiant[]>({
    queryKey: ["courseStudents", props.courseId],
    queryFn: () =>
      getStudents(props.courseId).then((response) => response.data.students),
  });
  const students = courseStudentsQuery.data || [];

  return (
    <Dialog
      open={props.isOpen}
      maxWidth={"sm"}
      fullWidth={true}
      onClose={props.onClose}
    >
      <DialogTitle>Liste des étudiants</DialogTitle>
      <DialogContent>
        {courseStudentsQuery.isLoading ? (
          <Loader />
        ) : (
          <List>
            {students.map((student) => (
              <StudentItem student={student} courseId={props.courseId} />
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};
