import * as React from "react";
import { SVGProps } from "react";

export const BookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="vuesax/outline/book"
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    {...props}
  >
    <g fill={props.color || "#4bbc9a"} data-name="vuesax/outline/book">
      <path d="M20 36.797a2.925 2.925 0 0 1-1.417-.35 41.8 41.8 0 0 0-12.033-3.95l-.483-.067a4.7 4.7 0 0 1-3.984-4.533V7.766a4.52 4.52 0 0 1 4.95-4.55 32.2 32.2 0 0 1 12.333 4.081l.4.233a.648.648 0 0 0 .467.017l.267-.167a32.858 32.858 0 0 1 12.35-4.147h.2a4.419 4.419 0 0 1 3.4 1.183 4.511 4.511 0 0 1 1.466 3.381v20.119a4.682 4.682 0 0 1-4 4.533l-.55.067a41.337 41.337 0 0 0-11.983 3.95 2.742 2.742 0 0 1-1.383.331ZM6.633 5.697a2.038 2.038 0 0 0-1.4.533 2.062 2.062 0 0 0-.65 1.536v20.131a2.2 2.2 0 0 0 1.8 2.067l.5.067a44.271 44.271 0 0 1 12.833 4.2.748.748 0 0 0 .45.05 43.535 43.535 0 0 1 12.883-4.25l.567-.067a2.188 2.188 0 0 0 1.8-2.067V7.783a2.1 2.1 0 0 0-.65-1.55 2.176 2.176 0 0 0-1.6-.533h-.2a30.622 30.622 0 0 0-11.117 3.75l-.267.183a3.078 3.078 0 0 1-3.1.017l-.399-.234a30.689 30.689 0 0 0-11.25-3.719Z" />
      <path
        data-name="Vector"
        d="M20 35.4a1.259 1.259 0 0 1-1.25-1.25v-25A1.259 1.259 0 0 1 20 7.9a1.259 1.259 0 0 1 1.25 1.25v25A1.248 1.248 0 0 1 20 35.4ZM12.917 15.4h-3.75a1.259 1.259 0 0 1-1.25-1.25 1.259 1.259 0 0 1 1.25-1.25h3.75a1.259 1.259 0 0 1 1.25 1.25 1.259 1.259 0 0 1-1.25 1.25ZM14.167 20.4h-5a1.259 1.259 0 0 1-1.25-1.25 1.259 1.259 0 0 1 1.25-1.25h5a1.259 1.259 0 0 1 1.25 1.25 1.259 1.259 0 0 1-1.25 1.25Z"
      />
    </g>
  </svg>
);
