import * as React from "react";
import { SVGProps } from "react";

export const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27.757}
    height={27.757}
    {...props}
  >
    <path
      data-name="Path 4066"
      d="M24.215 11.39a10.586 10.586 0 0 0-1.326-3.153l2.132-2.842-2.775-2.742-2.819 2.156a10.565 10.565 0 0 0-3.168-1.291l-.5-3.517-3.9.023-.468 3.519a10.586 10.586 0 0 0-3.153 1.326L5.396 2.737 2.654 5.511 4.81 8.33a10.567 10.567 0 0 0-1.291 3.168L0 12l.023 3.9 3.519.468a10.577 10.577 0 0 0 1.326 3.153l-2.132 2.842 2.774 2.742 2.819-2.157a10.556 10.556 0 0 0 3.168 1.292l.5 3.517 3.9-.023.468-3.519a10.585 10.585 0 0 0 3.153-1.326l2.842 2.132 2.742-2.774-2.157-2.818a10.556 10.556 0 0 0 1.292-3.168l3.517-.5-.023-3.9Zm-10.337 8.932a6.444 6.444 0 1 1 6.444-6.444 6.443 6.443 0 0 1-6.444 6.444Z"
      fill={props.color || "#fff"}
    />
  </svg>
);
