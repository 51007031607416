import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { Formation, Workshop } from "../../../../../api/models";
import produce from "immer";
import { WorkshopSelection } from "./_types";
import { Container } from "@mui/system";
import { WorkshopForm } from "../../../../../component/WorkshopForm";
import { Typography } from "@mui/material";

export const WorkshopContent: FC<{
  selection: WorkshopSelection;
  course: Formation | null;
  setCourse: Dispatch<SetStateAction<Formation | null>>;
}> = (props) => {
  const { chapterIndex } = props.selection;
  const chapter = props.course?.chapitres[chapterIndex]!!;
  const workshop = chapter.Workshop;
  const onUpdateWorkshop = (workshop: Workshop) => {
    props.setCourse(
      produce((course) => {
        if (!!course) {
          course.chapitres[chapterIndex].Workshop = {
            ...course.chapitres[chapterIndex].Workshop,
            ...workshop,
          };
        }
      })
    );
  };
  return (
    <Container
      maxWidth={"md"}
      sx={{
        py: 2,
      }}
    >
      <Typography
        variant={"h4"}
        color={"primary"}
        sx={{
          fontWeight: "bold",
          mb: 4,
        }}
      >
        Détails workshop
      </Typography>
      <WorkshopForm
        workshop={workshop}
        chapterId={chapter.id}
        onUpdate={onUpdateWorkshop}
      />
    </Container>
  );
};
