import { FC, useEffect, useState } from "react";

// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "ckeditor5-build-classic-mathtype";
// import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';

import "../ContentPage.css";
import { baseUrl } from "../../../../../env";
import { LoadingButton } from "@mui/lab";
import { blackAndWhite } from "../../../../../styles";

export const PageContent: FC<{
  isLoading: boolean;
  pageId: number;
  data: string;
  onChange: (content: string) => void;
  onSave: (content: string) => void;
}> = (props) => {
  const [content, setContent] = useState(props.data);

  useEffect(() => {
    setContent(props.data);
  }, [props.data]);

  return (
    <div>
      <CKEditor
        id={props.pageId}
        editor={ClassicEditor}
        data={content}
        config={{
          mediaEmbed:{
            previewsInData:true,
          },
          ckfinder: {
            uploadUrl: `${baseUrl}/uploads`,
          },
          // extra plugins: [ SourceEditing ],
          toolbar: 
             [
                'heading', 'MathType', 'ChemType',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                'imageUpload',
                'mediaEmbed',
                'insertTable',
                'blockQuote',
                'undo',
                'sourceEditing',
                // 'sourceElement',
                // 'sourcePosition',
                // 'source',
                'redo'
            ],
         
        }}
        onReady={(editor: any) => {}}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          setContent(data);
          props.onChange(data);
        }}
        onBlur={(event: any, editor: any) => {}}
        onFocus={(event: any, editor: any) => {}}
        onError={(details: any) => {}}
      />
      <div
        style={{
          marginTop: "2rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton
          loading={props.isLoading}
          {...blackAndWhite}
          onClick={() => {
            props.onSave(content);
          }}
        >
          Sauvegarder
        </LoadingButton>
      </div>
    </div>
  );
};
