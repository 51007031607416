import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import React, { useEffect, useState } from "react";
import { setSiderCollapse } from "../reducers/layout.reducer";

export const useSider = () => {
  const dispatch = useDispatch();
  const state = useSelector((root: RootState) => root.layout.isCollapsed);
  useEffect(() => {
    dispatch(
      setSiderCollapse({
        isCollapsed: true,
      })
    );
    return () => {
      dispatch(
        setSiderCollapse({
          isCollapsed: state,
        })
      );
    };
  }, []);
};

export const useMenu = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const isOpen = !!anchor;
  const open = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchor(event.currentTarget);
  const close = () => setAnchor(null);

  return {
    anchor,
    isOpen,
    open,
    close,
  };
};

export function useSwitch(defaultValue = false) {
  const [isOpen, setIsOpen] = useState<boolean>(defaultValue);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const flip = () => setIsOpen((isOpen) => !isOpen);

  return {
    isOpen,
    open,
    close,
    flip,
  };
}
