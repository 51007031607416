import { Button, TextField, Typography } from "@mui/material";
import { FC, ReactNode, useState } from "react";

export const SimplySupportedBeamAtBothEnds: FC<{}> = (props) => {
  const [L, setL] = useState(0);
  const [b, setB] = useState(0);
  const [h, setH] = useState(0);
  const [P, setP] = useState(0);
  const [Alpha, setAlpha] = useState(0);
  const [comp, setComp] = useState<ReactNode>(<></>);
  const [E, setE] = useState(0);

  function inertie() {
    return (b * Math.pow(h, 3)) / 12;
  }

  function Shear_Force() {
    let Beta = L - Alpha;
    let T1 = (P * Beta) / L;
    let T2 = (P * Alpha) / L;
    return [T1, T2];
  }

  function Moment() {
    let Beta = L - Alpha;
    let M = (P * Alpha * Beta) / L;
    return M;
  }

  function deflection() {
    let I = inertie();
    let Beta = L - Alpha;
    let Delta_max =
      (P *
        Alpha *
        Beta *
        (Alpha + 2 * Beta) *
        Math.sqrt(3 * Alpha * (Alpha + 2 * Beta))) /
      (27 * 1000 * E * I * L);
    return Delta_max;
  }

  function contrainte() {
    let M = Moment();
    let I = inertie();
    let cont = (M / I) * (h / 2);
    return cont;
  }

  function compute() {
    let I = inertie();
    const [T1, T2] = Shear_Force();
    let M = Moment();
    let Delta_max = deflection();
    let cont = contrainte();
    setComp(
      <>
        <Typography style={{ fontWeight: "bold" }}>Results</Typography>
        <p>Moment of Inertia, I= {I.toFixed(3)} m4</p>
        <p>Shear Force, V1=R1= {T1.toFixed(3)} KN</p>
        <p>Shear Force, V2=R2={T2.toFixed(3)} KN</p>
        <p>Maximum Moment, M={M.toFixed(3)} KN.m</p>
        <p>Maximum Deflection, &#916;max= {Delta_max} m</p>
        <p>Maximum Stress, &#963;max={cont.toFixed(2)} MPa</p>
      </>
    );
  }

  function s(e: any) {
    return parseFloat(e.target.value);
  }

  return (
    <div>
      <img
        src={
          "https://www.geotashfeen.tech/images/Poutre%20+charge%20cc%20qq.PNG"
        }
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          compute();
        }}
      >
        <table style={{ border: "1px solid black" }}>
          <thead>
            <tr>
              <th
                style={{
                  backgroundColor: "#D3D3D3",
                  textAlign: "center",
                }}
                colSpan={6}
                align="center"
              >
                Beam Geometry
              </th>
            </tr>
            <tr>
              <th>Length of Beam, L(m)</th>
              <th>width of Beam, b(m)</th>
              <th>height of Beam, h(m)</th>
              <th>Load on Beam, P(KN)</th>
              <th>Distance to Load, &#945;(m)</th>
              <th>Youngs Modulus, E(MPa)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TextField
                  name="L"
                  id="L"
                  value={L}
                  type={"number"}
                  onChange={(e) => setL(s(e))}
                />
              </td>
              <td>
                <TextField
                  type="number"
                  name="b"
                  id="b"
                  value={b}
                  onChange={(e) => setB(s(e))}
                />
              </td>
              <td>
                <TextField
                  type="number"
                  name="h"
                  id="h"
                  value={h}
                  onChange={(e) => setH(s(e))}
                />
              </td>
              <td>
                <TextField
                  type="number"
                  name="P"
                  id="P"
                  value={P}
                  onChange={(e) => setP(s(e))}
                />
              </td>
              <td>
                <TextField
                  type="number"
                  name="alpha"
                  id="alpha"
                  value={Alpha}
                  onChange={(e) => setAlpha(s(e))}
                />
              </td>
              <td>
                <TextField
                  type="number"
                  name="E"
                  id="E"
                  value={E}
                  onChange={(e) => setE(s(e))}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <Button
        variant={"contained"}
        size={"large"}
        style={{
          marginTop: "1rem",
        }}
        onClick={() => {
          compute();
        }}
      >
        Compute
      </Button>
      <div style={{ marginTop: "1rem" }}>{comp}</div>
    </div>
  );
};
