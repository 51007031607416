import React, { FC, PropsWithChildren, StrictMode } from "react";
import "./App.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "./hooks/snackbar";
import moment from "moment";
import "moment/locale/fr";
import { theme } from "./theme/theme";
import { usePing } from "./hooks/use-ping";
import { Router } from "./routes/components/Router";
import store from "./store";
import { Provider } from "react-redux";

moment.locale("fr");

const accessToken = localStorage.getItem("accessToken");
if (!!accessToken) {
  axios.defaults.headers.common["x-access-token"] = accessToken;
}

const Wrapper: FC<PropsWithChildren> = (props) => {
  usePing();

  return <>{props.children}</>;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
    },
    mutations: {
      cacheTime: 0,
    },
  },
});

function App() {
  return (
    <StrictMode>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Wrapper>
                <Router />
              </Wrapper>
            </ThemeProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </Provider>
    </StrictMode>
  );
}

export default App;
