import { cloneElement, FC } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

export const SideBarButton: FC<any> = ({
  icon,
  children,
  link,
  collapsed,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <NavLink
      to={link}
      className={({ isActive }) => {
        return isActive ? "active" : "";
      }}
    >
      {({ isActive }) => (
        <Button
          style={{
            justifyContent: collapsed ? "center" : "flex-start",
          }}
          sx={{
            "&": {
              textTransform: "capitalize",
              color: "white",
              justifyContent: "flex-start",
            },
            "&:hover": () => {
              return {
                backgroundColor: "#E8E8E8 0% 0% no-repeat padding-box",
                color: "#fff",
                fontWeight: "bold",
              };
            },
          }}
          {...props}
          onClick={() => {
            navigate(link, {
              replace: true,
            });
          }}
          fullWidth
          className={"d-flex align-items-center nav-link-btn py-2"}
        >
          <div
            style={{
              width: 32,
              height: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cloneElement(icon, {
              color: isActive ? "#048B9A" : "#fff",
            })}
          </div>

          <div
            className={"d-flex align-items-center nav-link-text"}
            style={{
              marginLeft: ".75rem",
              marginBottom: 0,
              fontSize: ".975rem",
            }}
          >
            {children}
          </div>
        </Button>
      )}
    </NavLink>
  );
};
