import axios from "axios";
import authHeader from "./auth-header";
import { baseUrl } from "../env";

const getAllOneToOneByEtudiantURL = `${baseUrl}/oneToOne/etudiant/`;
const getWorkshopByEtudiantFormationURL = `${baseUrl}/api/workshop/getWorkshopbyFormation`;
const getStudentssUrl = `${baseUrl}/students/all`;
const getFormationInfoUrl = `${baseUrl}/api/courses/`;
const updateFormationCreaneauByIDUrl = `${baseUrl}/api/courses/update/creneau/`;
const getListFormationStudentByIDUrl = `${baseUrl}/api/formationEtudiant/allformationbystudent/`;
const getListRecruteursUrl = `${baseUrl}/recruteur/getAll/`;
const getListClubsUrl = `${baseUrl}/club/getAll/`;

export const getQcmSheet = async (data) =>
  await axios.get(`${baseUrl}/api/Qcm/qcmSheet/${data.idQcm}`, {
    headers: authHeader(),
  });

export const validQcmSheet = async (data) =>
  await axios.post(
    `${baseUrl}/api/Qcm/valid_qcm`,
    {
      answerSheet: data.answerSheet,
      formationId: data.formationId,
    },
    { headers: authHeader() }
  );

export const getGeneralProgessionFormationByStudent = async (idFormation) =>
  await axios.get(
    `${baseUrl}/api/courses/get_general_progession_formation_by_student/${idFormation}`,
    {
      headers: authHeader(),
    }
  );

export const pushProject = (filepath, projectId) => {
  return axios.post(`${baseUrl}/student-projects/push/${projectId}`, filepath, {
    headers: authHeader(),
    "Content-Type": "multipart/form-data",
  });
};

export const getAllOneToOneByEtudiant = (data) =>
  axios.post(getAllOneToOneByEtudiantURL + data.userId, {
    formationId: data.idFormation,
  });

export const getWorkshopByEtudiantFormation = (data) =>
  axios.post(getWorkshopByEtudiantFormationURL, {
    formationId: data.formationId,
    etudiantId: data.etudiantId,
  });

export const getAllStudentsData = () =>
  axios.get(getStudentssUrl, { headers: authHeader() });
export const getGeneralFormationInfo = (idFormation) =>
  axios.get(getFormationInfoUrl + idFormation + "/general");
export const updateFormationCreaneauByID = (data) =>
  axios.put(updateFormationCreaneauByIDUrl + data.id, {
    solde_one_to_one: data.solde_one_to_one,
  });
export const getListFormationStudentByID = (data) =>
  axios.get(getListFormationStudentByIDUrl + data, { headers: authHeader() });
export const getListRecruteurs = () =>
  axios.get(getListRecruteursUrl, { headers: authHeader() });
export const getListClubs = () =>
  axios.get(getListClubsUrl, { headers: authHeader() });
export const getLastFormation = (id) =>
  axios
    .get(`${baseUrl}/api/formationEtudiant/getLast/${id}`)
    .then((response) => response.data);
