import { FC, useEffect } from "react";
import { getProjects } from "../../../api/instructor.service";
import { useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  HeaderCell,
  StyledTableCell,
} from "../../../component/StyledTableCell";
import moment from "moment";
import { updateStudentProject } from "../../../api/student-project.service";
import { getError, getUrl } from "../../../utils/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { RootState } from "../../../store";
import { useSnackbar } from "../../../hooks/snackbar";
import { Empty } from "../../../component/Empty";
import { Cancel, Check, Download } from "@mui/icons-material";

const CircleWithLabel: FC<{
  circleColor: string;
  label: string;
}> = (props) => {
  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item>
        <div
          style={{
            width: 14,
            height: 14,
            backgroundColor: props.circleColor,
            borderRadius: "50%",
          }}
        />
      </Grid>
      <Grid item>
        <Typography>{props.label}</Typography>
      </Grid>
    </Grid>
  );
};

export const ProjectsPage = () => {
  const userId = useSelector((state: RootState) => state.auth.user!!.id);
  const snackbar = useSnackbar();
  const query = useQuery({
    queryKey: ["getStudentsProjects", userId],
    queryFn: () => getProjects(userId).then((response) => response.data),
  });
  const updateStudentProjectMutation = useMutation({
    mutationKey: ["updateStudentProject", userId],
    mutationFn: (data: { id: number; isValidated: boolean }) => {
      return updateStudentProject(data.id, data.isValidated)
        .then(() => {
          query.refetch().then();
        })
        .catch((response) => {
          snackbar.show(getError(response, "Erreur serveur"), "error");
        });
    },
  });
  const studentProjects = query.data || [];
  const coursesIds = Array.from(
    new Set(
      studentProjects.map(
        (studentProject) => studentProject.project.chapitre.formation.id
      )
    )
  );
  const courses = coursesIds.map(
    (courseId) =>
      studentProjects.find(
        (studentProject) =>
          studentProject.project.chapitre.formationId == courseId
      )!!.project.chapitre.formation
  );

  function getStudentProjectsOfCourse(courseId: number) {
    return studentProjects.filter(
      (studentProject) =>
        studentProject.project.chapitre.formationId === courseId
    );
  }

  useEffect(() => {
    if (query.isError) {
      snackbar.show("Erreur lors du chargement des Series", "error");
    }
  }, [query.isError]);

  if (query.isLoading) {
    return <></>;
  }

  function handleUpdateStudentProject(id: number, isValidated: boolean) {
    updateStudentProjectMutation.mutate({ id, isValidated });
  }

  return (
    <div style={{ margin: "1rem" }}>
      {courses.length === 0 && (
        <Empty message={"Pas de Series soumis pour le moment"} />
      )}
      {courses.length > 0 &&
        courses.map((course) => (
          <div key={course.id}>
            <Typography
              style={{
                color: "#023047",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Formation:{" "}
              <span style={{ color: "#048B9A" }}>{course.title}</span>
            </Typography>
            <TableContainer
              style={{
                borderRadius: 6,
              }}
            >
              <Table
                sx={{
                  border: "2px solid #E8E8E8",
                }}
              >
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#FAFAFA",
                    }}
                  >
                    <HeaderCell>#</HeaderCell>
                    <HeaderCell>Etudiant</HeaderCell>
                    <HeaderCell>Statut</HeaderCell>
                    <HeaderCell>Chapitre</HeaderCell>
                    <HeaderCell>Soumis Le</HeaderCell>
                    <HeaderCell>Action</HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getStudentProjectsOfCourse(course.id).map(
                    (studentProject, index) => (
                      <TableRow key={studentProject.id}>
                        <StyledTableCell>{index + 1}.</StyledTableCell>
                        <StyledTableCell>
                          <Grid container spacing={2} alignItems={"center"}>
                            <Grid item>
                              <Avatar
                                src={getUrl(
                                  studentProject.etudiant.user.userImage
                                )}
                              />
                            </Grid>
                            <Grid item>
                              {studentProject.etudiant.user.nomComplet}
                            </Grid>
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell style={{ fontWeight: "bold" }}>
                          {studentProject.estValide && (
                            <CircleWithLabel
                              circleColor={"green"}
                              label={"Validé"}
                            />
                          )}
                          {studentProject.estValide === false && (
                            <CircleWithLabel
                              circleColor={"red"}
                              label={"Non Validé"}
                            />
                          )}
                          {studentProject.estValide === null && (
                            <CircleWithLabel
                              circleColor={"#FFB703"}
                              label={"Soumis"}
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {studentProject.project.chapitre.title}
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment(studentProject.createdAt).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Grid container alignItems={"center"} spacing={2}>
                            <Grid item>
                              <Button
                                size={"small"}
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                }}
                                disabled={
                                  updateStudentProjectMutation.isLoading
                                }
                                onClick={() => {
                                  handleUpdateStudentProject(
                                    studentProject.id,
                                    true
                                  );
                                }}
                              >
                                <Check />
                                Valider
                              </Button>
                            </Grid>

                            <Grid item>
                              <Button
                                size={"small"}
                                style={{
                                  backgroundColor: "crimson",
                                  color: "white",
                                }}
                                disabled={
                                  updateStudentProjectMutation.isLoading
                                }
                                onClick={() => {
                                  handleUpdateStudentProject(
                                    studentProject.id,
                                    false
                                  );
                                }}
                              >
                                <Cancel />
                                Refuser
                              </Button>
                            </Grid>

                            <Grid item>
                              <Button
                                style={{
                                  backgroundColor: "black",
                                  color: "white",
                                }}
                                size={"small"}
                                download={true}
                                target={"_blank"}
                                href={getUrl(studentProject.fichierUrl)}
                              >
                                <Download />
                                Télecharger
                              </Button>
                            </Grid>
                          </Grid>
                        </StyledTableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
    </div>
  );
};
