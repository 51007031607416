import { FC } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import {
  blue,
  green,
  green100,
  grey,
  grey1,
  primary,
  purple,
  secondary,
  violet,
  yellow2,
} from "../theme/colors";
import { StudentAppBar } from "../pages/student/home/StudentAppBar";
import { Content } from "antd/lib/layout/layout";
import { useNavigate } from "react-router-dom";
import { getImageStyles } from "../utils/utils";
import { home } from "../routes/student.routes";

export const CustomCard: FC<{
  title: string;
  tag: string;
  price: number;
  showActionBar: boolean;
  image: string;
  onAdd: () => void;
  onClick?: () => void;
}> = (props) => {
  const isFree = parseInt(String(props.price)) === 0;
  return (
    <Card
      elevation={0}
      style={{
        color: "white",
        borderRadius: 20,
        background: "#F0F3F9",
      }}
    >
      <div
        style={{
          // background: "#F0F3F9",
          ...getImageStyles(props.image),
          height: 206,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          cursor: typeof props.onClick === "undefined" ? undefined : "pointer",
        }}
        onClick={() => {
          props.onClick?.();
        }}
      ></div>
      <CardContent
        style={{
          backgroundColor: primary,
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: 22,
            minHeight: 67,
            cursor:
              typeof props.onClick === "undefined" ? undefined : "pointer",
          }}
          onClick={() => {
            props.onClick?.();
          }}
        >
          {props.title}
        </Typography>
        <Typography style={{ color: yellow2, marginTop: 10 }}>
          {props.tag}
        </Typography>
        {!props.showActionBar && (
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"flex-end"}
            spacing={1}
            style={{ marginTop: 15 }}
          >
            <Grid item>
              <Typography style={{ fontSize: 12, marginBottom: 8 }}>
                Prix
              </Typography>
              <div>
                <Typography
                  style={{
                    backgroundColor: isFree ? green100 : violet,
                    color: props.price === 0 ? green : purple,
                    padding: 6,
                    borderRadius: 6,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {isFree ? "Gratuit" : `${props.price}DT`}
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <Button
                disableElevation={true}
                variant={"contained"}
                style={{ background: blue, color: "white" }}
                onClick={() => {
                  props.onAdd();
                }}
              >
                <img
                  src={"/icons/bag-outlined.svg"}
                  style={{ marginRight: 11, fontWeight: "bold" }}
                />
                Ajouter
              </Button>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
const MyListButton: FC<{
  title: string;
  isActive: boolean;
  onClick: () => void;
  number?: number;
}> = (props) => {
  return (
    <Button
      style={{
        color: grey1,
        backgroundColor: props.isActive ? grey : "transparent",
        fontWeight: props.isActive ? "bold" : "normal",
      }}
      onClick={props.onClick}
    >
      <span>{props.title}</span>
      {typeof props.number !== "undefined" && (
        <span
          style={{
            borderRadius: "50%",
            backgroundColor: props.isActive ? grey1 : grey,
            color: props.isActive ? grey : grey1,
            fontWeight: props.isActive ? "bold" : "normal",
            marginLeft: "0.25rem",
            height: 24,
            width: 24,
          }}
        >
          {props.number}
        </span>
      )}
    </Button>
  );
};
const DiscoverButton: FC<{
  isActive: boolean;
  onClick: () => void;
}> = (props) => {
  return (
    <Button
      disableElevation
      variant={"contained"}
      style={{
        backgroundColor: props.isActive ? grey : "transparent",
        fontWeight: props.isActive ? "bold" : "normal",
        color: grey1,
      }}
      onClick={props.onClick}
    >
      Découvrir
    </Button>
  );
};
export const ExploreLayout: FC<{
  banner: {
    url: string;
    textColor: string;
  };
  title: string;
  discoverButton: {
    isActive: boolean;
    onClick: () => void;
  };
  listButton: {
    title: string;
    isActive: boolean;
    number?: number;
    onClick: () => void;
  };
  items: {
    title: string;
    tag: string;
    price: number;
    showActionBar: boolean;
    image: string;
    onAdd: () => void;
    onClick?: () => void;
  }[];
}> = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <StudentAppBar />
      <Content>
        <div
          style={{
            background: `url(${props.banner.url}) no-repeat center center`,
            backgroundSize: "cover",
          }}
        >
          <Container maxWidth={"lg"}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 8,
                alignItems: "center",
                minHeight: 148,
                flexWrap: "wrap",
              }}
            >
              <div>
                <Typography
                  style={{
                    fontSize: 48,
                    fontFamily: "Biennale",
                    color: props.banner.textColor,
                  }}
                >
                  {props.title}
                </Typography>
              </div>
              <div>
                <Button
                  variant={"contained"}
                  style={{ backgroundColor: secondary }}
                  disableElevation
                  onClick={() => {
                    navigate(home);
                  }}
                >
                  <img
                    style={{ marginRight: "0.5rem" }}
                    src={"/icons/home-outlined.svg"}
                  />
                  Accueil
                </Button>
              </div>
            </div>
          </Container>
        </div>
        <Box pt={5} pb={5}>
          <Grid container justifyContent={"center"} spacing={3}>
            <Grid item>
              <DiscoverButton
                isActive={props.discoverButton.isActive}
                onClick={props.discoverButton.onClick}
              />
            </Grid>
            <Grid item>
              <MyListButton
                title={props.listButton.title}
                isActive={props.listButton.isActive}
                number={props.listButton.number}
                onClick={props.listButton.onClick}
              />
            </Grid>
          </Grid>
        </Box>
        <Container maxWidth={"lg"}>
          <Grid container spacing={3}>
            {props.items.map((item, key) => (
              <Grid item key={key} xs={12} md={6} lg={4}>
                <CustomCard
                  title={item.title}
                  price={item.price}
                  tag={item.tag}
                  showActionBar={item.showActionBar}
                  image={item.image}
                  onAdd={item.onAdd}
                  onClick={item.onClick}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Content>
    </div>
  );
};
