import { baseUrl } from "../env";
import axios from "axios";
import { User } from "./models";

const uri = `${baseUrl}/user`;

export function login(email: string, password: string) {
  return axios.post<
    any,
    {
      data: {
        accessToken: string;
        data: User;
      };
    }
  >(`${uri}/login`, {
    email: email,
    motDePasse: password,
  });
}
