import * as React from "react";
import { SVGProps } from "react";

export const LocalizationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Group 384"
    xmlns="http://www.w3.org/2000/svg"
    width={15.543}
    height={23.34}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 216" fill="none" d="M0 0h15.543v23.34H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 383" clipPath="url(#a)">
      <path
        data-name="Path 3757"
        d="M7.638 23.34a2.419 2.419 0 0 1-.37-.41Q4.128 17.275 1 11.612a7.477 7.477 0 0 1-.951-4.58A7.49 7.49 0 0 1 4.369.845a7.411 7.411 0 0 1 7.717.515 7.447 7.447 0 0 1 3.377 5.4 7.571 7.571 0 0 1-.908 4.829q-3.13 5.675-6.274 11.34a2.421 2.421 0 0 1-.37.41ZM7.8 3.915a3.885 3.885 0 1 0 3.855 3.927A3.889 3.889 0 0 0 7.8 3.915"
        fill="#023047"
      />
      <path
        data-name="Path 3758"
        d="M7.799 3.915a3.885 3.885 0 1 1-3.9 3.833 3.889 3.889 0 0 1 3.9-3.833"
        fill="#fff"
      />
    </g>
  </svg>
);
