import { FC, useEffect, useState } from "react";
import {
  Question,
  Reponse,
  SousChapitre,
  STUDENT_QUIZ_SUCCESS,
  StudentQuiz,
  StudentQuizStatus,
} from "../../../../api/models";
import {
  Box,
  Button,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedUser } from "../../../../hooks/student/hooks";
import { passQuiz } from "../../../../api/student.service";
import { useSwitch } from "../../../../hooks/hooks";
import { useSnackbar } from "../../../../hooks/snackbar";
import { getError } from "../../../../utils/utils";
import { QuizResultDialog } from "./QuizResultDialog";
import { blackAndWhite } from "../../../../styles";
import { LoadingButton } from "@mui/lab";

export const ContentOfQuiz: FC<{
  subchapter: SousChapitre;
  studentQuiz?: StudentQuiz;
  onPass: (studentQuiz: StudentQuiz) => void;
  onFail: (studentQuiz: StudentQuiz) => void;
  onNext: () => void;
}> = (props) => {
  const resultDialog = useSwitch();
  const user = useAuthenticatedUser();
  const quiz = props.subchapter.qcm;
  const [questions, setQuestions] = useState(props.subchapter.qcm.questions);
  const [answers, setAnswers] = useState<Reponse[]>([]);
  const [allAnswers, setAllAnswers] = useState<Reponse[]>([]);
  const snackbar = useSnackbar();
  const [examResult, setExamResult] = useState<
    { studentQuiz: StudentQuiz; status: StudentQuizStatus } | undefined
  >();

  const passQuizMutation = useMutation({
    mutationKey: ["passQuiz", user?.id, quiz.id],
    mutationFn: () =>
      passQuiz(
        quiz.id,
        answers.map((answer) => answer.id)
      )
        .then((response) => {
          const { studentQuiz, status } = response.data;
          onPassQuiz(studentQuiz, status);
        })
        .catch((response) => {
          snackbar.show(getError(response, "Erreur du serveur"), "error");
        }),
  });

  const onPassQuiz = (studentQuiz: StudentQuiz, status: StudentQuizStatus) => {
    if (status === "fail") {
      setAnswers([]);
    }
    setExamResult({ studentQuiz, status });
  };

  const handleValidate = () => {
    passQuizMutation.mutate();
    // props.onPass();
  };

  const handleChange = (answerId: number, question: Question) => {
    const newAnswer = allAnswers.find((answer) => answer.id === answerId);
    if (newAnswer !== undefined) {
      setAnswers((answers) => [
        ...answers.filter((answer) => answer.QuestionId !== question.id),
        newAnswer,
      ]);
    }
  };

  const getAllAnswers = () => [
    ...quiz.questions.map((question) => question.Reponses).flat(),
  ];

  useEffect(() => {
    const allAnswers = getAllAnswers();

    setQuestions([...quiz.questions]);
    setAllAnswers(allAnswers);
    setAnswers([]);
  }, [quiz.questions]);

  useEffect(() => {
    if (!!examResult) {
      resultDialog.open();
    }
  }, [examResult]);

  useEffect(() => {
    console.log(props.studentQuiz);
    if (
      Boolean(props.studentQuiz) &&
      props.studentQuiz?.status === STUDENT_QUIZ_SUCCESS
    ) {
      const allAnswers = getAllAnswers();
      const theAnswers = props.studentQuiz.student_answers
        .map((studentAnswer) => studentAnswer.ReponseId)
        .map((id) => allAnswers.find((answer) => answer.id === id))
        .filter((answer) => answer !== undefined) as Reponse[];

      setAnswers(theAnswers);
    }
  }, [props.studentQuiz]);

  return (
    <>
      <QuizResultDialog
        isOpen={resultDialog.isOpen}
        onClose={() => {
          if (examResult?.status === "success") {
            props.onPass(examResult.studentQuiz);
          } else if (examResult?.status === "fail") {
            props.onFail(examResult.studentQuiz);
          }
          resultDialog.close();
        }}
        data={
          !!examResult
            ? {
                status: examResult.status,
                score: examResult.studentQuiz.score,
              }
            : undefined
        }
      />
      <Stack sx={{ pb: 2 }} spacing={2}>
        <Stack spacing={6}>
          {questions.map((question) => (
            <Box
              key={[question.id, quiz.questions.length].join("-")}
              sx={{
                borderRadius: "10px",
                border: "1px solid #707070",
                padding: 3,
              }}
            >
              <Box mb={2}>
                <Typography style={{ color: "black", fontWeight: 500 }}>
                  {question.question}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #707070",
                  padding: 3,
                }}
              >
                <RadioGroup
                  value={
                    answers.find((answer) => answer.QuestionId === question.id)
                      ?.id || ""
                  }
                  onChange={(event, value) => {
                    const id = parseInt(value);
                    if (id) {
                      handleChange(id, question);
                    }
                  }}
                >
                  <Stack
                    sx={{
                      "& > *": {
                        borderTop: "1px solid #707070",
                        borderLeft: "1px solid #707070",
                        borderRight: "1px solid #707070",
                      },
                      "& > *:last-child": {
                        borderBottom: "1px solid #707070",
                      },
                    }}
                  >
                    {question.Reponses.map((answer) => (
                      <Box
                        key={answer.id}
                        sx={{
                          py: 1,
                          px: 2,
                        }}
                      >
                        <Stack direction={"row"}>
                          <Radio
                            disabled={!!props.studentQuiz}
                            value={answer.id}
                          />
                          <Typography
                            sx={{
                              padding: "9px",
                            }}
                          >
                            {answer.reponse}
                          </Typography>
                        </Stack>
                      </Box>
                    ))}
                  </Stack>
                </RadioGroup>
              </Box>
            </Box>
          ))}
        </Stack>
        <Grid display={"flex"} justifyContent={"center"}>
          <div>
            {props.studentQuiz?.status === STUDENT_QUIZ_SUCCESS ? (
              <Button
                {...blackAndWhite}
                sx={{
                  ...blackAndWhite.sx,
                  px: 10,
                  py: 1,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={props.onNext}
              >
                Suivant
              </Button>
            ) : (
              <LoadingButton
                loading={passQuizMutation.isLoading}
                {...blackAndWhite}
                sx={{
                  ...blackAndWhite.sx,
                  px: 10,
                  py: 1,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => {
                  handleValidate();
                }}
              >
                Valider
              </LoadingButton>
            )}
          </div>
        </Grid>
      </Stack>
    </>
  );
};
