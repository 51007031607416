import React, { FC, ReactNode, useState } from "react";
import {
  EtudiantProject,
  EtudiantProjectStatut,
  Project as IProject,
} from "../../../api/models";
import {
  Box,
  Button,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ProjectInstructions } from "./ProjectInstructions";
import { ProjectFile } from "./ProjectFile";
import { ProjectDetails } from "./ProjectDetails";
import { blackAndWhite } from "../../../styles";

enum ProjectTab {
  DETAILS,
  INSTRUCTIONS,
  PROJECT,
}

type Props = {
  chapterTitle: string;
  index: number;
  project: IProject;
  total: number;
  studentProject?: EtudiantProject;
  onUpdate: () => void;
  onNext: () => void;
  action?: {
    title: ReactNode;
    onClick: () => void;
  };
};

export const CourseProject: FC<Props> = (props) => {
  const [selectedTab, selectTab] = useState<ProjectTab>(ProjectTab.DETAILS);
  const hasProject = Boolean(props.studentProject);

  const canUpload =
    !hasProject ||
    props.studentProject?.statut === EtudiantProjectStatut.SUBMITTED ||
    props.studentProject?.statut === EtudiantProjectStatut.REFUSED;

  if (props.studentProject?.statut === EtudiantProjectStatut.ACCEPTED) {
    return (
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          minHeight: "70vh",
        }}
        spacing={4}
      >
        <img
          src={"/images/project_success.svg"}
          style={{
            width: 670,
            height: 520,
          }}
        />
        <Stack
          direction={"column"}
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography>
            Vous avez terminé la Serie du chapitre{" "}
            <b>Créer ses propres hachures et types de ligne</b>
          </Typography>
          {props.action && (
            <Box>
              <Button
                {...blackAndWhite}
                onClick={() => {
                  props.action?.onClick();
                }}
              >
                {props.action.title}
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} justifyContent={"center"}>
        <Tabs
          value={selectedTab}
          sx={{
            width: "fit-content",
            borderBottom: "1px solid #E8E8E8",
            "& .MuiButtonBase-root": {
              textTransform: "capitalize",
              fontSize: 18,
              fontWeight: "bold",
              mx: 6,
            },
          }}
          onChange={(event, tab) => {
            selectTab(tab);
          }}
        >
          <Tab label={"Détails"} />
          <Tab label={"Instructions"} />
          <Tab label={"Projet"} disabled={!canUpload} />
        </Tabs>
      </Stack>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Container
          maxWidth={"md"}
          sx={{
            "&> *": {
              borderRadius: "31px",
              border: "1px solid #707070",
              px: 8,
              py: 4,
            },
          }}
        >
          {selectedTab === ProjectTab.DETAILS && (
            <ProjectDetails
              chapterTitle={props.chapterTitle}
              project={props.project}
              index={props.index}
              total={props.total}
              studentProject={props.studentProject}
            />
          )}
          {selectedTab === ProjectTab.INSTRUCTIONS && (
            <ProjectInstructions summary={props.project.enonce} />
          )}
          {canUpload && selectedTab === ProjectTab.PROJECT && (
            <ProjectFile
              projectId={props.project.id}
              studentProject={props.studentProject}
              onUpdate={props.onUpdate}
            />
          )}
        </Container>
      </div>
    </Stack>
  );
};
