import { baseUrl } from "../env";

export function format(numberOfMinutes: number) {
  let returnValue = "";
  const hours = Number(parseInt(String(numberOfMinutes / 60)));
  const minutes = numberOfMinutes % 60;

  if (hours === 0 && minutes === 0) {
    return "0H";
  }

  if (hours !== 0) {
    returnValue = `${hours}H`;
  }

  if (minutes > 0) {
    returnValue = `${returnValue}${hours > 0 ? "," : ""}${
      minutes < 10 ? "0" : ""
    }${minutes}Min`;
  }

  return returnValue;
}

export function capitalize(string: string) {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export function getUrl(path?: string) {
  if (!path) return "";
  return `${baseUrl}/${path}`;
}

export function getImageStyles(url: string) {
  return {
    backgroundImage: `url(${url})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  };
}

export function isDevelopmentEnvironment() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}

export function humanize(minutes: number): string {
  const hours = parseInt(String(minutes / 60));
  const restMinutes = minutes - hours * 60;
  const f = (value: number, label: string) =>
    value ? `${value} ${label}${value === 1 ? "" : "s"}` : undefined;

  return [f(hours, "heure"), f(restMinutes, "minute")]
    .filter(Boolean)
    .join(" et ");
}

export const compareByField =
  <T>(key: keyof T, order: "desc" | "asc" = "desc") =>
  (item1: T, item2: T) => {
    const value1 = item1[key];
    const value2 = item2[key];
    const c = order === "asc" ? 1 : -1;

    if (typeof value1 === "string" && typeof value2 === "string") {
      return c * String(value1).localeCompare(String(value2));
    } else if (typeof value1 === "number" && typeof value2 === "number") {
      return c * (value1 - value2);
    }

    return c * String(value1).localeCompare(String(value2));
  };

export function getError(response: any, defaultMessage: string): string {
  return response.response?.data?.message || defaultMessage;
}
