import React, { FC, useEffect, useState } from "react";
import { getWorkshops } from "../../../../api/course.service";
import { useParams } from "react-router-dom";
import { Formation, PresenceWorkshop, Workshop } from "../../../../api/models";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Close } from "@mui/icons-material";
import {
  getAttendanceInWorkshop,
  setAttendanceInWorkshop,
} from "../../../../api/student_workshop_attendance.service";
import { LoadingButton } from "@mui/lab";
import { StyledTableCell } from "../../../../component/StyledTableCell";
import { getUrl } from "../../../../utils/utils";
import { WorkshopForm } from "../../../../component/WorkshopForm";

const EditWorkshopDialog: FC<{
  workshop: Workshop;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (id: number, workshop: Partial<Workshop>) => void;
}> = (props) => {
  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      open={props.isOpen}
      onClose={props.onClose}
    >
      <DialogTitle>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid item>
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: "bold",
              }}
              color={"primary"}
            >
              Modification Workshop
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                props.onClose();
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <WorkshopForm
          workshop={props.workshop}
          chapterId={props.workshop.ChapitreId}
          onUpdate={(workshop) => {
            props.onUpdate(workshop.id, workshop);
            props.onClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const AttendanceDialog: FC<{
  workshopId: number;
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const [attendanceList, setAttendanceList] = useState<PresenceWorkshop[]>([]);

  useEffect(() => {
    if (props.isOpen) {
      getAttendanceInWorkshop(props.workshopId).then((response) => {
        setAttendanceList(response.data);
      });
    }
    return () => {
      setAttendanceList([]);
    };
  }, [props.isOpen]);

  function updateStudentAttendance(studentId: number, isPresent: boolean) {
    setAttendanceList((attendanceList) =>
      attendanceList.map((attendance) => ({
        ...attendance,
        estPresent:
          attendance.etudiantId === studentId
            ? isPresent
            : attendance.estPresent,
      }))
    );
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      PaperProps={{
        style: {
          borderRadius: 50,
        },
      }}
      style={{}}
    >
      <DialogContent
        style={{ padding: "3rem" }}
        sx={{
          "&>*:not(:last-child)": {
            marginBottom: "1rem",
          },
        }}
      >
        {attendanceList.map((attendance) => (
          <Grid
            key={attendance.etudiantId}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  <Avatar src={getUrl(attendance.etudiant.user.userImage)} />
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: 18,
                      color: "#023047",
                    }}
                  >
                    {attendance.etudiant.user.nomComplet}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {attendance.estPresent ? (
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                  }}
                  onClick={() => {
                    updateStudentAttendance(attendance.etudiantId, false);
                  }}
                >
                  Présent
                </Button>
              ) : (
                <Button
                  variant={"outlined"}
                  style={{
                    color: "black",
                    borderColor: "black",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                  }}
                  onClick={() => {
                    updateStudentAttendance(attendance.etudiantId, true);
                  }}
                >
                  Présent
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"center"}>
          <LoadingButton
            variant={"contained"}
            size={"large"}
            style={{ backgroundColor: "black", color: "white" }}
            onClick={() => {
              setAttendanceInWorkshop(props.workshopId, attendanceList).then(
                (response) => {
                  setAttendanceList(response.data);
                  props.onClose();
                }
              );
            }}
          >
            Sauvegarder
          </LoadingButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const WorkshopRow: FC<{
  index: number;
  workshop: Workshop;
  onUpdate: (id: number, workshop: Partial<Workshop>) => void;
}> = (props) => {
  const [editDialogIsShow, setEditDialogIsShown] = useState(false);
  const [attendanceDialogIsShow, setAttendanceDialogIsShown] = useState(false);

  return (
    <>
      <EditWorkshopDialog
        workshop={props.workshop}
        isOpen={editDialogIsShow}
        onClose={() => {
          setEditDialogIsShown(false);
        }}
        onUpdate={props.onUpdate}
      />
      <AttendanceDialog
        workshopId={props.workshop.id}
        isOpen={attendanceDialogIsShow}
        onClose={() => setAttendanceDialogIsShown(false)}
      />
      <TableRow>
        <StyledTableCell>{props.index + 1}.</StyledTableCell>
        <StyledTableCell>
          <div style={{ display: "flex", alignItems: "center" }}>
            {props.workshop.title || "----"}
          </div>
        </StyledTableCell>
        <StyledTableCell style={{ fontWeight: "bold" }}>
          {props.workshop.date
            ? moment(props.workshop.date).format("DD MMM YYYY - HH:mm")
            : "----"}
        </StyledTableCell>
        <StyledTableCell>{props.workshop.lieu || "----"}</StyledTableCell>
        <StyledTableCell>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                style={{
                  backgroundColor: "black",
                  color: " white",
                }}
                onClick={() => {
                  setEditDialogIsShown(true);
                }}
              >
                Editer
              </Button>
            </Grid>
            {moment(props.workshop.date).isBefore(moment()) && (
              <Grid item>
                <Button
                  style={{
                    backgroundColor: "black",
                    color: "white",
                  }}
                  onClick={() => {
                    setAttendanceDialogIsShown(true);
                  }}
                >
                  Valider
                </Button>
              </Grid>
            )}
          </Grid>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export const CourseWorkshopsPage = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState<Formation | null>(null);
  const [workshops, setWorkshops] = useState<Workshop[]>([]);

  useEffect(() => {
    getWorkshops(Number(courseId))
      .then((response) => response.data)
      .then((response) => {
        setCourse(response.course);
        setWorkshops(response.workshops);
      });
  }, []);

  if (!course) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: "1rem" }}>
      <Typography
        style={{ color: "#023047", fontWeight: "bold", marginBottom: "1rem" }}
      >
        Formation: <span style={{ color: "#048B9A" }}>{course.title}</span>
      </Typography>
      <TableContainer
        style={{
          borderRadius: 6,
        }}
      >
        <Table
          sx={{
            border: "2px solid #E8E8E8",
          }}
        >
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "#FAFAFA",
              }}
            >
              <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
                #
              </TableCell>
              <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
                Titre
              </TableCell>
              <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
                Date et Heure
              </TableCell>
              <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
                Lieu
              </TableCell>
              <TableCell style={{ color: "#048B9A", fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workshops.map((workshop, index: number) => {
              return (
                <WorkshopRow
                  key={index}
                  index={index}
                  workshop={workshop}
                  onUpdate={(id, workshop) => {
                    setWorkshops((workshops) =>
                      workshops.map((internalWorkshop) => {
                        if (internalWorkshop.id === id) {
                          return {
                            ...internalWorkshop,
                            ...workshop,
                          };
                        } else {
                          return internalWorkshop;
                        }
                      })
                    );
                  }}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
