import { Disponibilite } from "./models";
import axios from "axios";
import { baseUrl } from "../env";

export function setInstructorSlots(
  userId: number,
  slots: Pick<Disponibilite, "jour" | "heure_debut" | "heure_fin">[]
) {
  return axios.post(`${baseUrl}/dispo/setByInstructor/${userId}`, {
    slots,
  });
}
