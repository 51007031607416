import * as React from "react";
import { SVGProps } from "react";

export const DashboardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
  <svg id="vuesax_bold_home" data-name="vuesax/bold/home" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 30.745 30.745">
    <g id="vuesax_bold_home-2" data-name="vuesax/bold/home">
      <g id="home">
        <path id="Vector" d="M24.34,7.7,15.949.986A4.824,4.824,0,0,0,10.12.974L1.729,7.7A5.017,5.017,0,0,0,.051,12.093l1.614,9.659a4.76,4.76,0,0,0,4.573,3.869H19.818A4.834,4.834,0,0,0,24.4,21.739l1.614-9.659A5.147,5.147,0,0,0,24.34,7.7ZM13.989,20.5a.961.961,0,1,1-1.922,0V16.654a.961.961,0,1,1,1.922,0Z" transform="translate(2.344 2.562)" fill={props.color || "#fff"}/>
        <path id="Vector-2" data-name="Vector" d="M0,0H30.745V30.745H0Z" transform="translate(30.745 30.745) rotate(180)" fill="none" opacity="0"/>
      </g>
    </g>
  </svg>
  );
};
