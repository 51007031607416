import axios from "axios";
import { Page } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/api/page`;

export function addPage(subChapterId: number, content?: string) {
  return axios.post<Page>(`${uri}/create`, {
    SousChapitreId: subChapterId,
    contenu_Html: content,
  });
}

export function updatePage(id: number, page: Partial<Page>) {
  return axios.put(`${uri}/update/${id}`, page);
}

export function deletePage(id: number) {
  return axios.delete(`${uri}/delete/${id}`);
}
