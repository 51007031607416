import { Grid, IconButton, Toolbar } from "@mui/material";
import { NotificationIcon } from "../icons/NotificationIcon";
import { ConnectedUser } from "./ConnectedUser";
import { FC, ReactNode } from "react";
import { Header } from "antd/lib/layout/layout";

export const UserAppBar: FC<{
  toolbar: ReactNode;
}> = (props) => {
  return (
    <Header
      style={{
        backgroundColor: "white",
        color: "black",
        borderBottom: "1px solid #E8E8E8",
        padding: 0,
      }}
    >
      <Toolbar
        className={"d-flex justify-content-between"}
        style={{
          flexWrap: "wrap",
        }}
      >
        <div>{props.toolbar}</div>
        <div>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item>
              <IconButton>
                <NotificationIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <ConnectedUser />
            </Grid>
          </Grid>
        </div>
      </Toolbar>
    </Header>
  );
};
