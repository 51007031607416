import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, {
  CSSProperties,
  FC,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { LocalizationIcon } from "../../../icons/LocalizationIcon";
import { BagIcon } from "../../../icons/BagIcon";
import { GroupOfUsersIcon } from "../../../icons/GroupOfUsersIcon";
import {
  getAllStudentsData,
  getListClubs,
  getListRecruteurs,
} from "../../../api";

const Students = () => {
  const [studentsData, setStudentsData] = useState([]);

  useEffect(() => {
    getAllStudentsData().then(async (data: any) => {
      await setStudentsData(data.data);
    });
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item style={{ marginBottom: 70 }} className={"col-4"}>
        <Card sx={{ height: "100%", maxWidth: 345, borderRadius: "15px" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={
                "https://miro.medium.com/max/750/1*6Ab8Op4DBw8sQXgCc9vqvA.png"
              }
              alt="green iguana"
              style={{
                borderRadius: "15px",
              }}
              sx={{ boxShadow: 3 }}
            />
            <CardContent>
              <Typography
                gutterBottom
                component="div"
                style={{
                  color: "#023047",
                }}
                className={"fw-bold text-center"}
              >
                Web Development Masterclass - Online Certification Course
              </Typography>
              <Typography variant="body2" color="text.secondary">
                This course offers a comprehensive look into the entire web
                development process - from local server configuration using WAMP
                and MAMP (Apache, MySQL, PHP) to production deployment using the
                latest web technologies including: LAMP Stack (Linux, Apache,
                PHP and MySQL) for Ubuntu, HTML5, CSS, Bootstrap, JavaScript,
                jQuery, XML, and AJAX.
              </Typography>
              <div
                className={"d-flex align-items-end justify-content-end mt-4"}
              >
                <Button
                  variant={"contained"}
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#121212",
                    color: "white",
                    width: "25%",
                  }}
                  className={"mt-3 btn pb-0"}
                >
                  <p
                    style={{
                      fontSize: "0.75rem",
                    }}
                    className={"mb-1"}
                  >
                    S'inscrire
                  </p>
                </Button>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item style={{ marginBottom: 70 }} className={"col-4"}>
        <Card sx={{ height: "100%", maxWidth: 345, borderRadius: "15px" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={
                "https://quedulourd.com/wp-content/uploads/2020/07/Wordpress-pour-les-debutants-Cours-Udemy-gratuits.jpg"
              }
              alt="green iguana"
              style={{
                borderRadius: "15px",
              }}
              sx={{ boxShadow: 3 }}
            />
            <CardContent>
              <Typography
                gutterBottom
                component="div"
                style={{
                  color: "#023047",
                }}
                className={"fw-bold text-center"}
              >
                WordPress for Absolute Beginners
              </Typography>
              <Typography variant="body2" color="text.secondary">
                WordPress Blogging and WordPress driven Web development is an
                amazing career path. By completion of this course, you will
                master yourself in several aspects of WordPress. This course
                gives you hands-on and practical experience on Installing and
                Customizing WordPress Themes, Plugins, Working with Posts and
                Pages, Writing and Optimizing Blog posts and more.
              </Typography>
              <div
                className={"d-flex align-items-end justify-content-end mt-4"}
              >
                <Button
                  variant={"contained"}
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#121212",
                    color: "white",
                    width: "25%",
                  }}
                  className={"mt-3 btn pb-0"}
                >
                  <p
                    style={{
                      fontSize: "0.75rem",
                    }}
                    className={"mb-1"}
                  >
                    S'inscrire
                  </p>
                </Button>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item style={{ marginBottom: 70 }} className={"col-4"}>
        <Card sx={{ height: "100%", maxWidth: 345, borderRadius: "15px" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={"https://i.ytimg.com/vi/7a8PvlePpNQ/maxresdefault.jpg"}
              alt="green iguana"
              style={{
                borderRadius: "15px",
              }}
              sx={{ boxShadow: 3 }}
            />
            <CardContent>
              <Typography
                gutterBottom
                component="div"
                style={{
                  color: "#023047",
                }}
                className={"fw-bold text-center"}
              >
                Scrum Certification 2022 +Scrum Master+ Agile Scrum Training
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Scrum Open, Professional Scrum™, Professional Scrum Master™,
                PSM, PSM I, PSM 1, etc. is the protected brand of Scrum . org.
                Our course and practice exams are neither endorsed by nor
                affiliated with Scrum . org.
              </Typography>
              <div
                className={
                  "d-flex align-items-end justify-content-end mt-4 align-bottom"
                }
              >
                <Button
                  variant={"contained"}
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#121212",
                    color: "white",
                    width: "25%",
                  }}
                  className={"mt-3 btn pb-0"}
                >
                  <p
                    style={{
                      fontSize: "0.75rem",
                    }}
                    className={"mb-1"}
                  >
                    S'inscrire
                  </p>
                </Button>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item style={{ marginBottom: 70 }} className={"col-4"}>
        <Card sx={{ height: "100%", maxWidth: 345, borderRadius: "15px" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={
                "https://www.fossmint.com/wp-content/uploads/2020/03/Udemy-Advance-Python-Learning-Courses.png"
              }
              alt="green iguana"
              style={{
                borderRadius: "15px",
              }}
              sx={{ boxShadow: 3 }}
            />
            <CardContent>
              <Typography
                gutterBottom
                component="div"
                style={{
                  color: "#023047",
                }}
                className={"fw-bold text-center"}
              >
                Python Programming - From Basics to Advanced level [2022]
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Once you reach here you can start the new journey to learn
                domain-specific python libraries like NumPy, Pandas, Matplotlib,
                Scikit-learn, TensorFlow, Keras for machine learning. Django,
                flask for web development. PySpark for big data processing and
                many more...
              </Typography>
              <div
                className={"d-flex align-items-end justify-content-end mt-4"}
              >
                <Button
                  variant={"contained"}
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#121212",
                    color: "white",
                    width: "25%",
                  }}
                  className={"mt-3 btn pb-0"}
                >
                  <p
                    style={{
                      fontSize: "0.75rem",
                    }}
                    className={"mb-1"}
                  >
                    S'inscrire
                  </p>
                </Button>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

const Clubs = () => {
  /*const [clubs] = useState(
        Array.from({length: 12}, () => ({
            club: "Club LT",
            field: "Construction",
            imageUrl:
                "https://img.freepik.com/premium-vector/real-estate-logo_74869-167.jpg?w=2000",
        }))
    );*/

  const [clubs, setClubs] = useState([]);

  useEffect(() => {
    getListClubs().then(async (data: any) => {
      await setClubs(data.data);
    });
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        {clubs.map((club: any, i) => (
          <Grid item key={i} style={{ marginBottom: 66 }}>
            <Card
              style={{
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: 28,
              }}
            >
              <img
                src={
                  club?.imageUrl ||
                  "https://img.freepik.com/premium-vector/real-estate-logo_74869-167.jpg?w=2000"
                }
                style={{ width: 200, height: 200 }}
              />
            </Card>
            <div
              style={{
                marginTop: 23,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography style={{ color: "#023047", fontWeight: "bold" }}>
                {club?.name}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const RecruiterInfo: FC<{
  icon: ReactElement;
  value: string;
}> = (props) => {
  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item>{props.icon}</Grid>
      <Grid item>
        <Typography
          style={{
            color: "#707070",
          }}
        >
          {props.value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Recruiters = () => {
  const [companies] = useState(
    Array.from({ length: 20 }, () => ({
      name: "GoodCity Architect",
      place: "Soukra, Tunisia",
      field: "Architectes",
      employees: "10-20 employés",
      imageUrl:
        "https://seeklogo.com/images/B/business-company-logo-C561B48365-seeklogo.com.png",
    }))
  );

  const [recruteurs, setRecruteurs] = useState([]);

  useEffect(() => {
    getListRecruteurs().then(async (data: any) => {
      await setRecruteurs(data.data);
    });
  }, []);
  return (
    <div>
      <Grid container spacing={2}>
        {recruteurs.map((company: any, i) => (
          <Grid item key={i} className={"col-4"}>
            <Card
              variant={"outlined"}
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #E8E8E8",
                borderRadius: 28,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 20,
                  boxShadow: "0px 3px 6px #00000029",
                  border: "2px solid #E8E8E8",
                  borderRadius: 28,
                }}
              >
                <img
                  src={
                    company?.imageUrl ||
                    "https://seeklogo.com/images/B/business-company-logo-C561B48365-seeklogo.com.png"
                  }
                  style={{
                    width: 125,
                    height: 125,
                  }}
                />
              </div>
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 20,
                    color: "#048B9A",
                  }}
                >
                  {company.nom}
                </Typography>
                <Divider />
                <RecruiterInfo
                  icon={<LocalizationIcon />}
                  value={company?.adresse}
                />
                <RecruiterInfo icon={<BagIcon />} value={company.domaine} />
                <RecruiterInfo
                  icon={<GroupOfUsersIcon />}
                  value={company?.nombreEmployé}
                />
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export const Store = () => {
  const [activated, setActivated] = useState("formations");
  const nonActivatedStyle: CSSProperties = {
    backgroundColor: "#FAFAFA",
    border: "1px solid #E8E8E8",
    color: "black",
    fontWeight: "bold",
    paddingLeft: 40,
    paddingRight: 40,
  };

  const activatedStyle: CSSProperties = {
    boxShadow: "0px 2px 16px #4B556E33",
    border: "none",
    color: "black",
    fontWeight: "bold",
    paddingLeft: 40,
    paddingRight: 40,
  };

  return (
    <div className={"m-5"}>
      <Grid container justifyContent={"center"} style={{ marginBottom: 50 }}>
        <Grid item>
          <Button
            variant="outlined"
            style={
              activated === "formations" ? activatedStyle : nonActivatedStyle
            }
            onClick={() => {
              setActivated("formations");
            }}
          >
            Formations
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={"outlined"}
            style={activated === "outils" ? activatedStyle : nonActivatedStyle}
            onClick={() => {
              setActivated("outils");
            }}
          >
            Outils
          </Button>
        </Grid>
      </Grid>
      <div>
        {activated === "formations" && <Students />}
        {activated === "outils" && <Recruiters />}
      </div>
    </div>
  );
};
