import React, { FC } from "react";
import { Formation } from "../../../api/models";
import { useNavigate } from "react-router-dom";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "../../../component/StyledTableCell";
import {
  courseContent,
  courseStudents,
} from "../../../routes/instructor.routes";
import { Edit, Groups } from "@mui/icons-material";

export const CourseRow: FC<{
  course: Formation;
  index: number;
  onDelete: () => void;
}> = (props) => {
  const { course, index } = props;
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const navigate = useNavigate();

  const handleContextMenu = (event: React.MouseEvent, course: Formation) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <>
      <TableRow
        onContextMenu={(e) => handleContextMenu(e, course)}
        style={{ cursor: "context-menu" }}
      >
        <StyledTableCell>{index + 1}.</StyledTableCell>
        <StyledTableCell>{course.title}</StyledTableCell>
        <StyledTableCell style={{ fontWeight: "bold" }}>
          {course.nombre_places_disp}
        </StyledTableCell>
        <StyledTableCell>{course.prix}</StyledTableCell>
        <StyledTableCell>{course.duree}</StyledTableCell>
      </TableRow>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            navigate(courseContent(course.id));
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Éditer</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(courseStudents(course.id));
          }}
        >
          <ListItemIcon>
            <Groups />
          </ListItemIcon>
          <ListItemText>Liste des étudiants</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
