import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { dashboard } from "../student.routes";
import { instructorList as adminInstructorList } from "../admin.routes";
import { courseList } from "../instructor.routes";
import { Navigate } from "react-router-dom";
import React from "react";

export const Redirect = () => {
  const user = useSelector((root: RootState) => root.auth.user);

  let url = "";
  if (!user) {
    url = "/login";
  } else if (user.role === "etudiant") {
    url = dashboard;
  } else if (user.role === "admin") {
    url = adminInstructorList;
  } else if (user.role === "formateur") {
    url = courseList;
  }

  return <Navigate to={url} />;
};
