import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Formation } from "../../api/models";

export const useCurrentCourse = () => {
  const currentCourseId = useSelector(
    (state: RootState) => state.student.currentCourseId
  );
  const courses = useSelector((state: RootState) => state.student.courses);

  return courses.find((course) => course.id === currentCourseId) as Formation;
};

export function useAuthenticatedUser() {
  return useSelector((state: RootState) => state.auth.user);
}
