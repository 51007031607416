import { FC, useEffect } from "react";
import { getUrl } from "../../../../utils/utils";
import { Container } from "@mui/material";

export const ContentOfConclusion: FC<{
  fileUrl: string;
  onLoad: () => void;
}> = (props) => {
  useEffect(() => {
    props.onLoad();
  }, []);
  return (
    <Container maxWidth={"md"}>
      <embed
        style={{
          width: "100%",
          height: "85vh",
        }}
        src={getUrl(props.fileUrl)}
      />
    </Container>
  );
};
