import * as React from "react";
import { FC } from "react";
import { Workshop } from "../api/models";
import { useForm } from "react-hook-form";
import moment from "moment/moment";
import { useMutation } from "@tanstack/react-query";
import { updateWorkshop } from "../api/workshop.service";
import { useSnackbar } from "../hooks/snackbar";
import { Stack } from "@mui/material";
import { CInput } from "./CInput";
import { LoadingButton } from "@mui/lab";
import { blackAndWhite } from "../styles";

export const WorkshopForm: FC<{
  workshop?: Workshop;
  chapterId: number;
  onUpdate: (workshop: Workshop) => void;
}> = (props) => {
  const { workshop } = props;
  const form = useForm<Workshop>({
    defaultValues: {
      date: !workshop?.date
        ? ""
        : moment(workshop!!.date).format(moment.HTML5_FMT.DATETIME_LOCAL),
      title: workshop?.title,
      lieu: workshop?.lieu,
      duree: workshop?.duree,
      description: workshop?.description,
    },
  });
  const updateWorkshopMutation = useMutation({
    mutationKey: ["updateWorkshop", props.chapterId, workshop?.id],
    mutationFn: (values: Partial<Workshop>) =>
      updateWorkshop(workshop!!.id, values)
        .then((response) => {
          snackbar.show("Workshop mis à jour avec succès", "success");
          props.onUpdate(response.data);
        })
        .catch(() => {
          snackbar.show("Erreur lors de la mise à jour du workshop", "error");
        }),
  });
  const snackbar = useSnackbar();

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
      onSubmit={form.handleSubmit((values) => {
        if (!!workshop) {
          updateWorkshopMutation.mutate(values);
        }
      })}
    >
      <CInput
        label={"Titre"}
        {...form.register("title")}
        fullWidth
        error={!!form.formState.errors.title}
        helperText={!!form.formState.errors.title?.message}
      />
      <CInput
        label={"Lieu"}
        {...form.register("lieu")}
        fullWidth
        error={!!form.formState.errors.lieu}
        helperText={!!form.formState.errors.lieu?.message}
      />
      <CInput
        type={"datetime-local"}
        label={"Date et heure"}
        {...form.register("date")}
        fullWidth
        error={!!form.formState.errors.date}
        helperText={!!form.formState.errors.date?.message}
      />
      <CInput
        type={"number"}
        label={"Durée (En minutes)"}
        {...form.register("duree")}
        fullWidth
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
        error={!!form.formState.errors.duree}
        helperText={!!form.formState.errors.duree?.message}
      />
      <CInput
        label={"Description"}
        multiline={true}
        rows={5}
        {...form.register("description")}
        fullWidth
        error={!!form.formState.errors.description}
        helperText={!!form.formState.errors.description?.message}
      />
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <LoadingButton
          loading={updateWorkshopMutation.isLoading}
          {...blackAndWhite}
          type={"submit"}
        >
          Sauvegarder
        </LoadingButton>
      </Stack>
    </form>
  );
};
