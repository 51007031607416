import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  ButtonBase,
  Grid,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { KeyboardArrowDown, Settings } from "@mui/icons-material";
import { getUrl } from "../utils/utils";
import { logoutAction } from "../reducers/auth.reducer";
import { RootState } from "../store";

export function ConnectedUser() {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const user = useSelector((state: RootState) => state.auth.user!!);
  const open = !!anchor;
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutAction());
    navigate("/login");
  };

  return (
    <>
      <ButtonBase style={{ outline: "none" }}>
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          onClick={(event) => {
            setAnchor(event.currentTarget);
          }}
        >
          <Grid item>
            <Avatar src={getUrl(user.userImage)} />
          </Grid>
          <Grid
            item
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            }}
          >
            <Typography style={{ fontWeight: "bold" }}>
              {user.nomComplet}
            </Typography>
          </Grid>
          <Grid item>
            <KeyboardArrowDown />
          </Grid>
        </Grid>
      </ButtonBase>
      <Popover
        elevation={1}
        PaperProps={{
          style: {
            borderRadius: 10,
            boxShadow: "0px 3px 6px #00000029",
          },
        }}
        anchorEl={anchor}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={() => {
          setAnchor(null);
        }}
        className={"mt-3"}
      >
        <Grid
          container
          flexDirection={"column"}
          alignItems={"center"}
          style={{
            padding: "10px 15px",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {user.nomComplet}
          </Typography>
          <Typography style={{ fontSize: 15, color: "#707070", marginTop: 5 }}>
            {user.email}
          </Typography>
          {user.tel && (
            <Typography
              style={{
                color: "#023047",
                fontWeight: "bold",
                marginTop: 5,
              }}
            >
              {user.tel}
            </Typography>
          )}
        </Grid>
        <Grid
          container
          flexDirection={"column"}
          alignItems={"center"}
          style={{
            backgroundColor: "#F5F5F5",
            padding: "10px 15px",
          }}
        >
          <Button
            variant={"text"}
            fullWidth
            onClick={() => {
              let prefix = "";
              switch (user.role) {
                case "etudiant": {
                  prefix = "etudiant";
                  break;
                }
                case "formateur": {
                  prefix = "instructor";
                  break;
                }
                case "admin": {
                  prefix = "admin";
                  break;
                }
              }
              if (!!prefix) {
                navigate(`/${prefix}/account`);
              }
            }}
          >
            <Settings style={{ width: 16, height: 16, color: "#707070" }} />
            <Typography
              style={{ marginLeft: 6, fontWeight: "bold", color: "#707070" }}
            >
              Paramètres du compte
            </Typography>
          </Button>

          <Button
            variant={"text"}
            style={{
              color: "#048B9A",
              fontWeight: "bold",
            }}
            onClick={() => {
              handleLogout();
            }}
          >
            Déconnexion
          </Button>
        </Grid>
      </Popover>
    </>
  );
}
