import React, { useState } from "react";
import { useSnackbar } from "../../../../hooks/snackbar";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUserSchema } from "../../../../validation/user.validation";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Label } from "../../../../component/Label";
import { blackAndWhite } from "../../../../styles";
import { useMutation } from "@tanstack/react-query";
import { createStudent } from "../../../../api/student.service";
import { CreateUser } from "../../../../api/student.service-dto";

export const StudentAddPage = () => {
  const [file, setFile] = useState<any>(null);
  const [url, setUrl] = useState("");
  const inputFileId = "userImage";
  const snackbar = useSnackbar();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateUser>({
    resolver: yupResolver(updateUserSchema),
  });
  const save = useMutation<unknown, unknown, { user: CreateUser; file?: File }>(
    {
      mutationKey: ["addStudent"],
      mutationFn: (values) => {
        return createStudent(values.user, values.file)
          .then(() => {
            snackbar.show("Etudiant ajouté avec succès", "success");
            reset();
            setFile(null);
            setUrl("");
          })
          .catch((error) => {
            const message =
              error.response?.data?.message || "Erreur lors de l'ajout";
            snackbar.show(message, "error");
          });
      },
    }
  );
  const onSubmit: SubmitHandler<CreateUser> = (user) => {
    save.mutate({
      user,
      file,
    });
  };

  const onFileChange = (e: {
    target: { name: any; value: any; files: any[any] };
  }) => {
    const file = e.target.files[0];
    if (!!file) {
      setFile(file);
      setUrl(URL.createObjectURL(file));
    }
  };
  return (
    <Box px={20} pt={10}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{
                fontWeight: "bold",
                color: "#0D2646",
                fontSize: "1.5rem",
              }}
            >
              Ajout Étudiant
            </Typography>
          </Grid>
          <Grid item xs={12} className={"mt-5"}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3} container justifyContent={"center"}>
                <Stack spacing={2} justifyContent={"center"}>
                  <div>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={
                        <div>
                          <input
                            type="file"
                            id={inputFileId}
                            style={{
                              display: "none",
                            }}
                            onChange={onFileChange}
                          />
                          <Avatar
                            sx={{
                              width: 38,
                              height: 38,
                              border: "3px solid white",
                              backgroundColor: "#CDE8F4",
                            }}
                            onClick={() => {
                              let input = document.getElementById(inputFileId);
                              input?.click();
                            }}
                          >
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#048B9A"
                                className="bi bi-camera-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                              </svg>
                            </div>
                          </Avatar>
                        </div>
                      }
                    >
                      <Avatar
                        onClick={() => {
                          let input = document.getElementById(inputFileId);
                          input?.click();
                        }}
                        alt={""}
                        src={url}
                        sx={{ width: 202, height: 202 }}
                      />
                    </Badge>
                  </div>
                  <div>
                    <Typography
                      style={{
                        color: "#023047",
                        textAlign: "center",
                      }}
                    >
                      Changer photo de profile
                    </Typography>
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container spacing={3}>
                  <Grid item xs={6} md={6}>
                    <Label
                      label={"Nom"}
                      control={
                        <TextField
                          autoComplete={"family-name"}
                          {...register("nom")}
                          error={!!errors.nom?.message}
                          helperText={errors.nom?.message}
                          size={"small"}
                          fullWidth={true}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Label
                      label={"Prénom"}
                      control={
                        <TextField
                          autoComplete={"given-name"}
                          {...register("prenom")}
                          error={!!errors.prenom?.message}
                          helperText={errors.prenom?.message}
                          size={"small"}
                          fullWidth={true}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Label
                      label={"Numéro de téléphone"}
                      control={
                        <TextField
                          autoComplete={"tel"}
                          type={"tel"}
                          {...register("tel")}
                          error={!!errors.tel?.message}
                          helperText={errors.tel?.message}
                          size={"small"}
                          fullWidth={true}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Label
                      label={"Email"}
                      control={
                        <TextField
                          autoComplete={"email"}
                          type={"email"}
                          {...register("email")}
                          error={!!errors.email?.message}
                          helperText={errors.email?.message}
                          size={"small"}
                          fullWidth={true}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Label
                      label={"CIN"}
                      control={
                        <TextField
                          {...register("cin")}
                          error={!!errors.cin?.message}
                          helperText={errors.cin?.message}
                          size={"small"}
                          fullWidth={true}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Label
                      label={"Adresse"}
                      control={
                        <TextField
                          autoComplete={"street-address"}
                          {...register("adresse")}
                          error={!!errors.adresse?.message}
                          helperText={errors.adresse?.message}
                          size={"small"}
                          fullWidth={true}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type={"submit"} {...blackAndWhite}>
                      Sauvegarder
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
