import { SVGProps } from "react";

export const CourseIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27.421}
    height={20.933}
    {...props}
  >
    <g data-name="Group 412" fill={props.color || "white"}>
      <path
        data-name="Path 4038"
        d="M27.421 20.166a1.039 1.039 0 0 1-1.424.706 25.574 25.574 0 0 0-7.228-.589c-.949.068-1.889.235-2.835.358.12-.105.255-.234.4-.349a8.813 8.813 0 0 1 3.964-1.769 25.952 25.952 0 0 1 2.893-.32 2.567 2.567 0 0 0 2.28-1.428 2.738 2.738 0 0 0 .255-1.3V3.435c.951-.12 1.364-.043 1.7.715Z"
      />
      <path
        data-name="Path 4039"
        d="M12.942 19.896c-.215-.2-.391-.369-.576-.529a10.714 10.714 0 0 0-5.546-2.458c-.835-.126-1.683-.169-2.527-.225a1.02 1.02 0 0 1-1.077-1.1V1.075A.981.981 0 0 1 4.249 0a13.418 13.418 0 0 1 4.155.658 7.976 7.976 0 0 1 4.3 3.224 1.455 1.455 0 0 1 .238.745q.021 7.534.01 15.07c.001.05-.004.1-.01.199Z"
      />
      <path
        data-name="Path 4040"
        d="M14.468 19.898v-.362c0-4.818.013-9.637-.014-14.455a2.652 2.652 0 0 1 .657-1.772 8.562 8.562 0 0 1 5.2-2.993 24 24 0 0 1 2.84-.31.975.975 0 0 1 1.059 1.1v14.452a1.009 1.009 0 0 1-1.105 1.121 14.515 14.515 0 0 0-5.076.915 9.721 9.721 0 0 0-3.342 2.119c-.058.053-.119.099-.219.185Z"
      />
      <path
        data-name="Path 4041"
        d="M1.696 3.396v12.095a2.541 2.541 0 0 0 2.582 2.7 14.213 14.213 0 0 1 3.31.42 9.048 9.048 0 0 1 3.721 1.858c.067.058.129.12.251.235-.262-.055-.452-.1-.643-.133s-.386-.071-.579-.1a18.755 18.755 0 0 0-5.041-.156c-1.336.144-2.664.376-3.991.591a1.009 1.009 0 0 1-1.3-1.066V4.499a.993.993 0 0 1 1.108-1.106c.181.002.366.003.582.003Z"
      />
    </g>
  </svg>
);
