import React, { FC } from "react";
import {
  Grid,
  Step,
  StepConnector,
  stepConnectorClasses,
  Stepper,
} from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import { StepIcon } from "../../../../icons/StepIcon";

export const SubchapterStepper: FC<{
  index: number;
  total: number;
}> = (props) => {
  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={8}>
        <Stepper
          activeStep={props.index + 1}
          connector={
            <StepConnector
              sx={{
                [`&.${stepConnectorClasses.active}`]: {
                  [`& .${stepConnectorClasses.line}`]: (theme) => ({
                    backgroundColor: theme.palette.primary.main,
                  }),
                },
                [`&.${stepConnectorClasses.completed}`]: {
                  [`& .${stepConnectorClasses.line}`]: (theme) => ({
                    backgroundColor: theme.palette.primary.main,
                  }),
                },
                [`& .${stepConnectorClasses.line}`]: {
                  height: 5,
                  border: 0,
                  backgroundColor: "#e8e8e8",
                  width: "calc(100% + 8px)",
                },
              }}
            />
          }
        >
          {Array.from({ length: props.total }).map((item, i) => (
            <Step
              key={i}
              sx={{
                px: 0,
                zIndex: 2,
              }}
              completed={i < props.index}
              active={i === props.index}
            >
              <StepLabel
                sx={{
                  p: 0,
                  width: 20,
                }}
                StepIconComponent={() => (
                  <StepIcon fill={i <= props.index ? undefined : "#e8e8e8"} />
                )}
              />
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};
