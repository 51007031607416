import React, { FC, useEffect, useState } from "react";
import { useSnackbar } from "../../../../hooks/snackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAll as getAllInstructors } from "../../../../api/instructor.service";
import { addCourse } from "../../../../api/course.service";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { bigButton, blackAndWhite } from "../../../../styles";
import { useNavigate } from "react-router-dom";
import { getImageStyles } from "../../../../utils/utils";
import { AddImage } from "../../../../component/AddImage";

type AddCourse = {
  title: string;
  description: string;
  tag: string;
  places: number;
  price: number;
  duration: string;
  formateurId: number;
};

export const CourseAddPage: FC = () => {
  const snackbar = useSnackbar();
  const instructorsQuery = useQuery({
    queryKey: ["all-instructors"],
    queryFn: () => getAllInstructors().then((res) => res.data),
  });
  const [image, setImage] = useState<File | null>(null);
  const instructors = instructorsQuery.data || [];
  const addCourseMutation = useMutation({
    mutationKey: ["addCourse"],
    mutationFn: (values: AddCourse) => {
      return addCourse(
        values.formateurId,
        values.title,
        values.description,
        values.places,
        values.price,
        values.duration,
        values.tag,
        image
      ).then((response) => {
        setImage(null);
        form.reset();
      });
    },
  });
  const form = useForm<AddCourse>({});
  const navigate = useNavigate();

  useEffect(() => {
    if (addCourseMutation.isSuccess) {
      snackbar.show("Formation ajouté avec succès", "success");
    }
  }, [addCourseMutation.isSuccess]);

  const errorMessage =
    (addCourseMutation.error as any)?.response?.data?.message ||
    "Erreur lors de l'ajout";
  useEffect(() => {
    if (addCourseMutation.isError) {
      snackbar.show(errorMessage || "Erreur lors de l'ajout", "error");
    }
  }, [addCourseMutation.isError]);

  return (
    <Box p={3}>
      <form
        onSubmit={form.handleSubmit((values) => {
          addCourseMutation.mutate(values);
        })}
      >
        <Typography
          style={{
            color: "#023047",
            fontSize: 26,
            fontWeight: "bold",
          }}
        >
          Ajout de formation
        </Typography>
        <Box
          sx={{
            "& *": {
              marginTop: "1rem",
            },
          }}
        >
          <TextField
            placeholder={"Titre"}
            fullWidth={true}
            {...form.register("title")}
          />
          <div>
            <Controller
              control={form.control}
              name={"formateurId"}
              render={({ field }) => (
                <TextField
                  select
                  label={"Formateur"}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  value={field.value || ""}
                >
                  <MenuItem disabled={true}>Sélectionner le formateur</MenuItem>
                  {instructors.map((instructor) => (
                    <MenuItem key={instructor.id} value={instructor.id}>
                      <ListItemText>{instructor.user?.nomComplet}</ListItemText>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
          <TextField
            placeholder={"Description"}
            multiline={true}
            rows={3}
            fullWidth={true}
            {...form.register("description")}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <TextField
                {...form.register("places")}
                type={"number"}
                placeholder={"Nombre de places"}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <TextField
                {...form.register("price")}
                type={"number"}
                placeholder={"Prix"}
                fullWidth={true}
              />{" "}
            </Grid>

            <Grid item xs={12} lg={3}>
              <TextField
                {...form.register("duration")}
                placeholder={"Durée"}
                fullWidth={true}
              />{" "}
            </Grid>

            <Grid item xs={12} lg={3}>
              <TextField
                {...form.register("tag")}
                type={"text"}
                placeholder={"Tag"}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </Box>
        <div style={{ marginTop: "0.5rem" }}>
          <AddImage
            parentFile={image || undefined}
            inputId={"courseImage"}
            onChange={setImage}
          />
        </div>
        {addCourseMutation.isError && (
          <Alert style={{ marginTop: "1rem" }} severity={"error"}>
            {errorMessage || "Erreur lors de l'ajout"}
          </Alert>
        )}
        <Grid container justifyContent={"flex-end"}>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  type={"button"}
                  style={{
                    color: "#121212",
                  }}
                  {...bigButton}
                  fullWidth={true}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Retour
                </Button>
              </Grid>
              <Grid item>
                <Button type={"submit"} {...blackAndWhite} fullWidth={true}>
                  Confirmer
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </form>
    </Box>
  );
};
