import { combineReducers } from "redux";
import { authReducer } from "./reducers/auth.reducer";
import { message } from "./reducers/message";
import { formationEtudiant } from "./reducers/formation";
import { formationEtudiantReducer } from "./reducers/formationEtudiant";
import { quizEtudiant } from "./reducers/quiz";
import { instructorReducer } from "./reducers/instructor/instructor.reducer";
import { configureStore } from "@reduxjs/toolkit";
import { layoutReducer } from "./reducers/layout.reducer";
import { studentReducer } from "./reducers/student/student.reducer";

const reducer = combineReducers({
  auth: authReducer,
  message: message,
  formation: formationEtudiant,
  formationEtudiantReducer: formationEtudiantReducer,
  quizEtudiant: quizEtudiant,
  instructor: instructorReducer,
  layout: layoutReducer,
  student: studentReducer,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
