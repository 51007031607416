import * as React from "react";
import { SVGProps } from "react";

export const CommunityIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" id="vuesax_bold_people" data-name="vuesax/bold/people" width={props.width} height={props.height} viewBox="0 0 30.745 30.745">
      <g id="vuesax_bold_people-2" data-name="vuesax/bold/people">
        <g id="people">
          <path id="Vector" d="M0,0H30.745V30.745H0Z" fill="none" opacity="0"/>
          <path id="Vector-2" data-name="Vector" d="M3.83,7.392a.946.946,0,0,0-.269,0,3.709,3.709,0,1,1,.269,0Z" transform="translate(18.626 2.562)" fill={props.color || "#fff"}/>
          <path id="Vector-3" data-name="Vector" d="M5.368,6.011a7.954,7.954,0,0,1-5.3,1.076A8.366,8.366,0,0,0,.82,3.641,7.871,7.871,0,0,0,0,.08,7.99,7.99,0,0,1,5.355,1.143C7.379,2.476,7.379,4.666,5.368,6.011Z" transform="translate(21.265 12.82)" fill={props.color || "#fff"}/>
          <path id="Vector-4" data-name="Vector" d="M3.561,7.392a.946.946,0,0,1,.269,0,3.7,3.7,0,1,0-.269,0Z" transform="translate(4.689 2.562)" fill={props.color || "#fff"}/>
          <path id="Vector-5" data-name="Vector" d="M5.835,3.642a8.343,8.343,0,0,0,.756,3.484,7.916,7.916,0,0,1-5.073-1.1c-2.024-1.345-2.024-3.536,0-4.881A7.867,7.867,0,0,1,6.642.055,8.145,8.145,0,0,0,5.835,3.642Z" transform="translate(2.556 12.82)" fill={props.color || "#fff"}/>
          <path id="Vector-6" data-name="Vector" d="M4.573,8.8a1.447,1.447,0,0,0-.333,0,4.42,4.42,0,1,1,.333,0Z" transform="translate(10.953 11.529)" fill={props.color || "#fff"}/>
          <path id="Vector-7" data-name="Vector" d="M1.451,1.1c-1.934,1.294-1.934,3.42,0,4.7a7.828,7.828,0,0,0,8.019,0c1.934-1.294,1.934-3.42,0-4.7A7.828,7.828,0,0,0,1.451,1.1Z" transform="translate(9.912 21.877)" fill={props.color || "#fff"}/>
        </g>
      </g>
    </svg>
);
