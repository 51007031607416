export const student = `/etudiant`;
export const home = `${student}/dashboard`;
export const explore = `${student}/home`;
export const exploreCourses = `${student}/explore/courses`;
export const exploreTools = `${student}/explore/tools`;

export const toolRoute = (id: number) => `${student}/tools/${id}`;
export const dashboard = `${student}/dashboard`;
export const course = `${student}/course`;
export const calendar = `${student}/calendar`;
export const projects = `${student}/projects`;
export const oneToOne = `${student}/one-to-one`;
export const workshop = `${student}/workshop`;
export const community = `${student}/community`;
export const store = `${student}/store`;
