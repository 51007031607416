import { useState } from "react";
import { ExploreLayout } from "../../../../component/ExploreLayout";
import { useAllCoursesForStudent } from "../../../../hooks/queries";
import { getUrl } from "../../../../utils/utils";
import { useMutation } from "@tanstack/react-query";
import { assignToCourse } from "../../../../api/student.service";
import { useSnackbar } from "../../../../hooks/snackbar";
import { home } from "../../../../routes/student.routes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentCourseId } from "../../../../reducers/student/actions";

export enum CourseView {
  EXPLORE_COURSES,
  MY_COURSES,
}

export const ExploreCourses = () => {
  const [view, setView] = useState<CourseView>(CourseView.EXPLORE_COURSES);
  const query = useAllCoursesForStudent();
  const courses = query.data || [];
  const myCourses = courses.filter((course) => course.inMyList);
  const otherCourses = courses.filter((course) => !course.inMyList);
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addStudentToCourseMutation = useMutation<
    any,
    any,
    { courseId: number }
  >({
    mutationKey: ["assignStudentToCourse"],
    mutationFn: (args) =>
      assignToCourse(args.courseId)
        .then(() => {
          query.refetch();
        })
        .catch(() => {
          snackbar.show("Sorry! Could not assign you to the course", "error");
        }),
  });

  return (
    <ExploreLayout
      banner={{
        url: "/courses-bg.svg",
        textColor: "white",
      }}
      title={"FORMATIONS"}
      discoverButton={{
        isActive: view === CourseView.EXPLORE_COURSES,
        onClick: () => setView(CourseView.EXPLORE_COURSES),
      }}
      listButton={{
        isActive: view === CourseView.MY_COURSES,
        number: myCourses.length,
        title: "Mes Formations",
        onClick: () => setView(CourseView.MY_COURSES),
      }}
      items={(view === CourseView.MY_COURSES ? myCourses : otherCourses).map(
        (course) => ({
          title: course.title,
          price: parseFloat(String(course.prix)),
          tag: course.tag,
          showActionBar: course.inMyList === true,
          image: getUrl(course.photo),
          onAdd: () => {
            addStudentToCourseMutation.mutate({ courseId: course.id });
          },
          onClick:
            typeof course.inMyList === "undefined"
              ? undefined
              : () => {
                  dispatch(setCurrentCourseId(course.id));
                  navigate(home);
                },
        })
      )}
    />
  );
};
