import React, { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { CourseIcon } from "../../icons/CourseIcon";
import { CalendarIcon } from "../../icons/CalendarIcon";
import { ProjectIcon } from "../../icons/ProjectIcon";
import { OneToOneIcon } from "../../icons/OneToOneIcon";
import { WorkshopIcon } from "../../icons/WorkshopIcon";
import { CommunityIcon } from "../../icons/CommunityIcon";
import { StoreIcon } from "../../icons/StoreIcon";
import { DashboardIcon } from "../../icons/DashboardIcon";
import { useDispatch, useSelector } from "react-redux";
import { UserLayout } from "../../component/UserLayout";
import { RootState } from "../../store";
import { getLastFormation, getListFormationStudentByID } from "../../api";
import {
  calendar,
  community,
  course,
  dashboard,
  explore,
  oneToOne,
  projects,
  store,
  workshop,
} from "../../routes/student.routes";
import { setCourses, setCurrentCourseId } from "../../reducers/student/actions";
import { SelectCurrentCourse } from "./SelectCurrentCourse";

const menuItems = [
  {
    text: "Dashboard",
    icon: <DashboardIcon />,
    link: dashboard,
  },
  {
    text: "Cours",
    icon: <CourseIcon />,
    link: course,
  },
  {
    text: "Calendrier",
    icon: <CalendarIcon />,
    link: calendar,
  },
  {
    text: "Series",
    icon: <ProjectIcon />,
    link: projects,
  },
  {
    text: "One To One",
    icon: <OneToOneIcon />,
    link: oneToOne,
  },
  {
    text: "Devoirs",
    icon: <WorkshopIcon />,
    link: workshop,
  },
];
const menuItems2 = [
  {
    icon: <CommunityIcon />,
    link: community,
    text: "Communauté",
  },
  {
    icon: <StoreIcon />,
    link: store,
    text: "Store",
  },
];

export function StudentLayout() {
  const user = useSelector((state: RootState) => state.auth.user);
  const currentCourseId = useSelector(
    (state: RootState) => state.student.currentCourseId
  );
  const dispatch = useDispatch();
  const loadOnce = useRef(true);

  useEffect(() => {
    if (loadOnce.current) {
      if (user) {
        if (!currentCourseId) {
          getLastFormation(user.id).then((response) => {
            dispatch(setCurrentCourseId(response.data.id));
          });
        }

        getListFormationStudentByID(user.id)
          .then((response) => {
            dispatch(setCourses(response.data));
          })
          .catch(console.error);
      }
      loadOnce.current = !loadOnce.current;
    }
  }, [user]);

  return (
    <UserLayout
      logoLink={explore}
      toolbar={
        <div>
          <SelectCurrentCourse />
        </div>
      }
      menuItems={menuItems}
      menuItems2={menuItems2}
      content={<Outlet />}
    />
  );
}
