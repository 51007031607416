import axios, { AxiosResponse } from "axios";
import {
  Etudiant,
  EtudiantProject,
  Formation,
  OneToOne,
  PresenceWorkshop,
  StudentQuiz,
} from "./models";
import { baseUrl } from "../env";
import { CreateUser } from "./student.service-dto";

const uri = `${baseUrl}/students`;

export const getCourses = (userId: number) => {
  return axios.get<Formation[]>(`${uri}/${userId}/courses`);
};

export const getAll = (params?: {
  skip?: number;
  take?: number;
  search?: string;
}) => {
  return axios.get<{
    count: number;
    rows: Etudiant[];
  }>(`${uri}/`, { params });
};

export const getOneToOnes = (
  userId: number,
  filter?: {
    courseId?: number;
  }
) => {
  return axios.get<OneToOne[]>(`${uri}/${userId}/one-to-ones`, {
    params: {
      courseId: filter?.courseId,
    },
  });
};

export function getAllCourses() {
  return axios.get<(Formation & { inMyList: boolean })[]>(`${uri}/courses`);
}

export function assignToCourse(courseId: number) {
  return axios.post(`${uri}/courses/${courseId}`);
}

export function createStudent(user: CreateUser, photo?: File) {
  const formData = new FormData();
  formData.append("nom", user.nom);
  formData.append("prenom", user.prenom);
  formData.append("tel", user.tel);
  formData.append("cin", user.cin);
  formData.append("email", user.email);
  formData.append("adresse", user.adresse);

  if (photo) {
    formData.append("photo", photo);
  }

  return axios.post<any, Etudiant>(uri, formData);
}

export function getCourse(courseId: number): Promise<
  AxiosResponse<{
    content: Formation;
    progression: number;
    studentProjects: EtudiantProject[];
    studentQuizzes: any[];
    studentWorkshopAttendance: PresenceWorkshop[];
  }>
> {
  return axios.get(`${uri}/courses/${courseId}`);
}

export function uploadProject(projectId: number, project: File) {
  const formData = new FormData();
  formData.append("project", project);
  return axios.post<EtudiantProject>(`${uri}/projects/${projectId}`, formData);
}

export function passQuiz(quizId: number, answerIds: number[]) {
  return axios.post<{
    studentQuiz: StudentQuiz;
    status: "success" | "fail";
  }>(`${uri}/quiz/${quizId}`, {
    answerIds,
  });
}
