import { ButtonProps } from "@mui/material";

export const bigButton: ButtonProps = {
  sx: {
    padding: ".5rem 3rem",
  },
};

export const blackAndWhite: ButtonProps = {
  variant: "contained",
  sx: {
    ...bigButton.sx,
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
};
