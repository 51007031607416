import axios from "axios";
import { baseUrl } from "../env";

export function ping() {
  return axios
    .get<{ ping: number }>(`${baseUrl}/info/ping`)
    .then((response) => {
      if (response.data.ping === 1) return true;
      return false;
    })
    .catch(() => false);
}
