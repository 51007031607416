import React, { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";

export const ProjectInstructions: FC<{
  summary: string;
}> = (props) => {
  return (
    <Box>
      <Stack>
        <Stack>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#023047",
            }}
          >
            Enoncé
          </Typography>
          {!props.summary ? (
            <Typography>Enoncé non disponible pour le moment.</Typography>
          ) : (
            <Box dangerouslySetInnerHTML={{ __html: props.summary }} />
          )}
        </Stack>
        <Stack></Stack>
      </Stack>
    </Box>
  );
};
