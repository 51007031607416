import axios from "axios";
import { Qcm } from "./models";
import { baseUrl } from "../env";

const uri = `${baseUrl}/api/qcm`;

export function updateQuiz(quizId: number, quiz: Partial<Qcm>) {
  return axios.put<Partial<Qcm>>(`${uri}/update/${quizId}`, quiz);
}

export function hasQuestions(quiz: Qcm) {
  return quiz.questions.length > 0;
}
