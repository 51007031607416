import * as React from "react";
import { SVGProps } from "react";

export const ProjectsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="vuesax/bold/tick-square"
    xmlns="http://www.w3.org/2000/svg"
    width={30.745}
    height={30.745}
    {...props}
  >
    <path
      d="M20.74 2.562H10.005c-4.663 0-7.443 2.78-7.443 7.443v10.722c0 4.676 2.78 7.456 7.443 7.456h10.722c4.663 0 7.443-2.78 7.443-7.443V10.005c.013-4.663-2.767-7.443-7.43-7.443Zm.756 9.864-7.264 7.264a.959.959 0 0 1-1.358 0l-3.625-3.628a.96.96 0 1 1 1.358-1.358l2.946 2.946 6.585-6.585a.96.96 0 0 1 1.358 1.358Z"
      fill={props.color || "#fff"}
      data-name="vuesax/bold/tick-square"
    />
  </svg>
);
