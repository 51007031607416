import { FC, PropsWithChildren, ReactElement } from "react";
import { Typography } from "@mui/material";

export const Label: FC<
  PropsWithChildren<{
    label: string;
    control: ReactElement;
  }>
> = (props) => {
  return (
    <label style={{ width: "100%" }}>
      <Typography>{props.label}</Typography>
      {props.control}
    </label>
  );
};
